/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import {alpha, useTheme} from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {VideoPlayer} from "../../../components/VideoPlayer";
import CardMedia from "@mui/material/CardMedia";
import {CheckmarkIcon} from "../../NewStudio/SettingsIcon";
import Avatar from "@mui/material/Avatar";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import KeyboardAltIcon from "@mui/icons-material/KeyboardAlt";
import {FolderShared} from "@mui/icons-material";

const modernInfo = [
    {
        title: 'Studio-quality recording made easy',
        subtitle:
            'Achieving high audio and video recording quality shouldn’t require a studio or a flawless internet connection.',
        icon: (
            <VideoCameraFrontIcon/>
        ),
    },
    {
        title: 'Editing as simple as editing text',
        subtitle:
            "Editing shouldn't be so daunting, and making basic cuts and edits shouldn't require learning complex software.",
        icon: (
            <KeyboardAltIcon/>
        ),
    },
    {
        title: 'Sharing assets in one click',
        subtitle:
            "After recording and editing, sharing files with collaborators shouldn't require multiple steps or third-party tools.",
        icon: (
            <FolderShared/>
        ),
    },
];

export const NewVideoLayoutFeature = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [viewPortEntered, setViewPortEntered] = useState(false);
    const setViewPortVisibility = (isVisible) => {
        if (viewPortEntered) {
            return;
        }

        setViewPortEntered(isVisible);
    };

    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const videoHeight = isXs ? 210 : (isSm ? 270 : 360);
    const videoWidth = isXs ? 370 : (isSm ? 480 : 640);

    return (
        <Box>
            <Grid container spacing={isMd ? 4 : 0} direction={isMd ? 'row' : 'column'} alignItems="center">
                <Grid item xs={12} md={5}>
                    <Box marginBottom={4}>
                        <Typography sx={{ fontWeight: 700 }} variant={'h3'} gutterBottom color={'#2B6BFD'}>
                            Smart Video Layouts
                        </Typography>
                        <Typography component={'p'} color={'text.secondary'} fontFamily={'Inter'} fontSize="1.2rem" fontWeight={500} marginBottom={3}>
                            Choose from a variety of video layouts. For speaker-oriented layouts,
                            Libretto automatically detects and centers the active speaker.
                        </Typography>
                    </Box>
                </Grid>
                <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    md={7}
                >
                    <Box
                            component={CardMedia}
                            height={videoHeight}
                            width={videoWidth}
                            borderRadius={'20px'}
                            image={"https://storage.googleapis.com/libretto-public-assets/VideoLayout.png"}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export const GuideSectionOnLandingPage = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [viewPortEntered, setViewPortEntered] = useState(false);
    const setViewPortVisibility = (isVisible) => {
        if (viewPortEntered) {
            return;
        }

        setViewPortEntered(isVisible);
    };

    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const videoHeight = isXs ? 210 : (isSm ? 270 : 360);
    const videoWidth = isXs ? 370 : (isSm ? 480 : 640);

    return (
        <Box display={"flex"} alignItems={"center"} flexDirection={"column"} marginTop={"30px"}>
            <Box marginBottom={3} maxWidth={{ xs: "95%", sm: 720, md: 1236 }}>
                <Box marginBottom={2}>
                    <Typography
                        variant="h3"
                        color="text.primary"
                        align={'center'}
                        gutterBottom
                        sx={{
                            fontWeight: 700,
                        }}
                    >
                        Libretto makes it simple.
                    </Typography>
                    <Box
                        sx={{
                            fontSize: { xs: "21px", sm: "23px", md: "24px" }, // Smaller font for mobile
                            marginTop: "15px",
                            marginBottom: { xs: "-10px"},
                            fontWeight: "500",
                            color: "#6f6e77",
                            textAlign: "center", // Center align the text
                            paddingX: { xs: "20px", sm: "0" } // Padding on mobile for better spacing
                        }}
                    >
                        Streamlining your workflow, so you can focus on your creativity. Create, edit, and share with ease.
                    </Box>
                </Box>
            </Box>
            <Grid container maxWidth={{ xs: "95%", sm: 720, md: 1236 }} spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Box width={1} height={1}>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'center'}
                            >
                                <Typography variant={"h3"} color={"#2b6bfd"} fontWeight={"550"}>
                                                                 100+
                                </Typography>
                                <Typography align={'center'} color="text.secondary" fontFamily={"Inter"} fontSize={"1.1rem"} fontWeight={400}>
                                    Trusted by hundreds of creators and editors worldwide.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                <Grid item xs={12} md={4}>
                    <Box width={1} height={1}>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                        >
                            <Typography variant={"h3"} color={"#2b6bfd"} fontWeight={"550"}>
                                50%
                            </Typography>
                            <Typography align={'center'} color="text.secondary" fontFamily={"Inter"} fontSize={"1.1rem"} fontWeight={400}>
                                Save up to half of editing time with an editor that works with you.
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box width={1} height={1}>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                        >
                            <Typography variant={"h3"} color={"#2b6bfd"} fontWeight={"550"}>
                                99%
                            </Typography>
                            <Typography align={'center'} color="text.secondary" fontFamily={"Inter"} fontSize={"1.1rem"} fontWeight={400}>
                                Uptime and reliability you can count on. No more lost recordings.
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

const VideoLayoutFeature = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [viewPortEntered, setViewPortEntered] = useState(false);
    const setViewPortVisibility = (isVisible) => {
        if (viewPortEntered) {
            return;
        }

        setViewPortEntered(isVisible);
    };

    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const videoHeight = isXs ? 210 : (isSm ? 270 : 360);
    const videoWidth = isXs ? 370 : (isSm ? 480 : 640);

    return (
        <Box>
            <Grid container spacing={isMd ? 4 : 0} direction={isMd ? 'row' : 'column'} alignItems="center">
                <Grid item xs={12} md={5}>
                    <Box marginBottom={4}>
                        <Typography sx={{ fontWeight: 700 }} variant={'h3'} gutterBottom>
                            Smart{' '}
                            <Typography
                                color={'primary'}
                                component={'span'}
                                variant={'inherit'}
                            >
                                Video Layouts
                            </Typography>
                        </Typography>
                        <Typography component={'p'} color={'text.secondary'} fontSize="1.2rem" fontWeight={500} marginBottom={3}>
                            Choose from a variety of video layouts. For
                            {' '}<span style={{fontWeight: 900}}>
                                    speaker-oriented layouts,
                                </span>{' '}
                            Libretto automatically detects and centers the active speaker.
                        </Typography>
                    </Box>
                </Grid>
                <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    md={7}
                >
                    {isSm ?
                        <Box
                            component={Card} boxShadow={4} height={videoHeight} width={videoWidth}
                        >
                            <VideoPlayer src={"https://stream.mux.com/b0181Cc9e7bB02fwuhUw00OX6mP00ojbiUIvWOv6nafoca4.m3u8"} poster={`https://image.mux.com/b0181Cc9e7bB02fwuhUw00OX6mP00ojbiUIvWOv6nafoca4/thumbnail.png?time=0&width=${videoWidth}&height=${videoHeight}`} width={videoWidth} height={videoHeight} autoplay={true} controls={false} muted={true} loop={true}/>
                        </Box>
                        : <Box
                            component={CardMedia}
                            height={videoHeight}
                            width={videoWidth}
                            borderRadius={'20px'}
                            image={`https://image.mux.com/b0181Cc9e7bB02fwuhUw00OX6mP00ojbiUIvWOv6nafoca4/thumbnail.png?time=0&width=${videoWidth}&height=${videoHeight}`}
                        />}
                </Grid>
            </Grid>
        </Box>
    );
};

export default VideoLayoutFeature;
