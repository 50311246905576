import Box from "@mui/material/Box";
import {FilmIcon} from "./FilmIcon";
import TextPanelContainer from "../../editor/modules/sidebar/containers/TextPanelContainer/TextPanelContainer";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {observer} from "mobx-react-lite";
import {SidebarStore} from "../../editor/store/SidebarStore";
import {autorun, IReactionDisposer, reaction} from "mobx";
import {ApplicationStore} from "../../editor/store/ApplicationStore";
import {RendleyStore} from "../../editor/store/RendleyStore";
import {ClipTypeEnum, Engine} from "@rendley/sdk";
import EditPanelContainer from "../../editor/modules/sidebar/containers/EditPanelContainer/EditPanelContainer";
import Panel from "../../editor/modules/sidebar/components/Panel/Panel";
import MediaPanelContainer from "../../editor/modules/sidebar/containers/MediaPanelContainer/MediaPanelContainer";
import {collection, onSnapshot, query, where, getDocs} from "firebase/firestore";
import {
    AssetDeleted,
    AssetProjectId, AssetType,
    kAssetsCollectionName,
    kTracksCollectionName,
    TrackDeleted,
    TrackProjectId,
    TrackUserId
} from "../../utils/CollectionConstants";
import {db} from "../../Firebase";
import {AuthContext} from "../../context/AuthContext";
import {EditorMediaTypeEnums, IsExportedEdit, IsUploadedTrackType} from "../../utils/utils";
import {CameraIcon} from "../../editor/assets/icons/CameraIcon";
import StockPanelContainer from "../../editor/modules/sidebar/containers/StockPanelContainer/StockPanelContainer";
import SubtitlesStylesContainer
    from "../../editor/modules/sidebar/containers/SubtitlesStylesContainer/SubtitlesStylesContainer";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import AudiogramStylesContainer
    from "../../editor/modules/sidebar/containers/AudiogramStylesContainer/AudiogramStylesContainer";

const mediaBarStyle = {
    display: 'flex',
    width: '94px',
    padding: '12px',
    flexDirection: 'column',
    justifySelf: "flex-end",
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    gap: '20px',
    height: "360px",
    borderRadius: '8px',
    background: "#ffffff",
}

const mediaBarItemStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: "12px",
    paddingX: "10px",
    alignItems: 'center',
    gap: '4px',
    alignSelf: 'stretch',
    // Hover effect
    "&:hover": {
        background: "#F5F5F5",
        borderRadius: '4px',
        cursor: 'pointer',
    }
}

const captionsButtonStyle = (enabled) => {
    return ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: "12px",
        alignItems: 'center',
        border: "1px solid",
        borderColor: enabled ? "#2b6bfd" : "transparent",
        borderRadius: "4px",
        gap: '4px',
        alignSelf: 'stretch',
        // Hover effect
        "&:hover": {
            background: "#F5F5F5",
            borderRadius: '4px',
            cursor: 'pointer',
        }
    })
}

const mediaBarItemTextStyle = {
    color: "#1A1A1A",
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0.48px',
}

const CaptionsIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect x="4.75" y="4.75" width="14.5" height="14.5" rx="2.25" stroke="#1A1A1A" stroke-width="1.5"/>
            <path d="M7 13.25H17" stroke="black" stroke-width="1.5"/>
            <line x1="8" y1="16.25" x2="16" y2="16.25" stroke="black" stroke-width="1.5"/>
        </svg>
    )
}

const TextIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M8.78571 19H15.3333V18.3933H13.0714V5.60667H14.3571C15.5794 5.7 16.4444 6.50889 16.9524 8.03333L17.3333 9.59667H18L17.881 5H6.11905L6 9.59667H6.66667L7.04762 8.03333C7.06349 8.00222 7.07143 7.95556 7.07143 7.89333C7.5 6.36889 8.35714 5.60667 9.64286 5.60667H11.0238V18.3933H9.40476C9.3254 18.3933 9.24603 18.4011 9.16667 18.4167C8.9127 18.4633 8.78571 18.6578 8.78571 19Z"
                fill="black"/>
        </svg>
    )
}

interface OptionItem {
    icon?: React.FC;
    text: string;
    panel?: () => JSX.Element;
    onClick?: () => void;
    isHidden?: boolean;
}

const srtContent = `
1
00:00:01,000 --> 00:00:04,000
This is the first subtitle.

2
00:00:05,000 --> 00:00:07,000
Here comes the second line.

3
00:00:08,000 --> 00:00:10,000
The third subtitle appears now.

4
00:00:11,000 --> 00:00:13,000
Finally, this is the last one.
`;

// @ts-ignore
const SidebarComponent: React.FC = observer(({projectId, assetId}) => {
    const [activeItemKey, setActiveItemKey] = useState<string | null>(null);

    const authContext = useContext(AuthContext);
    const [projectTracks, setProjectTracks] = useState([] as any[]);
    const [projectImages, setProjectImages] = useState([] as any[]);

    // @ts-ignore
    const OPTIONS = useMemo(() => ({
        text: {
            icon: TextIcon,
            text: "Text",
            panel: () => <TextPanelContainer/>,
            onClick: () => <TextPanelContainer/>,
        },
        stock: {
            icon: CameraIcon,
            text: "Stock",
            panel: () => <StockPanelContainer onClose={() => {
            }}/>,
            onClick: () => <StockPanelContainer onClose={() => {
            }}/>,
        },
        captions: {
            icon: CaptionsIcon,
            text: "Captions",
            // @ts-ignore
            panel: () => <SubtitlesStylesContainer/>,
            // @ts-ignore
            onClick: () => <SubtitlesStylesContainer/>,
        },
        audiogram: {
            icon: GraphicEqIcon,
            text: "Audiogram",
            onClick: () => <AudiogramStylesContainer/>,
            panel: () => <AudiogramStylesContainer/>,
        },
        edit: {
            isHidden: true,
            panel: () => <EditPanelContainer/>,
            onClick: () => <EditPanelContainer/>,
            text: "Edit",
        },
    }), []);

    useEffect(() => {
        const disposeAutorun: IReactionDisposer = autorun(() => {
            setActiveItemKey(SidebarStore.activeItemKey);
        });

        const disposeReaction: IReactionDisposer = reaction(
            () => ({
                clipType: ApplicationStore.selectedClipId ? RendleyStore.clips[ApplicationStore.selectedClipId]?.type : null,
            }),
            ({clipType}) => {
                if (clipType === ClipTypeEnum.SUBTITLES) {
                    SidebarStore.setActiveItemKey("subtitles");
                    return;
                }

                if (clipType === ClipTypeEnum.TEXT || clipType === ClipTypeEnum.LOTTIE) {
                    console.log("Setting active item key to edit")
                    SidebarStore.setActiveItemKey("edit");
                    return;
                }

                SidebarStore.setActiveItemKey(null);
            },
        );

        return () => {
            disposeAutorun();
            disposeReaction();
        };
    }, []);

    const userId = authContext.getUserId();

    // Load the tracks that belong to the current project.
    useEffect(() => {
        if (projectId) {
            const q = query(collection(db, kAssetsCollectionName), where(AssetProjectId, "==", projectId), where(AssetDeleted, "==", false), where(AssetType, "==", "IMAGE_UPLOAD"));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const newImages = [];
                    querySnapshot.forEach((doc) => {
                        newImages.push(doc.data());
                    });
                    // @ts-ignore
                    // Update state only if the data has changed
                    if (JSON.stringify(newImages) !== JSON.stringify(projectImages)) {
                        setProjectImages(newImages)
                    }
                },
                (error) => {
                    if (error.code === 'permission-denied') {
                        authContext.logout();
                    }
                });

            return () => unsubscribe();
        } else {
            setProjectImages([]);
        }
    }, [projectId]);

    // Load the uploaded image assets that belong to the current project.
    useEffect(() => {
        if (projectId) {
            const q = query(collection(db, kTracksCollectionName), where(TrackProjectId, "==", projectId), where(TrackDeleted, "==", false), where(TrackUserId, "==", userId));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const newTracks = [];
                    querySnapshot.forEach((doc) => {
                        if (IsUploadedTrackType(doc.data().trackType) || doc.data().assetId === assetId || IsExportedEdit(doc.data().trackType)) {
                            newTracks.push(doc.data());
                        }
                    });
                    // @ts-ignore
                    // Update state only if the data has changed
                    if (JSON.stringify(newTracks) !== JSON.stringify(projectTracks)) {
                        setProjectTracks(newTracks)
                    }
                },
                (error) => {
                    if (error.code === 'permission-denied') {
                        authContext.logout();
                    }
                });

            return () => unsubscribe();
        } else {
            setProjectTracks([]);
        }
    }, [projectId, userId, assetId]);

    const handleItemClick = (id) => {
        if (OPTIONS[id].onClick) {
            OPTIONS[id].onClick!();
        }

        if (activeItemKey === id) {
            handleClosePanel();
        } else {
            handleOpenPanel(id);
        }
    };

    const handleOpenPanel = (id: string) => {
        SidebarStore.setActiveItemKey(id);
    };

    const handleClosePanel = () => {
        SidebarStore.setActiveItemKey(null);
    };

    const getActivePanelComponent = (): React.ReactElement | null => {
        const PanelComponent = activeItemKey && OPTIONS[activeItemKey]?.panel;
        return PanelComponent ? <PanelComponent/> : null;
    };

    const activeComponent = getActivePanelComponent();

    const isMedia = activeItemKey === "media";

    const sidebarPanelStyle = {
        top: "88px",
        right: '32px',
        position: 'absolute',
        maxWidth: '300px',
        width: '70vw',
        maxHeight: "500px",
        backgroundColor: "#ffffff",
    }

    const handleMediaOnSidebarClick = () => {
        if (activeItemKey === "media") {
            handleClosePanel();
        } else {
            handleOpenPanel("media");
        }
    }

    // @ts-ignore
    return (
        <div>
            <Box sx={mediaBarStyle}>
                <Box sx={mediaBarItemStyle} onClick={() => handleMediaOnSidebarClick()}>
                    <Box sx={{width: '24px', height: '24px'}}>
                        <FilmIcon/>
                    </Box>
                    <Box sx={mediaBarItemTextStyle}>
                        Media
                    </Box>
                </Box>
                <Box sx={mediaBarItemStyle} onClick={() => handleItemClick("stock")}>
                    <Box sx={{width: '24px', height: '24px'}}>
                        <CameraIcon/>
                    </Box>
                    <Box sx={mediaBarItemTextStyle}>
                        Stock
                    </Box>
                </Box>
                <Box sx={mediaBarItemStyle} onClick={() => handleItemClick("text")}>
                    <Box sx={{width: '24px', height: '24px'}}>
                        <TextIcon/>
                    </Box>
                    <Box sx={mediaBarItemTextStyle}>
                        Text
                    </Box>
                </Box>
                <Box sx={captionsButtonStyle(false)} onClick={() => handleItemClick("captions")}>
                    <Box sx={{width: '24px', height: '24px'}}>
                        <CaptionsIcon/>
                    </Box>
                    <Box sx={mediaBarItemTextStyle}>
                        Subtitles
                    </Box>
                </Box>
                {/*<Box sx={captionsButtonStyle(false)} onClick={() => handleItemClick("audiogram")}>*/}
                {/*    <Box sx={{width: '24px', height: '24px'}}>*/}
                {/*        <GraphicEqIcon/>*/}
                {/*    </Box>*/}
                {/*    <Box sx={mediaBarItemTextStyle}>*/}
                {/*        Audiogram*/}
                {/*    </Box>*/}
                {/*</Box>*/}
            </Box>
            {isMedia && (
                // @ts-ignore
                <div style={sidebarPanelStyle}>
                    <Panel onClose={handleClosePanel} isMedia={isMedia} projectId={projectId}>
                        <MediaPanelContainer projectTracks={projectTracks} projectImages={projectImages}/>
                    </Panel>
                </div>
            )}
            {!isMedia && activeComponent && (
                // @ts-ignore
                <div style={sidebarPanelStyle}>
                    <Panel onClose={handleClosePanel} isMedia={isMedia} projectId={projectId}>
                        {getActivePanelComponent()}
                    </Panel>
                </div>
            )}
        </div>
    );
});

const Sidebar = React.memo(SidebarComponent);

export default Sidebar;