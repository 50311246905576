import {useNavigate} from "react-router-dom";

import React, {useEffect, useRef, useState} from "react";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {Button, DialogContent, Typography} from "@mui/material";
import {NewCustomControlBar} from "../NewStudio/NewCustomControlBar";
import {NewGuestRecordingStoppedCard, NewRecordingStoppedCard} from "../Studio/RecordingStoppedCard";
import {LiveKitRoom, usePersistentUserChoices} from "@livekit/components-react";
import {
    NewGuestStudioVideoConference,
    NewGuestStudioVideoConferenceVideoRecording,
    NewStudioVideoConference
} from "../../components/LibrettoConference";
import {
    AdaptRecordingMode,
    GridVideoLayout, LibrettoPlan,
    LibrettoRecordingMode, LibrettoStudioNameStyle, LibrettoStudioTheme,
    StandardVideoResolution,
    StudioNameStyleString
} from "../../utils/utils";
import {collection, onSnapshot, query, where} from "firebase/firestore";
import {db} from "../../Firebase";
import {kRoomStateCollectionName, RoomName} from "../../utils/CollectionConstants";
import NewRecordButton, {RecordingStateDisplay} from "../NewStudio/NewRecordButton";
import {CustomLeaveButton, HostLeaveButton} from "./CustomLeaveButton";
import Dialog from "@mui/material/Dialog";
import {UnauthenticatedUserIntercomComponent} from "../../components/IntercomComponent";
import {MediaButton, NewCameraButton, NewMicrophoneButton, NewShareScreenButton} from "../NewStudio/ControlBarButtons";
import Tooltip from "@mui/material/Tooltip";
import RecordingModeToggle from "../NewStudio/RecordingModeToggle";
import {NewBackgroundMenu} from "../NewStudio/BackgroundMenu";

const serverUrl = 'wss://librettobeta-htow58t4.livekit.cloud'

const NewGuestStudio = ({guestToken, userChoices, userFriendlyStudioName, roomName, hostIdentity}) => {
    const navigate = useNavigate();

    const theme = useTheme();

    const isTooSmall = useMediaQuery(theme.breakpoints.down('smd'));

    const announcementBarStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '18px',
        backgroundColor: '#000000', // You can change this to match your theme
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999, // Ensure it's above other elements
        overflow: 'hidden',
        transition: 'height 0.3s ease',
        '&:hover': {
            height: '20px', // Expands on hover for better readability
        }
    };

    const announcementTextStyle = {
        color: 'white',
        fontSize: '13px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };

    const studioThemeToBackgroundColor = (themeArg) => {
        let themeBackgroundColor = themeArg === LibrettoStudioTheme.Light ? "#f3f4f5" : "#1A1A1A";
        if (themeArg === LibrettoStudioTheme.Libretto) {
            themeBackgroundColor = "#2B6BBD";
        }
        return themeBackgroundColor;
    }

    const pageStyle = (theme, showAnnouncement) => {

        let backgroundColor = theme === LibrettoStudioTheme.Light ? "#f3f4f5" : "#1A1A1A";
        if (theme === LibrettoStudioTheme.Libretto) {
            backgroundColor = "#2B6BBD";
        }

        return ({
            paddingTop: "12px",
            height: "100vh",
            width: "100vw",
            background: `radial-gradient(circle at top, ${backgroundColor} 0%, ${backgroundColor} 50%, ${backgroundColor} 100%), linear-gradient(180deg, ${backgroundColor} 0%, ${backgroundColor} 50%, ${backgroundColor} 100%)`,
        })
    }

    const [backgroundImage, setBackgroundImage] = useState({isBlur: false, imageUrl: null});

    const isXl = useMediaQuery(theme.breakpoints.up('xl'));
    const isLg = useMediaQuery(theme.breakpoints.up('lg'));
    const isMd = useMediaQuery(theme.breakpoints.up('md'));
    const isSmd = useMediaQuery(theme.breakpoints.up('smd'));

    const controlBarHeight = isXl ? "93px" : (isLg ? "75px" : "66px");

    const titleAreaWidth = "250px";

    const [videoAreaFullScreen, setVideoAreaFullScreen] = useState(false);

    const videoHeight = videoAreaFullScreen ? "100vh" : isXl ? "calc(100vh - 180px)" : (isLg ? "calc(100vh - 180px)" : "calc(100vh - 180px)");

    const videoWidth = videoAreaFullScreen ? "100vw" : isXl ? "calc((100vh - 180px) * 16 / 9)" : (isLg ? "calc((100vh - 180px) * 16 / 9)" : "calc((100vh - 180px) * 16 / 9)");

    const barHeight = isXl ? "100px" : (isLg ? "75px" : "66px");

    const videoDisplayStyle = (videoAreaFullScreen) => {
        return ({
            // Set height to be everything that remains in the viewport after subtracting 316px for the control bar and soundboard together.
            height: videoHeight,
            // Set the width based on the height to maintain the aspect ratio of 16:9.
            width: videoWidth,
            maxWidth: videoAreaFullScreen ? "100vw" : "90vw",
        })
    }


    const controlBarMarginBottom = isXl ? "22px" : (isLg ? "22px" : "22px");

    const [showRecordingEndedCard, setShowRecordingEndedCard] = useState(true);

    document.addEventListener('keydown', (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault(); // Prevent the default F11 behavior
            setVideoAreaFullScreen(!videoAreaFullScreen);
        } else if (event.key === 'Escape' && videoAreaFullScreen) {
            setVideoAreaFullScreen(false);
        }
    });

    const [cloudEgressStarted, setCloudEgressStarted] = useState(false);   // This means cloud egress has been started by the backend.
    const [recordingInProgress, setRecordingInProgress] = useState(false); // This is the client UI state to show flashing red and timer.
    const [countdown, setCountdown] = useState(null);
    const [recordingStarting, setRecordingStarting] = useState(false);
    const [localRecordingInProgress, setLocalRecordingInProgress] = useState(false)
    const [localRecordingDone, setLocalRecordingDone] = useState(false);
    const [selectedVideoResolution, setSelectedVideoResolution] = useState(StandardVideoResolution);
    const [noiseCancellationEnabled, setNoiseCancellationEnabled] = useState(false);
    const [localRecordingEnabled, setLocalRecordingEnabled] = useState(false);
    const [videoMirrored, setVideoMirrored] = useState(false);
    const [recordingMode, setRecordingMode] = useState(LibrettoRecordingMode.Audio);
    const [studioTheme, setStudioTheme] = useState(LibrettoStudioTheme.Light);
    const [studioNameStyle, setStudioNameStyle] = useState(LibrettoStudioNameStyle.Clean);

    const [uploadProgress, setUploadProgress] = useState(0);

    const assetIdRef = useRef(null);

    const [localRecordingStopped, setLocalRecordingStopped] = useState(false);

    // This is called so that the recording can be restarted after it has been stopped. (And recording stopped card
    // can be shown again.)
    const handleRecordingRestart = () => {
        setLocalRecordingStopped(false);
        setLocalRecordingDone(false);
        setShowRecordingEndedCard(true);
    }

    const readableRecordingMode = (mode) => {
        return mode === LibrettoRecordingMode.Audio ? "audio-only recording" : "video recording";
    }

    const startHQRecording = () => {
        if (highQualityRecordingRef.current && !highQualityRecordingRef.current.isHighQualityRecordingActive()) {
            highQualityRecordingRef.current.startRecording();
        }
    }

    const reliablyStartRecording = () => {
        startHQRecording();
        // Call start recording a couple of times to make sure it starts.
        setTimeout(() => {
            startHQRecording();
        }, 300);
        setTimeout(() => {
            startHQRecording();
        }, 600);
    }

    const stopHQRecording = () => {
        if (highQualityRecordingRef.current && highQualityRecordingRef.current.isHighQualityRecordingActive()) {
            highQualityRecordingRef.current.stopRecording();
        }
    }

    const reliablyStopRecording = () => {
        stopHQRecording();
        setTimeout(() => {
            stopHQRecording();
        }, 300);
        setTimeout(() => {
            stopHQRecording();
        }, 600);
    }

    const [showAnnouncement, setShowAnnouncement] = useState(true); // State for the announcement bar [true/false
    const [announcement, setAnnouncement] = useState("Welcome to " + userFriendlyStudioName + ".");

    useEffect(() => {
        setAnnouncement("Welcome to " + userFriendlyStudioName + ". Press Ctrl+F to toggle full screen for the video display.");
    }, [recordingMode]);

    const [selectedBrandLogo, setSelectedBrandLogo] = useState({assetId: null, url: null});

    const [triggerReload, setTriggerReload] = useState(false);

    const [room, setRoom] = useState(null);

    const [backgroundImageIndex, setBackgroundImageIndex] = useState(null);

    const highQualityRecordingRef = useRef(null);

    useEffect(() => {
        if (roomName !== '') {

            const q = query(collection(db, kRoomStateCollectionName), where(RoomName, "==", roomName));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const newRoomState = [];
                querySnapshot.forEach((doc) => {
                    newRoomState.push(doc.data());
                });
                if (newRoomState.length > 0) {
                    setNoiseCancellationEnabled(newRoomState[0].backgroundNoiseCancellationEnabled);
                    setLocalRecordingEnabled(newRoomState[0].hasOwnProperty("localRecordingEnabled") ? newRoomState[0].localRecordingEnabled : true);
                    setVideoMirrored(newRoomState[0].hasOwnProperty("videoMirrored") ? newRoomState[0].videoMirrored : false);
                    setSelectedVideoResolution(newRoomState[0].videoRecordingQuality);
                    setRecordingMode(AdaptRecordingMode(newRoomState[0].recordingType));
                    setStudioTheme(newRoomState[0].studioTheme  ? newRoomState[0].studioTheme : LibrettoStudioTheme.Light);
                    setStudioNameStyle(newRoomState[0].studioNameStyle ? newRoomState[0].studioNameStyle : LibrettoStudioNameStyle.Clean);
                    setSelectedBrandLogo(newRoomState[0].studioBrandLogo && newRoomState[0].studioBrandLogo != "" ? newRoomState[0].studioBrandLogo : {assetId: null, url: null});
                    // RoomNotRecording
                    if (newRoomState[0].roomState === 0) {
                        setRecordingInProgress(false);
                        setCloudEgressStarted(false);
                        setCountdown(null);
                        setRecordingStarting(false);
                        reliablyStopRecording();
                    }
                    // RoomRecordingStarting
                    if (newRoomState[0].roomState === 1 && countdown === null) {
                        setRecordingStarting(true);
                        assetIdRef.current = newRoomState[0].currentAssetId;
                        setCountdown(5);
                        setShowAnnouncement(false);
                        handleRecordingRestart();
                        reliablyStartRecording();
                    }
                    // RoomRecordingInProgress
                    if (newRoomState[0].roomState === 2 && !cloudEgressStarted) {
                        setCloudEgressStarted(true);
                        reliablyStartRecording();
                    }
                }
            });

            return () => unsubscribe();
        } else {

        }
    }, [roomName]);

    // The client UI state.
    useEffect(() => {
        if (cloudEgressStarted && countdown === null) {
            setRecordingInProgress(true);
        }
    }, [cloudEgressStarted, countdown]);

    useEffect(() => {
        setLocalRecordingInProgress(recordingStarting || recordingInProgress);
    }, [recordingStarting, recordingInProgress]);

    // Countdown before recording starts.
    useEffect(() => {
        let intervalId;
        if (countdown > 0) {
            intervalId = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setCountdown(null);
        }

        // Clear interval on unmount or countdown change
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [countdown]);

    const titleBoxStyle = {
        display: isSmd ? 'flex' : 'none',
    }

    const titleStyle = (theme) => {
        return ({
            fontFamily: "Inter",
            color: theme === LibrettoStudioTheme.Light ? "#1A1A1A" : "#ffffff",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0.14px",
            // Hide the title if the screen is below 1200px.
        })
    }

    const countdownBoxStyle = {
        position: "absolute",
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(26, 26, 26, 0.15)",
        zIndex: 1000,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    }

    const textStyle = {
        fontFamily: "Inter",
        fontSize: {md: "9px", lg: "11px", xl: "14px"},
        fontStyle: "normal",
        fontWeight: {md: "350", lg: "400", xl: "500"},
        lineHeight: {md: "13px", lg: "15px", xl: "20px"},
        letterSpacing: {md: "0.1px" , lg:"0.1px", xl: "0.14px"},
        color: "#1A1A1A",
        // disable highlight on double click
        WebkitTouchCallout: "none",
        WebkitUserSelect: "none",
        KhtmlUserSelect: "none",
        MozUserSelect: "none",
        msUserSelect: "none",
    }

    const countdownTextStyle = {
        color: "#2B6BFD",
        fontSize: "120px",
        fontStyle: "normal",
        fontWeight: "800",
        lineHeight: "normal",
        opacity: 1,
        zIndex: 999999,
        fontFamily: "Inter, sans-serif",  // Added fallback font
    }

    const onLeave = () => {
        navigate("/dashboard");
    }

    const soundBoardStyle = (videoAreaFullScreen) => {
        return ({
            height: "40px",
            marginTop: controlBarMarginBottom,
            paddingLeft: "22px",
            paddingRight: "22px",
            paddingTop: "12px",
            paddingBottom: "12px",
            display: videoAreaFullScreen ? 'none' : 'inline-flex',
            width: "auto",
            borderRadius: "12px",
            boxShadow: "0px 20px 100px 0px rgba(0, 0, 0, 0.05)",
            backgroundColor: "transparent",
        })
    }

    const smallButtonStyle = {
        display: 'flex',
        height: "40px",
        padding: "10px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        cursor: 'pointer',
        width: "110px",
        flexDirection: "row",
        borderRadius: "5px",
        border: "1px solid #D5D5D5",
        transition: 'background-color 0.3s',
        backgroundColor: "#f3f4f5",
        '&:hover': {
            backgroundColor: '#e5e5e5', // Darker primary color
        }
    }

    const topAreaStyle = (videoAreaFullScreen) =>  {
        return ({
            display: videoAreaFullScreen ? 'none' : 'flex',
            marginTop: "12px",
        })
    }

    const [isScreenShareEnabled, setIsScreenShareEnabled] = React.useState(false);

    const onScreenShareChange = React.useCallback(
        (enabled) => {
            setIsScreenShareEnabled(enabled);
        },
        [setIsScreenShareEnabled],
    );

    const {
        saveAudioInputEnabled,
        saveVideoInputEnabled,
        saveAudioInputDeviceId,
        saveVideoInputDeviceId,
    } = usePersistentUserChoices({preventSave: false});

    const microphoneOnChange = React.useCallback(
        (enabled, isUserInitiated) =>
            isUserInitiated ? saveAudioInputEnabled(enabled) : null,
        [saveAudioInputEnabled],
    );

    const cameraOnChange = React.useCallback(
        (enabled, isUserInitiated) =>
            isUserInitiated ? saveVideoInputEnabled(enabled) : null,
        [saveVideoInputEnabled],
    );

    return (
        <>
            {showAnnouncement && !isTooSmall && <Box sx={announcementBarStyle}>
                <Typography sx={announcementTextStyle}>{announcement}</Typography>
            </Box>}
            <UnauthenticatedUserIntercomComponent/>
            <Dialog
                open={isTooSmall}
                onClose={() => {}}
                aria-labelledby="form-dialog-title"
            >
                <DialogContent sx={{borderRadius: '30px',
                    display: 'flex',        // Enable flexbox
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center',     // Center vertically
                    minWidth: { xs: '300px', md: '400px' },
                }}>
                    <Typography><b>Please switch to a larger screen.</b> For now, you need a larger screen here.</Typography>
                </DialogContent>
            </Dialog>
        <Grid container direction="column" style={pageStyle(studioTheme, showAnnouncement)} justifyContent="center" alignItems="center">
            <Grid container justifyContent="space-between" alignItems="flex-start" position="relative" mb={2} sx={topAreaStyle(videoAreaFullScreen)}>
                <Grid item marginLeft="32px" paddingTop={0} width={titleAreaWidth}
                      style={{display: "flex", justifyContent: "flex-start"}}>
                    <Box display={'flex'} gap={"8px"} paddingBottom={2} alignItems={"center"} justifyContent={"center"}>
                        <Box style={titleBoxStyle}>
                            <Typography style={titleStyle(studioTheme)}>{userFriendlyStudioName}</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item marginRight="32px" width="400px" gap={"12px"} alignItems={"center"} justifyContent={"center"} style={{display: isTooSmall ? "none" : "flex", justifyContent: "flex-end"}}>
                    <Box display={'flex'} gap={"12px"}>
                        {<NewMicrophoneButton room={room} onChange={microphoneOnChange} saveAudioInputDevice={saveAudioInputDeviceId}/>}
                        {<NewCameraButton room={room} onChange={cameraOnChange} saveVideoInputDevice={saveVideoInputDeviceId}/>}
                        {<Tooltip title={"Recording mode"}>
                            <Box sx={{height: "40px", display: "flex", alignItems: "center"}}>
                                <RecordingModeToggle recordingMode={recordingMode} roomName={roomName} userPlan={LibrettoPlan.Free} isDisabled={true}/>
                            </Box>
                        </Tooltip>}
                        {<NewShareScreenButton room={room} onChange={onScreenShareChange}/>}
                        {<NewBackgroundMenu setBackgroundImage={setBackgroundImage} initialSelection="none" onBackgroundChange={() => {}} backgroundImageIndex={backgroundImageIndex} setBackgroundImageIndex={setBackgroundImageIndex}/>}
                    </Box>
                    <Box
                        component={'img'}
                        src={'/logo-new.svg'}
                        height={0.65}
                        width={0.65}
                        onClick={() => navigate("/dashboard")}
                        style={{cursor: "pointer"}}
                    />
                </Grid>
            </Grid>
            {localRecordingStopped ? <NewGuestRecordingStoppedCard uploadProgress={uploadProgress} showRecordingEndedCard={showRecordingEndedCard} setShowRecordingEndedCard={setShowRecordingEndedCard} localRecordingDone={localRecordingDone}/> : null}
            <Grid item justifyContent="center" alignItems="center" style={videoDisplayStyle(videoAreaFullScreen)}>
                <LiveKitRoom key={triggerReload} serverUrl={serverUrl} token={guestToken}
                             data-lk-theme="default"
                             video={userChoices.videoEnabled} audio={userChoices.audioEnabled}
                             style={{height: '100%', width: '100%', borderRadius: '8px'}}
                             connect={true} onDisconnected={onLeave}>
                    {recordingMode === LibrettoRecordingMode.Audio ? <NewGuestStudioVideoConference
                        assetIdRef={assetIdRef}
                        localRecordingEnabled={localRecordingEnabled}
                        backgroundImage={backgroundImage}
                        recordingInProgress={recordingInProgress}
                        setUploadProgress={setUploadProgress}
                        highQualityRecordingRef={highQualityRecordingRef}
                        themeBackgroundColor={studioThemeToBackgroundColor(studioTheme)}
                        videoMirrored={videoMirrored}
                        startRecording={null}
                        roomName={roomName}
                        studioNameStyle={StudioNameStyleString(studioNameStyle)}
                        localRecordingDone={localRecordingDone}
                        stopRecording={null}
                        hostIdentity={hostIdentity}
                        brandLogoUrl={selectedBrandLogo.url}
                        enableKrispNoiseCancellation={noiseCancellationEnabled}
                        localRecordingInProgress={localRecordingInProgress}
                        livekitToken={guestToken}

                        setLocalRecordingDone={setLocalRecordingDone}
                        setLocalRecordingStopped={setLocalRecordingStopped}
                        videoResolution={selectedVideoResolution}
                        setRoom={setRoom}
                    /> : <NewGuestStudioVideoConferenceVideoRecording
                        assetIdRef={assetIdRef}
                        setUploadProgress={setUploadProgress}
                        localRecordingEnabled={localRecordingEnabled}
                        themeBackgroundColor={studioThemeToBackgroundColor(studioTheme)}
                        backgroundImage={backgroundImage}
                        recordingInProgress={recordingInProgress}
                        highQualityRecordingRef={highQualityRecordingRef}
                        startRecording={null}
                        videoMirrored={videoMirrored}
                        roomName={roomName}
                        studioNameStyle={StudioNameStyleString(studioNameStyle)}
                        localRecordingDone={localRecordingDone}
                        stopRecording={null}
                        hostIdentity={hostIdentity}
                        brandLogoUrl={selectedBrandLogo.url}
                        enableKrispNoiseCancellation={noiseCancellationEnabled}
                        localRecordingInProgress={localRecordingInProgress}
                        livekitToken={guestToken}
                        setLocalRecordingDone={setLocalRecordingDone}
                        setLocalRecordingStopped={setLocalRecordingStopped}
                        videoResolution={selectedVideoResolution}
                        setRoom={setRoom} />}
                </LiveKitRoom>
            </Grid>
            <Grid container item justifyContent="center" alignItems="center"
                  display={isTooSmall ? "none" : "inline-flex"} mb={2}>
                <Grid container item justifyContent="center" alignItems="center" display="flex">
                    <Grid item style={soundBoardStyle(videoAreaFullScreen)}>
                        <Grid container spacing={1.5} direction="row" alignSelf="center" justifySelf="center">
                            <Grid item>
                                <RecordingStateDisplay recordingInProgress={recordingInProgress} localRecordingInProgress={localRecordingInProgress}/>
                            </Grid>
                            <Grid item>
                                {room && <CustomLeaveButton room={room}/>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item alignSelf="center">
                {countdown !== null && countdown > 0 && (<Box
                    style={countdownBoxStyle}
                >
                    <Box style={countdownTextStyle}
                    >
                        {countdown}
                    </Box>
                </Box>)}
            </Grid>
        </Grid>
        </>
    );
}

export default NewGuestStudio;
