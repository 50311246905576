import React from "react";
import { Box, Typography } from "@mui/material";

const EmailBadge = ({ email }) => {
    // Define the 7 solid colors for the circle border
    const colors = ["#FF4500", "#32CD32", "#1E90FF", "#FFD700", "#FF1493", "#8A2BE2", "#00CED1"];

    const initial = email.charAt(0).toUpperCase();

    const initialToNumber = initial.charCodeAt(0) - 65; // Convert A-Z to 0-25

    // Generate a random color from the array
    const randomColor = colors[initialToNumber % colors.length];

    return (
        <Box
            display="flex"
            alignItems="center"
            mb={1} // Add spacing between badges
        >
            {/* Circle with initial */}
            <Box
                sx={{
                    width: 25,
                    height: 25,
                    borderRadius: "50%",
                    backgroundColor: randomColor,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#FFFFFF",
                    fontWeight: "bold",
                    fontSize: 15,
                }}
            >
                {initial}
            </Box>

            {/* Email text */}
            <Typography
                sx={{
                    marginLeft: 2,
                    color: "#1a1a1a",
                    fontSize: 16,
                }}
            >
                {email}
            </Typography>
        </Box>
    );
};

export default EmailBadge;