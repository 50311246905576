import {UpChunk} from "@mux/upchunk";
import AudioThumbnail from "../assets/AudioThumbnail.png";
import VideoThumbnail from "../assets/VideoThumbnail.png";
import axios from "axios";
import dayjs from "dayjs";

function compareTracksForSort(track1, track2) {
    if (track1.trackType === TypeOfTrack.RoomComposite || track1.trackType === TypeOfTrack.RoomCompositeAudioOnly || track1.trackType === TypeOfTrack.LocalAudioRecordingComposite || track1.trackType === TypeOfTrack.LocalVideoRecordingComposite) {
        return -1;
    }

    if (track2.trackType === TypeOfTrack.RoomComposite || track2.trackType === TypeOfTrack.RoomCompositeAudioOnly || track2.trackType === TypeOfTrack.LocalAudioRecordingComposite || track2.trackType === TypeOfTrack.LocalVideoRecordingComposite) {
        return 1;
    }

    if (track1.title === track2.title) {
        return -1;
    }

    return track1.title.localeCompare(track2.title);
}

function formatDuration(seconds) {
    // Round to first decimal place
    const totalSeconds = Math.round(Number(seconds)); // Round to nearest whole number
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const remainingSeconds = totalSeconds % 60;

    const padWithZero = (number) => number.toString().padStart(2, '0');

    return `${padWithZero(hours)}:${padWithZero(minutes)}:${padWithZero(remainingSeconds)}`;
}

// Show minutes and seconds only
export function formatDurationBrief(seconds) {
    const totalSeconds = Math.round(Number(seconds)); // Round to nearest whole number
    const minutes = Math.floor(totalSeconds / 60);
    const remainingSeconds = totalSeconds % 60;

    const padWithZero = (number) => number.toString().padStart(2, '0');

    return `${padWithZero(minutes)}:${padWithZero(remainingSeconds)}`;
}

export function formatDurationSidebar(seconds) {
    const totalSeconds = Math.round(Number(seconds)); // Round to nearest whole number
    const minutes = Math.floor(totalSeconds / 60);
    const remainingSeconds = totalSeconds % 60;

    // Format minutes and seconds as needed
    if (minutes >= 60) {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return `${hours}h ${remainingMinutes}m`;
    }

    if (minutes >= 1) {
        return `${minutes}m ${remainingSeconds}s`;
    }

    return `${remainingSeconds}s`;
}

export const FormatUnixTimeForSchedule = (timestamp, timezone) => {
    return dayjs.unix(timestamp).tz(timezone).format("ddd MMM DD");
};

export const FormatTimeRange = (startTime, endTime, timezone) => {
    const start = dayjs.unix(startTime).tz(timezone).format("h:mm A");
    const end = dayjs.unix(endTime).tz(timezone).format("h:mm A");
    return `${start} - ${end}`;
};

function formatDurationForEditor(seconds) {
    // Use the number as is, no initial rounding
    const totalSeconds = Number(seconds);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const remainingSeconds = (totalSeconds % 60).toFixed(1); // Keep one decimal

    const padWithZero = (number) => number.toString().padStart(2, '0');

    // Ensure seconds are padded to at least two digits before the decimal
    const paddedSeconds = remainingSeconds.includes('.') ? remainingSeconds.padStart(4, '0') : remainingSeconds;

    return `${padWithZero(hours)}:${padWithZero(minutes)}:${paddedSeconds}`;
}

function formatSampleRate(sampleRate) {
    if (sampleRate < 1000) {
        return `${sampleRate}Hz`;
    } else {
        const kHz = sampleRate / 1000;
        return `${kHz.toFixed(0)}kHz`;
    }
}

const formatToReadableSourceAndType = (assetType) => {
    if (assetType === "VIDEO_RECORDING") {
        return "Recorded on Libretto";
    }
    if (assetType === "VIDEO_UPLOAD") {
        return "External recording";
    }
    if (assetType === "AUDIO_ONLY_RECORDING") {
        return "Recorded on Libretto";
    }
    if (assetType === "AUDIO_ONLY_UPLOAD") {
        return "External recording";
    }
    return "Unknown";
}

function formatUnixTime(unixTime) {
    const date = new Date(unixTime * 1000); // Convert seconds to milliseconds
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const year = date.getFullYear();
    const month = monthNames[date.getMonth()];
    const day = date.getDate().toString().padStart(2, '0');

    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedHours = hours.toString().padStart(2, '0');

    return `${month} ${day} ${year}, ${formattedHours}:${minutes} ${ampm}`;
}

function formatUnixTimeForTitle(unixTime) {
    const time = formatUnixTime(unixTime);
    // Replace spaces with underscores
    return time.replace(/ /g, '_');
}

function formatUnixTimeBrief(unixTime) {
    const date = new Date(unixTime * 1000); // Convert seconds to milliseconds
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const year = date.getFullYear();
    const month = monthNames[date.getMonth()];
    const day = date.getDate().toString().padStart(2, '0');

    return `${month} ${day} ${year}`;
}

function formatToReadableTitle(title) {
    // Truncate if too long and add a ...
    if (title.length > 20) {
        return title.substring(0, 17) + '...';
    }
    return title;
}

function formatToReadableShortTitle(title) {
    // Truncate if too long and add a ...
    if (title.length >= 15) {
        return title.substring(0, 13) + '..';
    }
    return title;
}

export const PlanToRenderablePlan = (plan) => {
    if (plan === 0) {
        return 'Free';
    } else if (plan === 1) {
        return 'Free';
    } else if (plan === 2) {
        return 'Standard - Monthly';
    } else if (plan === 3) {
        return 'Standard - Annual';
    } else if (plan === 4) {
        return 'Creator - Monthly';
    } else if (plan === 5) {
        return 'Creator - Annual';
    } else if (plan === 6) {
        return 'AppSumo - Standard Lifetime';
    } else if (plan === 7) {
        return 'AppSumo - Creator Lifetime';
    } else  {
        return 'Free';
    }
}

export const LibrettoPlanToSubscriptionDesc = (plan) => {
    if (plan < 2) {
        return {name: 'Free', desc: 'Start your journey', price: '$0/month'};
    } else if (plan === 2) {
        return {name: 'Standard', desc: 'Recording and editing essentials', price: '$15/month'};
    } else if (plan === 3) {
        return {name: 'Standard Annual', desc: 'Recording and editing essentials', price: '$144/year'};
    } else if (plan === 4) {
        return {name: 'Creator', desc: 'Recording and editing suite', price: '$24/month'};
    } else if (plan === 5) {
        return {name: 'Creator Annual', desc: 'Recording and editing suite', price: '$240/year'};
    } else if (plan === 6) {
        return {
            name: 'Standard Lifetime',
            desc: 'Recording and editing essentials',
            price: 'AppSumo purchase'
        };
    } else if (plan === 7) {
        return {name: 'Creator Lifetime', desc: 'Recording and editing suite', price: 'AppSumo purchase'};
    } else {
        return {name: 'Free', desc: 'Start your journey', price: '$0/month'};
    }
}

function formatToReadableLargeTitle(title) {
    // Truncate if too long and add a ...
    if (title.length > 30) {
        return title.substring(0, 27) + '...';
    }
    return title;
}

function formatBitrate(bitrate) {
    // The input is in bps. Convert to kbps. Round to nearest whole number.
    bitrate = Math.round(bitrate / 1000);
    return `${bitrate}kbps`;
}

function getFileTypeFromMime(mime) {
    mime = mime.toLowerCase();
    const [type] = mime.split('/');
    if (type === 'audio') {
        return 'audio';
    } else if (type === 'video') {
        return 'video';
    } else if (type === 'image') {
        return IMAGE_UPLOAD;
    }
    return 'unknown';
}

function uploadFileWithProgress(signedUrl, file, setProgress, setDone) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        // Open a PUT request to the signed URL
        xhr.open("PUT", signedUrl);

        // Set up a listener to monitor the progress of the upload
        xhr.upload.onprogress = function (event) {
            if (event.lengthComputable) {
                const percentCompleted = (event.loaded / event.total) * 100;
                setProgress(percentCompleted);
            }
        };

        // Event handler for when the upload is complete
        xhr.onload = function () {
            if (xhr.status >= 200 && xhr.status < 300) {
                console.log("Upload successful!");
                setDone(true);
                resolve(xhr.response);
            } else {
                console.log(`Failed to upload file. Status: ${xhr.status}`);
                reject(xhr.statusText);
            }
        };

        // Event handler for when there's an error
        xhr.onerror = function () {
            console.log("An error occurred during the upload");
            reject(xhr.statusText);
        };

        // Send the file
        xhr.send(file);
    });
}

export const UploadWithSignedUrl = async ({
    blob,
    signedUrl,
    isVideo,
    setProgress,
    setRecordingDone
}) => {

    const file = isVideo ? new File([blob], 'video.mkv', {type: 'video/x-matroska'}) :
        new File([blob], 'audio.wav', {type: 'audio/wav'});

    await uploadFileWithProgress(signedUrl, file, setProgress, setRecordingDone);
}

const UploadToLibretto = async ({
                                    inputRef,
                                    filetype,
                                    projectId,
                                    editId,
                                    languageCode,
                                    transcriptionMode,
                                    setProgress,
                                    fetchContext,
                                    authContext
                                }) => {
    let response;

    try {
        response = await fetchContext.authAxios.post('/assets/upload', {
            projectId: projectId,
            title: inputRef.files[0].name,
            fileType: filetype,
            editId: editId,
            languageCode: languageCode,
            transcriptionMode: transcriptionMode,
        }, {
            headers: {
                Authorization: `Bearer ${authContext.getToken()}`,
            }
        });
    } catch (error) {
        if (error.response.status === 401) {
            await RefreshTokenAndRetry(error, authContext, fetchContext);
        }
    }

    const assetId = response.data.assetId;
    const signedUrl = response.data.signedUrl;

    const setUploadProgress = (progress) => {
        setProgress({assetId: assetId, uploadProgress: progress});
    }

    const setRecordingDone = () => {

    }

    await uploadFileWithProgress(signedUrl, inputRef.files[0], setUploadProgress, setRecordingDone);

    return assetId;
}

const IsVideoAsset = (asset) => {
    return asset.assetType === "VIDEO_RECORDING" || asset.assetType === "VIDEO_UPLOAD" || asset.assetType === "SCREEN_RECORDING";
}

const determineThumbnailUrl = (asset, assetThumbnailUrl) => {
    const isVideo = IsVideoAsset(asset);

    const audioThumbnailUrl = AudioThumbnail;
    const videoThumbnailUrl = assetThumbnailUrl ? assetThumbnailUrl : VideoThumbnail;

    const thumbnailUrl = asset.status === 'Ready' ? videoThumbnailUrl : 'https://dummyimage.com/510x300/323232/ffffff.jpg&text=Processing';

    return isVideo ? thumbnailUrl : audioThumbnailUrl;
}

const SendEndOfStreamMarker = async (fetchContext, trackId, livekitToken, setLocalRecordingDone) => {
    try {
        const {data} = await fetchContext.authAxios.post(`/tracks/upload/${trackId}`, {},
            {
                headers: {
                    Authorization: `Bearer ${livekitToken}`,
                }
            });
    } catch (err) {
        console.log("Error is ", err);
    }
}

const RefreshTokenAndRetry = async (error, authContext, fetchContext) => {
    const originalRequest = error.config;
    const newToken = await authContext.refreshToken();
    originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
    return fetchContext.authAxios(originalRequest);
}

const UploadSerializedChunks = async ({
                                          blob,
                                          fetchContext,
                                          livekitToken,
                                          assetId,
                                          setRecordingDone,
                                          setUploadProgress,
                                          isVideo
                                      }) => {

    const file = isVideo ? new File([blob], 'video.mkv', {type: 'video/x-matroska'}) :
        new File([blob], 'audio.wav', {type: 'audio/wav'});

    const upload = UpChunk.createUpload({
        endpoint: `${fetchContext.getBaseURL()}/track/upload/${assetId}`, // Authenticated url
        headers: {
            Authorization: `Bearer ${livekitToken}`,
        },
        file: file, // File object with your video file’s properties
        chunkSize: 30720, // Uploads the file in ~30 MB chunks
    });

    // Subscribe to events
    upload.on('error', error => {
        console.log('Error uploading file:', error);
    });

    upload.on('progress', (progress) => {
        setUploadProgress(progress.detail);
    });

    upload.on('success', () => {
        setRecordingDone(true);
    });
}

const determineTrackThumbnailUrl = (track) => {
    const isVideo = track.trackType ? IsVideoTrackType(track.trackType) : track.mediaType === "Video";

    const audioThumbnailUrl = AudioThumbnail;
    const videoThumbnailUrl = track.thumbnailUrl ? track.thumbnailUrl : VideoThumbnail;

    const thumbnailUrl = track.status === 'Ready' ? videoThumbnailUrl : 'https://dummyimage.com/510x300/000000/ffffff.jpg&text=Processing';

    return isVideo ? thumbnailUrl : audioThumbnailUrl;
}

const ReadableUploadedTrackTitle = (trackTitle, trackCreatedAt) => {
    const createTime = formatUnixTimeForTitle(trackCreatedAt);
    const titleForFilename = trackTitle.replace(/ /g, "_");
    return `${titleForFilename}_${createTime}`;
}

const ReadableRecordedTrackTitle = (assetTitle, trackTitle, trackCreatedAt) => {
    const createTime = formatUnixTimeForTitle(trackCreatedAt);
    const assetTitleForFilename = assetTitle.replace(/ /g, "_");
    const titleForFilename = trackTitle.replace(/ /g, "_");

    if (assetTitleForFilename) {
        return `${assetTitleForFilename}_${createTime}_${titleForFilename}`;
    }

    return `${titleForFilename}_${createTime}`;
}

export const EditorMediaTypeEnums = {
    IMAGE: 0,
    VIDEO: 1,
    AUDIO: 2,
}

export const StudioMediaBoardTypeEnums = {
    SFX: 0,
    MUSIC: 1,
    UPLOADS: 2,
}

const handleDownloadButtonClick = async ({
                                             assetTitle,
                                             trackTitle,
                                             trackUrl,
                                             trackCreatedAt,
                                             isRecording,
                                             customTitle
                                         }) => {
    if (assetTitle === null || assetTitle === undefined) {
        assetTitle = "";
    }
    try {
        const response = await fetch(trackUrl);
        const blob = await response.blob(); // Create a Blob from the response

        // Create a link and set the URL as an object URL pointing to the Blob
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        let downloadFilename = "";
        if (customTitle != null && customTitle !== "") {
            downloadFilename = customTitle;
        } else {
            downloadFilename = isRecording ? ReadableRecordedTrackTitle(assetTitle, trackTitle, trackCreatedAt) : ReadableUploadedTrackTitle(trackTitle, trackCreatedAt);
        }

        // Apply an appropriate file extension
        let extension = "";
        if (trackUrl.includes("mp4")) {
            extension = ".mp4";
        } else if (trackUrl.includes("wav")) {
            extension = ".wav";
        } else if (trackUrl.includes("ogg")) {
            extension = ".ogg";
        } else if (trackUrl.includes("webm")) {
            extension = ".webm";
        } else if (trackUrl.includes("mp3")) {
            extension = ".mp3";
        } else if (trackUrl.includes("m4a")) {
            extension = ".m4a";
        }

        function getFileExtension(url) {
            // Fixed position of the extension in the URL
            const prefixLength = 52;

            // Extract the substring starting from the fixed prefix length
            const substring = url.slice(prefixLength);

            // Find the position of the next dot, and determine the end of the extension
            const dotIndex = substring.indexOf('.');

            // Check for either `/`, `?`, or the end of the substring as the end delimiter
            const endIndex = substring.search(/[\/?]/, dotIndex);

            // Return the extension by slicing from dotIndex up to endIndex or end of substring
            return substring.slice(dotIndex + 1, endIndex === -1 ? undefined : endIndex);
        }

        if (!extension && trackUrl) {
            extension = "." + getFileExtension(trackUrl);
        }

        downloadFilename += extension;
        downloadLink.download = downloadFilename;

        // This part remains the same, triggers the download and cleans up
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(downloadLink.href); // Clean up the object URL
    } catch (error) {
        console.error("Failed to download the file:", error);
    }
};

function Int16ToUint8Array(int16Array) {
    // Create a new ArrayBuffer with a size in bytes that can hold all the int16 values
    const buffer = new ArrayBuffer(int16Array.length * 2); // 2 bytes per int16
    const view = new DataView(buffer);

    // Fill the buffer with int16 values, converting each to 2 bytes
    int16Array.forEach((value, index) => {
        view.setInt16(index * 2, value, true); // true for little-endian
    });

    // Create and return a Uint8Array view of the buffer
    return new Uint8Array(buffer);
}

function CombineAudioChunks(audioChunks) {
    // Calculate the total size of all chunks
    const totalSize = audioChunks.reduce((acc, chunk) => acc + chunk.length, 0);

    // Create a new Uint8Array with the total size
    const combinedData = new Uint8Array(totalSize);

    // Copy each chunk into the combinedData array
    let offset = 0;
    for (const chunk of audioChunks) {
        combinedData.set(chunk, offset);
        offset += chunk.length;
    }

    return combinedData;
}

// AudioData has attributes timestamp (number) and audioData (Uint8Array)
function SerializeAudioChunks(audioChunks) {
    let totalSize = audioChunks.reduce((acc, chunk) => acc + 8 + 4 + chunk.audioData.length, 0);

    console.log("Serialized audio chunks to send has size: ", totalSize);

    let serializedBuffer = new ArrayBuffer(totalSize);
    let view = new DataView(serializedBuffer);

    let offset = 0;
    for (let chunk of audioChunks) {
        // eslint-disable-next-line no-undef
        view.setBigUint64(offset, BigInt(Math.round(chunk.timestamp)), true); // true for little endian
        offset += 8;

        view.setUint32(offset, chunk.audioData.length, true); // true for little endian
        offset += 4;

        // Write audioData (Uint8Array)
        new Uint8Array(serializedBuffer, offset, chunk.audioData.length).set(chunk.audioData);
        offset += chunk.audioData.length;
    }

    return new Uint8Array(serializedBuffer);
}

async function SerializeVideoChunks(videoBlobs) {
    // First, convert all Blobs to Uint8Arrays
    const chunksPromises = videoBlobs.map(async (blob) => {
        const arrayBuffer = await blob.arrayBuffer();
        return new Uint8Array(arrayBuffer);
    });

    const chunks = await Promise.all(chunksPromises);

    // Calculate total size needed for the serialized data
    let totalSize = chunks.reduce((acc, chunk) => acc + 8 + 4 + chunk.length, 0);

    let serializedBuffer = new ArrayBuffer(totalSize);
    let view = new DataView(serializedBuffer);

    let offset = 0;
    for (let chunk of chunks) {
        // eslint-disable-next-line no-undef
        view.setBigUint64(offset, BigInt(0), true); // true for little endian
        offset += 8;

        // Write chunk length (4 bytes)
        view.setUint32(offset, chunk.length, true); // true for little endian
        offset += 4;

        // Write chunk data (Uint8Array)
        new Uint8Array(serializedBuffer, offset, chunk.length).set(chunk);
        offset += chunk.length;
    }

    return new Uint8Array(serializedBuffer);
}

const MergeTrims = ({trims, fillerWordRemovalTrims}) => {
    const combined = [...trims, ...fillerWordRemovalTrims];

    // Sort the combined list by startTime
    combined.sort((a, b) => a.startTime - b.startTime);

    // Function to merge intervals
    const merged = [];

    if (combined.length === 0) {
        return merged;
    }

    let currentInterval = combined[0];

    for (let i = 1; i < combined.length; i++) {
        const nextInterval = combined[i];

        // Check if intervals overlap
        if (currentInterval.endTime >= nextInterval.startTime) {
            // Merge intervals
            currentInterval.endTime = Math.max(currentInterval.endTime, nextInterval.endTime);
        } else {
            // Add the non-overlapping interval to the result list
            merged.push(currentInterval);
            currentInterval = nextInterval;
        }
    }

    // Add the last interval
    merged.push(currentInterval);

    return merged;
}

function Float32ToInt16Array(buffer) {
    let l = buffer.length;
    let buf = new Int16Array(l);

    for (let i = 0; i < l; i++) {
        // Scale the float values (range -1 to 1) by 32768; clip to the max/min 16-bit values
        let s = Math.max(-1, Math.min(1, buffer[i]));
        buf[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
    }
    return buf;
}

export class TimeKeeper {
    hasStartTime = false;

    constructor() {
        this.startTime = performance.now();
        this.performanceStartTime = performance.now();
    }

    canBeUsed() {
        return this.hasStartTime;
    }

    setStartTime(timeInMillis) {
        this.hasStartTime = true;
        this.startTime = timeInMillis;
        this.performanceStartTime = performance.now();
    }

    getCurrentTime() {
        return this.startTime + (performance.now() - this.performanceStartTime);
    }
};

export const TypeOfTrack = {
    RoomComposite: 0,
    RoomCompositeAudioOnly: 1,
    TrackComposite: 2,
    TrackAudioOnly: 3,
    TrackVideo: 4,
    EnhancedAudio: 5,
    LocalAudioRecording: 6,
    LocalVideoRecording: 7,
    UploadedAudio: 8,
    UploadedVideo: 9,
    LocalAudioRecordingComposite: 10,
    LocalVideoRecordingComposite: 11,
    ExportedAudioEdit: 12,
    ExportedVideoEdit: 13,
    UnknonUploadType: 14,
    SoundboardRecording: 15,
    SoundboardLocalRecording: 16,
    UploadedSoundboardSound: 17,
    ScreenRecordingVideo: 18,
    ScreenRecordingComposite: 19,
};

export const loadTranscriptForTrack = async ({trackId, authContext, fetchContext}) => {
    try {
        const {data} = await fetchContext.authAxios.get('/transcript/' + trackId, {
            headers: {
                Authorization: `Bearer ${authContext.getToken()}`,
            },
        });
        console.log("Transcript loaded successfully: ", data)
        return data;
    } catch (error) {
        console.log("Error loading transcript:", error);
        if (error.response.status === 401) {
            await RefreshTokenAndRetry(error, authContext, fetchContext);
        }
        if (error.response.status === 404) {
            return null;
        }
        console.error("Error loading transcript:", error);
    }
}

// Convert track type to string based on the track type enumeration
function TrackTypeToString(trackType) {
    switch (trackType) {
        case TypeOfTrack.RoomComposite:
            return "ROOM_COMPOSITE";
        case TypeOfTrack.RoomCompositeAudioOnly:
            return "ROOM_COMPOSITE_AUDIO_ONLY";
        case TypeOfTrack.TrackComposite:
            return "TRACK_COMPOSITE";
        case TypeOfTrack.TrackAudioOnly:
            return "TRACK_AUDIO_ONLY";
        case TypeOfTrack.TrackVideo:
            return "TRACK_VIDEO";
        case TypeOfTrack.EnhancedAudio:
            return "ENHANCED_AUDIO";
        case TypeOfTrack.LocalAudioRecording:
            return "LOCAL_AUDIO_RECORDING";
        case TypeOfTrack.LocalVideoRecording:
            return "LOCAL_VIDEO_RECORDING";
        case TypeOfTrack.UploadedAudio:
            return "UPLOADED_AUDIO";
        case TypeOfTrack.UploadedVideo:
            return "UPLOADED_VIDEO";
        case TypeOfTrack.LocalAudioRecordingComposite:
            return "LOCAL_AUDIO_RECORDING_COMPOSITE";
        case TypeOfTrack.LocalVideoRecordingComposite:
            return "LOCAL_VIDEO_RECORDING_COMPOSITE";
        default:
            return "UNKNOWN";
    }
}

function IsUploadedAssetType(assetType) {
    return assetType === "VIDEO_UPLOAD" || assetType === "AUDIO_ONLY_UPLOAD" || assetType === "SCREEN_RECORDING";
}

function IsLocalRecordingTrackType(trackType) {
    return trackType === TypeOfTrack.LocalAudioRecording || trackType === TypeOfTrack.LocalVideoRecording || trackType === TypeOfTrack.LocalAudioRecordingComposite || trackType === TypeOfTrack.LocalVideoRecordingComposite || trackType === TypeOfTrack.SoundboardLocalRecording;
}

function IsCloudRecordingTrackType(trackType) {
    return trackType === TypeOfTrack.RoomComposite || trackType === TypeOfTrack.RoomCompositeAudioOnly || trackType === TypeOfTrack.TrackComposite || trackType === TypeOfTrack.TrackAudioOnly || trackType === TypeOfTrack.TrackVideo || trackType === TypeOfTrack.SoundboardRecording;
}

function IsVideoTrackType(trackType) {
    return trackType === TypeOfTrack.TrackVideo || trackType === TypeOfTrack.LocalVideoRecording || trackType === TypeOfTrack.RoomComposite
        || trackType === TypeOfTrack.TrackComposite || trackType === TypeOfTrack.UploadedVideo || trackType === TypeOfTrack.LocalVideoRecordingComposite || trackType === TypeOfTrack.ExportedVideoEdit || trackType === TypeOfTrack.ScreenRecordingVideo
        || trackType === TypeOfTrack.ScreenRecordingComposite;
}

function IsUploadedTrackType(trackType) {
    return trackType === TypeOfTrack.UploadedAudio || trackType === TypeOfTrack.UploadedVideo;
}

export function IsExportedEdit(trackType) {
    return trackType === TypeOfTrack.ExportedAudioEdit || trackType === TypeOfTrack.ExportedVideoEdit;
}

const MergeEdits = (intervals) => {
    const tolerance = 0.1;  // Tolerance for considering endpoints equal

    const merged = [];  // Array to hold merged intervals

    if (!intervals || intervals.length === 0) {
        return merged;
    }

    // Clone the intervals array to avoid issues with immutability
    const cloneIntervals = intervals.map(interval => ({start: interval.start, end: interval.end}));

    // Sort intervals by start time
    cloneIntervals.sort((a, b) => a.start - b.start);

    cloneIntervals.forEach(current => {
        if (merged.length === 0) {
            // If merged array is empty, add the current interval
            merged.push({start: current.start, end: current.end});
        } else {
            // Get the last interval from the merged array
            const last = merged[merged.length - 1];

            // Check if the current interval overlaps or is very close to the last interval
            if (current.start <= last.end + tolerance) {
                // Extend the last interval's end if needed
                last.end = Math.max(last.end, current.end);
            } else {
                // Add the current interval as a new entry
                merged.push({start: current.start, end: current.end});
            }
        }
    });

    return merged;
}

const AreEqualTimes = (time1, time2) => {
    return Math.abs(time1 - time2) <= 0.01;
}

const DetermineTrackSetStatus = (tracks) => {
    for (const track of tracks) {
        if (track.status === "Ready") {
            return "Ready";
        }
    }
    return tracks[0].status;
}

const DetermineHighQualityDuration = (tracks) => {
    for (const track of tracks) {
        if (IsLocalRecordingTrackType(track.trackType) && track.duration !== "") {
            return formatDuration(track.duration)
        }
    }
    return "";
}

const DetermineCloudQualityDuration = (tracks) => {
    for (const track of tracks) {
        if (IsCloudRecordingTrackType(track.trackType) && track.duration !== "") {
            return formatDuration(track.duration)
        }
    }
    return "";
}

const DetermineHighQualityFileUrl = (tracks) => {
    for (const track of tracks) {
        if (IsLocalRecordingTrackType(track.trackType)) {
            return track.objectUrl;
        }
    }
    return "";
}

const DetermineTrackSetSampleRate = (tracks) => {
    for (const track of tracks) {
        if (IsCloudRecordingTrackType(track.trackType)) {
            return track.sampleRate;
        }
    }
    return tracks[0].sampleRate;
}

const DetermineTrackSetResolution = (tracks) => {
    for (const track of tracks) {
        if (IsLocalRecordingTrackType(track.trackType)) {
            return track.resolution;
        }
    }
    return tracks[0].resolution;
}

const DetermineCloudRecordingObjectUrl = (tracks) => {
    for (const track of tracks) {
        if (IsCloudRecordingTrackType(track.trackType)) {
            return track.objectUrl;
        }
    }
    return "";
}

const DetermineCloudRecordingRenditionUrl = (tracks) => {
    for (const track of tracks) {
        if (IsCloudRecordingTrackType(track.trackType)) {
            return track.renditionUrl;
        }
    }
    return "";
}

const DetermineHighQualityCreateTime = (tracks) => {
    for (const track of tracks) {
        if (IsLocalRecordingTrackType(track.trackType)) {
            return track.createTime;
        }
    }
    return tracks[0].createTime;
}

const DetermineHighQualityStatus = (tracks) => {
    for (const track of tracks) {
        if (IsLocalRecordingTrackType(track.trackType)) {
            return track.status;
        }
    }
    return "Unknown";
}

const DetermineCloudRecordingStatus = (tracks) => {
    for (const track of tracks) {
        if (IsCloudRecordingTrackType(track.trackType)) {
            return track.status;
        }
    }
    return "Unknown";
}


const DetermineCloudRecordingCreateTime = (tracks) => {
    for (const track of tracks) {
        if (IsCloudRecordingTrackType(track.trackType)) {
            return track.createTime;
        }
    }
    return tracks[0].createTime;
}

const DetermineTrackId = (tracks) => {
    for (const track of tracks) {
        if (IsCloudRecordingTrackType(track.trackType)) {
            return track.trackId;
        }
    }
    return tracks[0].trackId;
}

const DetermineHasTranscript = (tracks) => {
    for (const track of tracks) {
        if (IsCloudRecordingTrackType(track.trackType)) {
            return track.hasTranscript;
        }
    }
    return tracks[0].hasTranscript;
}

const DetermineObjectUrl = (tracks) => {
    for (const track of tracks) {
        if (IsCloudRecordingTrackType(track.trackType) && track.status === "Ready") {
            return track.objectUrl;
        }
    }
    return DetermineCloudRecordingObjectUrl(tracks);
}

export const InitiateResumableUpload = async ({signedUrl, contentType}) => {
    const response = await axios.post(signedUrl, null, {
        headers: {
            'x-goog-resumable': 'start',
            'Content-Type': contentType,
            'x-goog-meta-status': 'partial',
        },
    });

    // Correct way to get the `Location` header using axios
    const uploadSessionUrl = response.headers['location'];

    if (!uploadSessionUrl) {
        throw new Error("Failed to initiate resumable upload");
    }

    return uploadSessionUrl; // Use this URL for all subsequent PUT requests
}

export const FinalizeResumableUpload = async ({finalizeUrl, contentType}) => {
    const response = await axios.post(finalizeUrl, null, {
        headers: {
            'x-goog-resumable': 'start',
            'Content-Type': contentType,
            'x-goog-meta-status': 'complete',
        },
    });

    // Correct way to get the `Location` header using axios
    const uploadSessionUrl = response.headers['location'];

    if (!uploadSessionUrl) {
        throw new Error("Failed to initiate resumable upload");
    }

    return uploadSessionUrl;
}

export const UploadChunk = async ({blob, uploadSessionUrl, startByte, finalize = false, totalSize=0}) => {
    const chunkSize = blob.size;
    const endByte = startByte + chunkSize - 1;

    const headers = new Headers();
    headers.append("Content-Length", chunkSize);
    if (finalize) {
        headers.append("Content-Range", `bytes ${startByte}-${endByte}/${totalSize}`);
    } else {
        headers.append("Content-Range", `bytes ${startByte}-${endByte}/*`); // Use '*' when total size is unknown
    }

    const response = await axios.put(uploadSessionUrl, blob, { headers });

    if (response.status !== 200 && response.status !== 201) {
        console.log("Failed to upload chunk: ", response.statusText);
        return -1
    }

    return endByte + 1;
}

export function MergeAudioChunk(leftBuffer, rightBuffer, sampleRate, numberOfAudioChannels, cb) {
    function interleave(leftChannel, rightChannel) {
        const length = leftChannel.length + rightChannel.length;
        const result = new Float64Array(length);
        let inputIndex = 0;
        for (let index = 0; index < length;) {
            result[index++] = leftChannel[inputIndex];
            result[index++] = rightChannel[inputIndex];
            inputIndex++;
        }
        return result;
    }

    // Convert the buffer to interleaved format for stereo
    let interleaved = numberOfAudioChannels === 2 ? interleave(leftBuffer, rightBuffer) : leftBuffer;

    function writeUTFBytes(view, offset, string) {
        for (let i = 0; i < string.length; i++) {
            view.setUint8(offset + i, string.charCodeAt(i));
        }
    }

    const bufferLength = 44 + interleaved.length * 2;
    const buffer = new ArrayBuffer(bufferLength);
    const view = new DataView(buffer);

    // Write WAV headers
    writeUTFBytes(view, 0, 'RIFF');
    view.setUint32(4, 44 + interleaved.length * 2, true);
    writeUTFBytes(view, 8, 'WAVE');
    writeUTFBytes(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, numberOfAudioChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * 2, true);
    view.setUint16(32, numberOfAudioChannels * 2, true);
    view.setUint16(34, 16, true);
    writeUTFBytes(view, 36, 'data');
    view.setUint32(40, interleaved.length * 2, true);

    // Write PCM samples
    let index = 44;
    for (let i = 0; i < interleaved.length; i++) {
        view.setInt16(index, interleaved[i] * (0x7FFF), true);
        index += 2;
    }

    if (cb) {
        cb({
            buffer: buffer,
            view: view
        });
    }
}

const DetermineTranscriptUrl = (tracks) => {
    for (const track of tracks) {
        if (IsCloudRecordingTrackType(track.trackType) && track.hasTranscript && track.transcriptUrl != "") {
            return track.transcriptUrl;
        }
    }

    return tracks[0].transcriptUrl;
}



const DetermineHighQualityRecordingStartTime = (tracks) => {
    for (const track of tracks) {
        if (IsLocalRecordingTrackType(track.trackType)) {
            return track.recordingStartMs;
        }
    }
    return tracks[0].recordingStartMs;
}

const IsSoundboardRecording = (track) => {
    return track.trackType === TypeOfTrack.SoundboardRecording || track.trackType === TypeOfTrack.SoundboardLocalRecording;
}

const IsCompositeStudioRecording = (track) => {
    return track.trackType === TypeOfTrack.RoomComposite || track.trackType === TypeOfTrack.LocalVideoRecordingComposite;
}

const IsSoundboardTrackSet = (tracks) => {
    for (const track of tracks) {
        if (!IsSoundboardRecording(track)) {
            return false;
        }
    }
    return true;
}

const IsSilentTrackSet = (tracks) => {
    for (const track of tracks) {
        if (!track.isSilentAudio) {
            return false;
        }
    }
    return true;
}

const IsAllParticipantsComposite = (tracks) => {
    for (const track of tracks) {
        if (track.trackType === TypeOfTrack.LocalVideoRecordingComposite || track.trackType === TypeOfTrack.RoomComposite) {
            return true;
        }
    }
    return false;
}

const ClipSize16And9RatioId = 1;
const ClipSize9And16RatioId = 2;
const ClipSize1And1RatioId = 3;

const ClipSizeToWidthAndHeight = (clipSizeId) => {
    switch (clipSizeId) {
        case ClipSize16And9RatioId:
            return {width: 1920, height: 1080};
        case ClipSize9And16RatioId:
            return {width: 1080, height: 1920};
        case ClipSize1And1RatioId:
            return {width: 1080, height: 1080};
        default:
            return {width: 1920, height: 1080};
    }
}

const DisplayToClipSizeId = (display) => {
    const {width, height} = display;
    if (width === 1920 && height === 1080) {
        return ClipSize16And9RatioId;
    } else if (width === 1080 && height === 1920) {
        return ClipSize9And16RatioId;
    } else if (width === 1080 && height === 1080) {
        return ClipSize1And1RatioId;
    } else {
        return ClipSize16And9RatioId;
    }
}

const PrepareRenderableRecordingTracks = ({inputTracks, assetTitle}) => {

   return [];
}

export const PrepareRecordingTrackSet = ({originalTracks, assetTitle}) => {

    const originalRecordings = originalTracks.filter(track => track.trackType !== TypeOfTrack.ExportedVideoEdit && track.trackType !== TypeOfTrack.ExportedAudioEdit)

    if (originalRecordings.length === 0) {
        return [];
    }

    const tracksKeyedByTitle = new Map();
    for (const track of originalRecordings) {
        const title = track.title;
        if (!tracksKeyedByTitle.has(title)) {
            tracksKeyedByTitle.set(title, []);
        }
        tracksKeyedByTitle.get(title).push(track);
    }

    const renderableTracks = [];
    for (const [title, tracks] of tracksKeyedByTitle) {

        const highQualityFileUrl = DetermineHighQualityFileUrl(tracks);
        const cloudRecordingFileUrl = DetermineCloudRecordingObjectUrl(tracks);

        const highQualityCreateTime = DetermineHighQualityCreateTime(tracks);
        const cloudRecordingCreateTime = DetermineCloudRecordingCreateTime(tracks);

        const highQualityDuration = DetermineHighQualityDuration(tracks);
        const cloudQualityDuration = DetermineCloudQualityDuration(tracks);

        const duration = cloudQualityDuration ? cloudQualityDuration : highQualityDuration;

        if (IsSoundboardTrackSet(tracks) && IsSilentTrackSet(tracks)) {
            continue;
        }

        renderableTracks.push({
            title: title,
            assetTitle: assetTitle,
            trackIds: tracks.map(track => track.trackId),
            thumbnailUrl: tracks.map(track => track.thumbnailUrl)[0],
            trackId: DetermineTrackId(tracks),
            status: DetermineTrackSetStatus(tracks),
            mediaType: IsVideoTrackType(tracks[0].trackType) ? "Video" : "Audio",
            recordingStartMs: DetermineHighQualityRecordingStartTime(tracks),
            duration: duration,
            highQualityDuration: highQualityDuration,
            cloudQualityDuration: cloudQualityDuration,
            highQualityFileUrl: highQualityFileUrl,
            highQualityStatus: DetermineHighQualityStatus(tracks),
            cloudRecordingFileUrl: cloudRecordingFileUrl,
            cloudRecordingStatus: DetermineCloudRecordingStatus(tracks),
            highQualityCreateTime: highQualityCreateTime,
            cloudRecordingCreateTime: cloudRecordingCreateTime,
            // Prefer the cloud recording track for display on the asset page, since high quality recording is
            // still a little unreliable. (Except the resolution shown.)
            objectUrl: DetermineCloudRecordingObjectUrl(tracks),
            renditionUrl: DetermineCloudRecordingRenditionUrl(tracks),
            createTime: cloudRecordingCreateTime ? cloudRecordingCreateTime : highQualityCreateTime,
            sampleRate: DetermineTrackSetSampleRate(tracks),
            resolution: DetermineTrackSetResolution(tracks),
            transcriptUrl: DetermineTranscriptUrl(tracks),
            isVideoComposite: IsAllParticipantsComposite(tracks),
            isSoundboard: IsSoundboardTrackSet(tracks),
        });
    }

    return renderableTracks;
}

const StandardVideoResolution = 0;
const HighVideoResolution = 1;

const GridVideoLayout = "grid";
const SpeakerVideoLayout = "speaker";
const SingleSpeakerVideoLayout = "single-speaker";

const LibrettoPlan = {
    Free: 0,
    Unused: 1,
    StandardMonthly: 2,
    StandardAnnual: 3,
    CreatorMonthly: 4,
    CreatorAnnual: 5,
    AppSumoTier1: 6,
    AppSumoTier2: 7,
    Starter: 8,  // Deprecated
    StarterPaidMonthly: 10,
    StarterPaidAnnual: 11,
}

const LibrettoRecordingMode = {
    Video: 0,
    Audio: 1,
}

const LibrettoStudioTheme = {
    Light: 0,
    Libretto: 1,
    Dark: 2,
}

const LibrettoStudioNameStyle = {
    Clean: 0,
    Bold: 1,
    Edgy: 2,
    Classic: 3,
    Fun: 4,
}

const StudioNameStyleString = (nameStyle) => {
    if (nameStyle === LibrettoStudioNameStyle.Clean) {
        return "clean";
    } else if (nameStyle === LibrettoStudioNameStyle.Bold) {
        return "bold";
    } else if (nameStyle === LibrettoStudioNameStyle.Edgy) {
        return "edgy";
    } else if (nameStyle === LibrettoStudioNameStyle.Classic) {
        return "classic";
    } else {
        return "fun";
    }
}

const RecordingModeString = (recordingMode) => {
    if (recordingMode === LibrettoRecordingMode.Video) {
        return "Video";
    } else {
        return "Audio";
    }
}

const OppositeRecordingMode = (mode) => {
    if (mode === LibrettoRecordingMode.Video) {
        return LibrettoRecordingMode.Audio;
    } else {
        return LibrettoRecordingMode.Video;
    }
}

const AdaptRecordingMode = (mode) => {
    if (mode === LibrettoRecordingMode.Video) {
        return LibrettoRecordingMode.Video;
    } else {
        return LibrettoRecordingMode.Audio;
    }
}

// For screen recording. Determines which one of these, besides a screen, is being recorded.
// ["Audio", "Video", "Audio+Video", "None"]
export const SecondaryRecordingStreamType = ({webcamStream, micStream}) => {
    if (webcamStream && micStream) {
        return "Audio+Video";
    } else if (webcamStream) {
        return "Video";
    } else if (micStream) {
        return "Audio";
    } else {
        return "None";
    }
}

const KAppSumoCodeLength = 17;

// Brand logo images used in studio for recording
export const BRAND_LOGO = "BrandLogo";
// Images uploaded for use in editing. (Used as a string for uploads).
export const IMAGE_UPLOAD = "ImageUpload";

export {
    Float32ToInt16Array,
    AreEqualTimes,
    IsUploadedTrackType,
    MergeEdits,
    TrackTypeToString,
    LibrettoPlan,
    IsVideoAsset,
    LibrettoRecordingMode,
    LibrettoStudioTheme,
    LibrettoStudioNameStyle,
    StudioNameStyleString,
    RecordingModeString,
    OppositeRecordingMode,
    AdaptRecordingMode,
    PrepareRenderableRecordingTracks,
    IsVideoTrackType,
    SerializeAudioChunks,
    IsUploadedAssetType,
    Int16ToUint8Array,
    formatDuration,
    formatSampleRate,
    formatUnixTime,
    formatToReadableTitle,
    IsLocalRecordingTrackType,
    IsCloudRecordingTrackType,
    formatBitrate,
    formatToReadableLargeTitle,
    formatUnixTimeBrief,
    formatUnixTimeForTitle,
    formatToReadableShortTitle,
    getFileTypeFromMime,
    UploadToLibretto,
    UploadSerializedChunks,
    CombineAudioChunks,
    formatToReadableSourceAndType,
    determineThumbnailUrl,
    handleDownloadButtonClick,
    compareTracksForSort,
    SendEndOfStreamMarker,
    StandardVideoResolution,
    HighVideoResolution,
    ClipSize16And9RatioId,
    ClipSize9And16RatioId,
    ClipSize1And1RatioId,
    GridVideoLayout,
    SpeakerVideoLayout,
    SerializeVideoChunks,
    SingleSpeakerVideoLayout,
    MergeTrims,
    formatDurationForEditor,
    determineTrackThumbnailUrl,
    KAppSumoCodeLength,
    ClipSizeToWidthAndHeight,
    DisplayToClipSizeId,
    RefreshTokenAndRetry,
};
