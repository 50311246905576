import {useMutation} from "@liveblocks/react/suspense";

export const useCreateSetDisplayResolution = () => {
    return useMutation(({storage}, width, height) => {
        storage?.get("display")?.set("width", String(width));
        storage?.get("display")?.set("height", String(height));
    }, []);
}

export const useCreateSetDisplayBackground = () => {
    return useMutation(({storage}, backgroundColor) => {
        storage?.get("display")?.set("backgroundColor", backgroundColor);
    }, []);
}

export const useCreateAddMediaToLibrary = () => {
    return useMutation(({storage}, media) => {
        // Check if corrections has an entry for this entity, if not add it. Some media (like stock media) don't need corrections
        // and will not have an entity id.
        if (media.entityId && !storage.get("corrections").has(media.entityId)) {
            storage.get("corrections").set(media.entityId, {mediaDataId: media.entityId, corrections: new Map()});
        }
    }, []);
}

export const useCreateSaveEditorState = () => {
    return useMutation(({storage}, editorState) => {
        editorState?.library?.media.forEach((media) => {
            if (media.customData) {
                media.customData = media.customData.filter((data) => data[0] !== "transcript");
            }
        });
        storage.set("state", editorState);
    }, []);
}

export const useCreateSaveTranscriptCorrection = () => {
    return useMutation(({storage}, entityId, indexOnTranscript, correctedText, setLocalCorrectionsState) => {

        const clipCorrections = storage.get("corrections").get(String(entityId))

        const newCorrectionsForClip = clipCorrections.corrections;
        newCorrectionsForClip[String(indexOnTranscript)] = {correctedText: correctedText, index: indexOnTranscript};
        clipCorrections.corrections = newCorrectionsForClip;

        storage.get("corrections").set(String(entityId), clipCorrections);

        // Assume local corrections is a map of entityId to corrections
        setLocalCorrectionsState((prev) => {
            const newLocalCorrections = new Map(prev);
            newLocalCorrections.set(entityId, clipCorrections);
            return newLocalCorrections;
        });

    }, []);
}