import React, {CSSProperties} from 'react';
import './TrackContextMenu.styles.scss';
import {Card, ClickAwayListener} from "@mui/material";
import {Engine} from "@rendley/sdk";
import Box from "@mui/material/Box";
import {DeleteIcon, RenameIcon} from "../../../../../pages/NewHome/HomeIcons";
import {BiLeftArrow} from "react-icons/bi";
import {ArrowBack, ContentCopy, East, FirstPage, LastPage, West} from "@mui/icons-material";
import {RendleyService} from "../../../../services/RendleyService";
import {RendleyStore} from "../../../../store/RendleyStore";

const TrackContextMenu = ({closeCard, left, top, selectedClipId}) => {

    const clip = Engine.getInstance().getClipById(selectedClipId);

    if (!clip) {
        closeCard();
        return;
    }

    // Where does the clip start on the timeline.
    const clipPosition = clip ? clip.getLeftTrim() + clip.getStartTime() : 0;
    const clipDuration = clip ? clip.getDuration() - clip.getRightTrim() - clip.getLeftTrim() : 0;

    const handleDuplicateClip = () => {

    }

    const handleDeleteClip = () => {
        RendleyService.deleteClipById(selectedClipId);
        closeCard();
    }

    const handleExtendRight = () => {
        RendleyService.extendClipRight(selectedClipId);
        closeCard();
    }

    const handleExtendLeft = () => {
        RendleyService.extendClipLeft(selectedClipId);
        closeCard();
    }

    const handleAlignStartToPlayhead = () => {
        const playheadTime = Engine.getInstance().getTimeline().currentTime;
        Engine.getInstance().getClipById(selectedClipId).setStartTime(playheadTime - clip.getLeftTrim());
        RendleyStore.clips[selectedClipId].startTime = playheadTime;
        closeCard();
    }

    const handleAlignEndToPlayhead = () => {
        const playheadTime = Engine.getInstance().getTimeline().currentTime;
        Engine.getInstance().getClipById(selectedClipId).setStartTime(playheadTime - clipDuration - clip.getLeftTrim());
        RendleyStore.clips[selectedClipId].startTime = playheadTime - clipDuration - clip.getLeftTrim();
        closeCard();
    }

    const extendDisabled = clip && clip.getType() !== "text" && clip.getType() !== "image";
    const alignEndToPlayheadDisabled = clip && clipDuration > Engine.getInstance().getTimeline().currentTime;

    const boxStyle: CSSProperties = {
        position: "absolute",
        top: `${top}px`,
        left: `${left}px`,
        display: "inline-flex",
        padding: "8px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "4px",
        borderRadius: "8px",
        background: "#EEEEEE",
        boxShadow: "10px 10px 20px 0px rgba(0, 0, 0, 0.15)",
        zIndex: 1000,
    }

    const itemStyle = (disabled) => {
        return ({
            display: "flex",
            padding: "8px",
            alignItems: "center",
            gap: "8px",
            alignSelf: "stretch",
            cursor: disabled ? "not-allowed" : "pointer",
            borderRadius: "8px",
            // On hover
            '&:hover': {
                background: disabled ? "#EEEEEE" : "#E3F4F5",
            },
        })
    }

    const itemTextStyle = (disabled) => {
        return ({
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: disabled ? 300 : 500,
            color: disabled ? "#B0B0B0" : "#000000",
            lineHeight: "20px",
            letterSpacing: "0.14px",
        })
    }

    return (
        <ClickAwayListener onClickAway={closeCard}>
            <Card style={boxStyle}>
                <Box sx={itemStyle(false)} onClick={handleAlignStartToPlayhead}>
                    <Box style={{display: "flex", height: "20px", width: "20px"}}>
                        <FirstPage/>
                    </Box>
                    <Box style={itemTextStyle(false)}>
                        Align start to playhead
                    </Box>
                </Box>
                <Box sx={itemStyle(alignEndToPlayheadDisabled)} onClick={handleAlignEndToPlayhead}>
                    <Box style={{display: "flex", height: "20px", width: "20px", color: alignEndToPlayheadDisabled ? "#B0B0B0" : "#000000"}}>
                        <LastPage/>
                    </Box>
                    <Box style={itemTextStyle(alignEndToPlayheadDisabled)}>
                        Align end to playhead
                    </Box>
                </Box>
                <Box sx={itemStyle(extendDisabled)} onClick={handleExtendLeft}>
                    <Box style={{display: "flex", height: "20px", width: "20px",  color: extendDisabled ? "#B0B0B0" : "#000000"}}>
                        <West/>
                    </Box>
                    <Box style={itemTextStyle(extendDisabled)}>
                        Extend left
                    </Box>
                </Box>
                <Box sx={itemStyle(extendDisabled)} onClick={handleExtendRight}>
                    <Box style={{display: "flex", height: "20px", width: "20px", color: extendDisabled ? "#B0B0B0" : "#000000"}}>
                        <East/>
                    </Box>
                    <Box style={itemTextStyle(extendDisabled)}>
                        Extend right
                    </Box>
                </Box>
                {/*<Box sx={itemStyle(false)} onClick={handleDeleteClip}>*/}
                {/*    <Box style={{display: "flex", height: "20px", width: "20px"}}>*/}
                {/*        <ContentCopy/>*/}
                {/*    </Box>*/}
                {/*    <Box style={itemTextStyle(false)}>*/}
                {/*        Duplicate clip*/}
                {/*    </Box>*/}
                {/*</Box>*/}
                <Box sx={itemStyle(false)} onClick={handleDeleteClip}>
                    <Box style={{display: "flex", height: "20px", width: "20px"}}>
                        <DeleteIcon/>
                    </Box>
                    <Box style={itemTextStyle(false)}>
                        Delete clip
                    </Box>
                </Box>
            </Card>
        </ClickAwayListener>
    );
};

export default TrackContextMenu;