/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import KeyboardAltIcon from '@mui/icons-material/KeyboardAlt';
import {FolderShared} from "@mui/icons-material";

const modernInfo = [
    {
        title: 'Studio-quality recording made easy',
        subtitle:
            'Achieving high audio and video recording quality shouldn’t require a studio or a flawless internet connection.',
        icon: (
            <VideoCameraFrontIcon/>
        ),
    },
    {
        title: 'Editing as simple as editing text',
        subtitle:
            "Editing shouldn't be so daunting, and making basic cuts and edits shouldn't require learning complex software.",
        icon: (
            <KeyboardAltIcon/>
        ),
    },
    {
        title: 'Share recordings in one click',
        subtitle:
            "After recording and editing, sharing files with collaborators shouldn't require multiple steps or third-party tools.",
        icon: (
            <FolderShared/>
        ),
    },
];

const mock = [
    {
        title: 'High-quality remote recording',
        subtitle:
            'Record high-definition audio and video with remote participants. Get studio-quality recordings without a professional setup.',
        icon: (
            <VideoCameraFrontIcon/>
        ),
    },
    {
        title: 'Edit video by editing text',
        subtitle:
            'Libretto transcribes your recordings and uploads, letting you edit it the way you edit a doc. No need to scrub through audio or video.',
        icon: (
            <KeyboardAltIcon/>
        ),
    },
    {
        title: 'One-click project sharing',
        subtitle:
            'Share assets with collaborators in a single click. Give others access to download, or even contribute to your projects effortlessly.',
        icon: (
            <FolderShared/>
        ),
    },
];

export const ModernServices = () => {
    const theme = useTheme();
    return (
        <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
            <Box marginBottom={3} maxWidth={{ xs: "95%", sm: 720, md: 1236 }}>
                <Box marginBottom={2}>
                    <Typography
                        variant="h3"
                        color="text.primary"
                        align={'center'}
                        gutterBottom
                        sx={{
                            fontWeight: 700,
                        }}
                    >
                        Podcasting should be simple
                    </Typography>
                    <Box
                        sx={{
                            fontSize: { xs: "21px", sm: "23px", md: "24px" }, // Smaller font for mobile
                            marginTop: "15px",
                            marginBottom: { xs: "-10px"},
                            fontWeight: "500",
                            color: "#6f6e77",
                            textAlign: "center", // Center align the text
                            paddingX: { xs: "20px", sm: "0" } // Padding on mobile for better spacing
                        }}
                    >
                        And it should not require so many tools.
                    </Box>
                </Box>
            </Box>
            <Grid container maxWidth={{ xs: "95%", sm: 720, md: 1236 }} spacing={2}>
                {modernInfo.map((item, i) => (
                    <Grid item xs={12} md={4} key={i}>
                        <Box width={1} height={1}>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'center'}
                            >
                                <Box
                                    component={Avatar}
                                    width={60}
                                    height={60}
                                    marginBottom={2}
                                    bgcolor={alpha(theme.palette.primary.main, 0.1)}
                                    color={"#2B6BFD"}
                                >
                                    {item.icon}
                                </Box>
                                <Typography
                                    variant={'h6'}
                                    gutterBottom
                                    sx={{ fontWeight: 550 }}
                                    align={'center'}
                                >
                                    {item.title}
                                </Typography>
                                <Typography align={'center'} color="text.secondary" fontFamily={"Inter"} fontSize={"1.1rem"} fontWeight={400}>
                                    {item.subtitle}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export const NewServices = () => {
    const theme = useTheme();
    return (
        <Box>
            <Box marginBottom={4}>
                <Box marginBottom={2}>
                    <Typography
                        variant="h3"
                        color="text.primary"
                        align={'center'}
                        gutterBottom
                        sx={{
                            fontWeight: 700,
                        }}
                    >
                        Studio-quality recording + editing
                    </Typography>
                    <Typography
                        variant="h6"
                        component="p"
                        fontFamily={"Inter"}
                        color="text.secondary"
                        sx={{ fontWeight: 400 }}
                        align={'center'}
                    >
                        Record high-quality audio & video, edit with an intelligent editor and share content in one click.
                    </Typography>
                </Box>
            </Box>
            <Grid container spacing={2}>
                {mock.map((item, i) => (
                    <Grid item xs={12} md={4} key={i}>
                        <Box width={1} height={1}>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'center'}
                            >
                                <Box
                                    component={Avatar}
                                    width={60}
                                    height={60}
                                    marginBottom={2}
                                    bgcolor={alpha(theme.palette.primary.main, 0.1)}
                                    color={"#2B6BFD"}
                                >
                                    {item.icon}
                                </Box>
                                <Typography
                                    variant={'h6'}
                                    gutterBottom
                                    sx={{ fontWeight: 500 }}
                                    align={'center'}
                                >
                                    {item.title}
                                </Typography>
                                <Typography align={'center'} color="text.secondary" fontFamily={"Inter"} fontSize={"1.1rem"} fontWeight={500}>
                                    {item.subtitle}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

const Services = () => {
    const theme = useTheme();
    return (
        <Box>
            <Box marginBottom={4}>
                <Box marginBottom={2}>
                    <Typography
                        variant="h3"
                        color="text.primary"
                        align={'center'}
                        gutterBottom
                        sx={{
                            fontWeight: 700,
                        }}
                    >
                        Podcasting made
                        {' '}<Typography
                            color={'primary'}
                            component={'span'}
                            variant={'inherit'}
                        >
                            simple
                        </Typography>
                    </Typography>
                    <Typography
                        variant="h6"
                        component="p"
                        color="text.secondary"
                        sx={{ fontWeight: 600 }}
                        align={'center'}
                    >
                        Record high-quality audio & video, and edit with a collaborative editor and automated audio post production.
                    </Typography>
                </Box>
            </Box>
            <Grid container spacing={2}>
                {mock.map((item, i) => (
                    <Grid item xs={12} md={4} key={i}>
                        <Box width={1} height={1}>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'center'}
                            >
                                <Box
                                    component={Avatar}
                                    width={60}
                                    height={60}
                                    marginBottom={2}
                                    bgcolor={alpha(theme.palette.primary.main, 0.1)}
                                    color={theme.palette.primary.main}
                                >
                                    {item.icon}
                                </Box>
                                <Typography
                                    variant={'h6'}
                                    gutterBottom
                                    sx={{ fontWeight: 500 }}
                                    align={'center'}
                                >
                                    {item.title}
                                </Typography>
                                <Typography align={'center'} color="text.secondary" fontSize={"1.1rem"} fontWeight={500}>
                                    {item.subtitle}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Services;
