import {alpha, useTheme} from "@mui/material/styles";
import React, {useContext, useState} from "react";
import {AuthContext} from "../../context/AuthContext";
import Box from "@mui/material/Box";
import {NewMain} from "../Landing/Components/Main";
import {ModernHero, NewHero} from "../Landing/Components/Hero";
import Container, {NavbarContainer} from "../../components/Container";
import {ModernServices, NewServices} from "../Landing/Components/Services";
import {NewStudioQualityRecording} from "../Landing/Components/QuickStart";
import {GuideSectionOnLandingPage, NewVideoLayoutFeature} from "../Landing/Components/VideoLayoutFeature";
import {NewLiveSoundboardFeature} from "../Landing/Components/LiveSoundboardFeature";
import {NewEditLikeADocFeature} from "../Landing/Components/EditLikeADocFeature";
import CombineTracksFeature from "../Landing/Components/CombineTracksFeature";
import TrimTracksFeature from "../Landing/Components/TrimTracksFeature";
import {NewAudioEnhanceFeature} from "../Landing/Components/AudioEnhanceFeature";
import ShareRecordingsFeature from "../Landing/Components/ShareRecordingsFeature";
import TranscriptionAndCaptioningFeature from "../Landing/Components/TranscriptionAndCaptioningFeature";
import {NewBenefits} from "../Landing/Components/Benefits";
import {
    CreatorPricingCard,
    FreePricingCard,
    StandardPricingCard,
} from "../Pricing/Pricing";
import {NewGetStarted} from "../Landing/Components/GetStarted";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ModernOneToolSection, {ModernEffortlessEditingSection} from "../Landing/Components/ModernOneToolSection";
import {
    ApplePodcastsIcon,
    InstagramIcon,
    LinkedInIcon, ShortsIcon,
    SpotifyIcon,
    TikTokIcon,
    XIcon,
    YouTubeIcon
} from "../../assets/SvgLogos";
import useMediaQuery from "@mui/material/useMediaQuery";
import {UnauthenticatedUserIntercomComponent} from "../../components/IntercomComponent";

const AnnualPlan = "Annual";
const MonthlyPlan = "Monthly";

const TestimonialSection = () => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const width = isSmallScreen ? "100%" : "60%";
    const marginTop = isSmallScreen ? "0px" : "-30px";

    return (
        <Box sx={{
            height: "570px",
            marginTop: marginTop,
            borderRadius: "20px",
            maxWidth: "1200px",
            width: "100%",
            border: "1px solid #eaeaea",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            padding: "20px",
            paddingLeft: "30px",
            paddingRight: "30px",
            backgroundColor: "#edf1f7",
        }}>
            <Box sx={{fontSize: "1.1rem", width: width}}>
                "We've been using Libretto for our podcast recording and editing, and it’s been an absolute game-changer. What used to take hours of coordinating and editing across tools is now a single process. Recordings sound perfect, and we’re
                creating episodes in record time."
            </Box>
        </Box>
    );
}

export const ModernLandingPage = () => {
    const theme = useTheme();

    const authContext = useContext(AuthContext);

    const [selectedPricingOption, setSelectedPricingOption] = useState(AnnualPlan);

    let LogoIcons = [<YouTubeIcon/>, <SpotifyIcon/>, <ApplePodcastsIcon/>, <LinkedInIcon/>, <InstagramIcon/>, <XIcon/>,
        <TikTokIcon/>, <ShortsIcon/>];

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    LogoIcons = isSmallScreen ? LogoIcons.slice(0, 2) : LogoIcons;

    return (
        <>
            <Box sx={{overflowX: 'hidden'}}>
                <UnauthenticatedUserIntercomComponent/>
                <NewMain bgcolor={'background.paper'} selectedPricingOption={selectedPricingOption} pageEnum={0}>
                    <ModernHero/>
                    <ModernServices/>
                    <Box
                        sx={{
                            backgroundImage: `linear-gradient(to bottom, ${alpha(
                                theme.palette.background.paper,
                                0,
                            )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
                            backgroundRepeat: 'repeat-x',
                            position: 'relative',
                            marginTop: theme.spacing(3),
                        }}
                    >
                        <Box
                            component={'svg'}
                            preserveAspectRatio="none"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            viewBox="0 0 1920 100.1"
                            sx={{
                                width: '100%',
                                marginBottom: theme.spacing(-3),
                            }}
                        >
                            <path
                                fill={theme.palette.background.paper}
                                d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                            ></path>
                        </Box>
                    </Box>
                    {/*<Container>*/}
                    {/*    <TestimonialSection/>*/}
                    {/*</Container>*/}
                    <GuideSectionOnLandingPage/>
                    <NavbarContainer>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginBottom: "40px",
                            marginTop: "40px"
                        }}>
                            <Box>
                                <Typography
                                    variant="h2"
                                    color="text.primary"
                                    align={'center'}
                                    gutterBottom
                                    sx={{
                                        fontWeight: 700,
                                    }}
                                >
                                    Create every kind of content
                                </Typography>
                                <Box
                                    sx={{
                                        fontSize: { xs: "21px", sm: "23px", md: "24px" }, // Smaller font for mobile
                                        marginTop: "15px",
                                        marginBottom: { xs: "-10px"},
                                        fontWeight: "500",
                                        color: "#6f6e77",
                                        textAlign: "center", // Center align the text
                                        paddingX: { xs: "20px", sm: "0" } // Padding on mobile for better spacing
                                    }}
                                >
                                    From podcasts to marketing videos, record with ease.
                                </Box>
                            </Box>
                        </Box>
                        <ModernOneToolSection/>
                    </NavbarContainer>
                    <NavbarContainer sx={{marginTop: "-30px"}}>
                        <Box
                            sx={{display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "40px"}}>
                            <Box>
                                <Typography
                                    variant="h2"
                                    color="text.primary"
                                    align={'center'}
                                    gutterBottom
                                    sx={{
                                        fontWeight: 700,
                                    }}
                                >
                                    Edit effortlessly
                                </Typography>
                                <Box
                                    sx={{
                                        fontSize: { xs: "21px", sm: "23px", md: "24px" }, // Smaller font for mobile
                                        marginTop: "15px",
                                        marginBottom: { xs: "-10px"},
                                        fontWeight: "500",
                                        color: "#6f6e77",
                                        textAlign: "center", // Center align the text
                                        paddingX: { xs: "20px", sm: "0" } // Padding on mobile for better spacing
                                    }}
                                >
                                    With an intelligent editor that saves you time.
                                </Box>
                            </Box>
                        </Box>
                        <ModernEffortlessEditingSection/>
                    </NavbarContainer>
                    <NavbarContainer sx={{marginTop: "-30px"}}>
                        <Box
                            sx={{display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "40px"}}>
                            <Box>
                                <Typography
                                    variant="h2"
                                    color="text.primary"
                                    align={'center'}
                                    gutterBottom
                                    sx={{
                                        fontWeight: 600,
                                    }}
                                >
                                    Export for any platform
                                </Typography>
                                <Box
                                    sx={{
                                        fontSize: { xs: "21px", sm: "23px", md: "24px" }, // Smaller font for mobile
                                        marginTop: "15px",
                                        marginBottom: { xs: "-10px"},
                                        fontWeight: "500",
                                        color: "#6f6e77",
                                        textAlign: "center", // Center align the text
                                        paddingX: { xs: "20px", sm: "0" } // Padding on mobile for better spacing
                                    }}
                                >
                                    Vertical and square videos. Raw audio files. We've got you covered.
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{
                            backgroundColor: "#edf1f7",
                            height: "400px",
                            width: "100%",
                            marginTop: "50px",
                            marginBottom: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <Box sx={{
                                height: "180px",
                                backgroundColor: "#ffffff",
                                width: "80%",
                                borderRadius: "20px",
                                gap: "20px",
                                display: "flex",        // This makes the inner box a flex container
                                flexDirection: "row",   // Aligns items (icons) in a row
                                justifyContent: "center", // Distributes space evenly around the icons
                                alignItems: "center",   // Vertically center the icons
                                padding: "20px"         // Optional padding to give the icons some space
                            }}>
                                {LogoIcons.map((Icon, index) => (
                                    <Box key={index} sx={{marginX: "10px"}}>
                                        {Icon}
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </NavbarContainer>
                    <Container sx={{marginTop: "-30px"}}>
                        <Box sx={{display: "flex", flexDirection: "column"}}>
                            <Box>
                                <Typography
                                    variant="h2"
                                    color="text.primary"
                                    gutterBottom
                                    align={'center'}
                                    sx={{
                                        fontWeight: 600,
                                    }}
                                >
                                    Choose the best plan for you
                                </Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'strech',
                                gap: 7,
                                justifyContent: 'center',
                                marginTop: '50px',
                                flexDirection: {xs: 'column', sm: 'row'}
                            }} marginBottom={8}>
                                <FreePricingCard planType={AnnualPlan}
                                                 handleSignupFormOpen={authContext.handleSignupFormOpen}/>
                                <StandardPricingCard planType={AnnualPlan}
                                                     handleSignupFormOpen={authContext.handleSignupFormOpen}/>
                                <CreatorPricingCard planType={AnnualPlan}
                                                    handleSignupFormOpen={authContext.handleSignupFormOpen}/>
                            </Box>
                        </Box>
                    </Container>
                </NewMain>
            </Box>
        </>
    );
};

const NewLandingPage = () => {
    const theme = useTheme();

    const authContext = useContext(AuthContext);

    const [selectedPricingOption, setSelectedPricingOption] = useState(AnnualPlan);

    return (
        <>
            <Box sx={{overflowX: 'hidden'}}>
                <NewMain bgcolor={'background.paper'} selectedPricingOption={selectedPricingOption} pageEnum={0}>
                    <NewHero/>
                    <Container>
                        <NewServices/>
                    </Container>
                    <Box
                        sx={{
                            backgroundImage: `linear-gradient(to bottom, ${alpha(
                                theme.palette.background.paper,
                                0,
                            )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
                            backgroundRepeat: 'repeat-x',
                            position: 'relative',
                        }}
                    >
                        <Container>
                            <NewStudioQualityRecording/>
                        </Container>
                        <Container>
                            <NewVideoLayoutFeature/>
                        </Container>
                        <Container>
                            <NewLiveSoundboardFeature/>
                        </Container>
                        <Container>
                            <NewEditLikeADocFeature/>
                        </Container>
                        {/*<Container>*/}
                        {/*    <CombineTracksFeature/>*/}
                        {/*</Container>*/}
                        {/*<Container>*/}
                        {/*    <TrimTracksFeature/>*/}
                        {/*</Container>*/}
                        <Container>
                            <NewAudioEnhanceFeature/>
                        </Container>
                        <Container>
                            <ShareRecordingsFeature/>
                        </Container>
                        {/*<Container>*/}
                        {/*    <TranscriptionAndCaptioningFeature/>*/}
                        {/*</Container>*/}
                        <Box
                            component={'svg'}
                            preserveAspectRatio="none"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            viewBox="0 0 1920 100.1"
                            sx={{
                                width: '100%',
                                marginBottom: theme.spacing(-3),
                            }}
                        >
                            <path
                                fill={theme.palette.background.paper}
                                d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                            ></path>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            backgroundImage: `linear-gradient(to bottom, ${alpha(
                                theme.palette.background.paper,
                                0,
                            )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
                            backgroundRepeat: 'repeat-x',
                            position: 'relative',
                        }}
                    >
                        <Container>
                            <NewBenefits/>
                        </Container>
                        <Box
                            component={'svg'}
                            preserveAspectRatio="none"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            viewBox="0 0 1920 100.1"
                            sx={{
                                width: '100%',
                                marginBottom: theme.spacing(-2),
                            }}
                        >
                            <path
                                fill={theme.palette.background.paper}
                                d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                            ></path>
                        </Box>
                    </Box>
                    <Container>
                        <NewGetStarted handleSignupFormOpen={authContext.handleSignupFormOpen}/>
                    </Container>
                </NewMain>
            </Box>
        </>
    );
};

export default NewLandingPage;
