import {AudioClip, ClipOptions, ClipSchema, ClipTypeEnum, CustomClip, Engine} from "@rendley/sdk";
import { Zod, Pixi } from "@rendley/sdk";

// This is the library to computer the frequency domain for our audio sample
// From https://github.com/indutny/fft.js/
import FFT from "./utils/fft/fft";

// Optional we can have additional options we can read on initialization
export interface WaveformClipOptions extends ClipOptions {
    clipId?: string;
}

// This is the parsing schema used to validate the data on serialization/deserialization
const WaveformClipSchema = ClipSchema.extend({
    clipId: Zod.string().optional(),
});

type WaveformClipSerialized = ReturnType<CustomClip["serialize"]> & {
    clipId?: string;
}

const FFT_WINDOW_SIZE = 4096; // Choose an FFT size that matches your input length

// CustomClip is has the bare minimum to interact with the sdk
// You can overwrite things like: hasSprite() (is it rendable?), init(layerId), update(currentTime), serialize()/static deserialize(payload), ...
class WaveformClip extends CustomClip {
    // Holds the reference (by Id) to our target audio clip
    clipId?: string;
    // Our canvas
    graphics: Pixi.Graphics;
    // Holds the full audioBuffer of our target clip
    private audioBuffer: Float32Array | null = null;

    // We use another flag hasAudio instead of null checks because of async audio extraction, we need to avoid multiple extractions and also stop searching for clips
    private hasAudio = false;

    // This will contain our frequency data
    private spectrum: Float32Array;
    // This will contain the temporary buffer for our window
    private audioWindow: Float32Array;
    private fft: FFT;
    // This is the output from FFT, contains complex (real/imaginary) data
    private out: any[];

    // We take any options we need here
    constructor(options: WaveformClipOptions) {
        super(options);

        this.clipId = options.clipId;

        // ---------------- Spectrum stuff

        // Initialize the FFT
        this.fft = new FFT(FFT_WINDOW_SIZE);
        // Allocate buffer
        // @ts-ignore
        this.out = this.fft.createComplexArray();

        // Prepare the input data
        this.audioWindow = new Float32Array(FFT_WINDOW_SIZE);

        // Allocate final spectrum data
        this.spectrum = new Float32Array(FFT_WINDOW_SIZE / 2);

        // ---------------- End Spectrum stuff

        // We use the main sprite as a container for our Graphics
        this.sprite = new Pixi.Sprite();

        // The main Graphics we'll draw on
        this.graphics = new Pixi.Graphics();

        this.sprite.addChild(this.graphics);
    }

    // We'll do the heavy load and any dependencies here
    override async init(layerId: string) {
        // This will will add our clip to the layer and init the rest
        await super.init(layerId);

        if (this.clipId) {
            console.log("Clipid during init", this.clipId);
            const audioClip = Engine.getInstance().getClipById(this.clipId) as AudioClip;
            if (audioClip) {
                this.hasAudio = true; // Stop any searching
                audioClip.extractMonoAudioData(0, audioClip.getDuration()).then((audioBuffer) => {
                    this.audioBuffer = audioBuffer;
                });
            }
        }
    }

    // This is a smoothing function for our sample window for better (smoother) FFT results
    applyHanningWindow(buffer: Float32Array) {
        const N = buffer.length;
        for (let n = 0; n < N; n++) {
            const hanningValue = 0.5 * (1 - Math.cos((2 * Math.PI * n) / (N - 1)));
            buffer[n] *= hanningValue;
        }
    }

    // Helper function
    clamp(value: number, min: number, max: number) {
        return Math.min(Math.max(value, min), max);
    }

    // This is main update function, it is always called from the engine, it's clip responsability to optimize it's data update. (like check if currentTime changed, or clip properties changed).
    override update(currentTime: number) {
        super.update(currentTime);

        if (this.clipId && !this.hasAudio) {
            const clip = Engine.getInstance().getTimeline().getClipById(this.clipId) as AudioClip;
            if (clip) {
                this.hasAudio = true;
                clip.extractMonoAudioData(0, clip.getDuration()).then((audioBuffer) => {
                    this.audioBuffer = audioBuffer;
                    this.clipId = clip.id;
                });
            }
        } else if (!this.audioBuffer) {
            let audioClip: AudioClip;

            Engine.getInstance()
                .getTimeline()
                .getClips()
                .forEach((clip) => {
                    if (clip.getType() == ClipTypeEnum.AUDIO) {
                        audioClip = clip as AudioClip;
                    }
                });

            if (audioClip! && !this.hasAudio) {
                this.hasAudio = true;
                audioClip.extractMonoAudioData(0, audioClip.getDuration()).then((audioBuffer) => {
                    this.audioBuffer = audioBuffer;
                    this.clipId = audioClip.id;
                });
            }
        } else if (this.clipId) {
            const refClip = Engine.getInstance().getClipById(this.clipId);
            if (!refClip) return; // Early return

            // Get the time relative to clip and convert it to samples (44100 Hz returns the clip extractMonoAudioData)
            let diff = Math.max(0, Math.min(currentTime - refClip.getStartTime(), refClip.getDuration()));
            diff = Math.floor(diff * 44100);
            // Make sure we don't overflow the buffer
            const sampleStartPos = Math.min(this.audioBuffer.length - FFT_WINDOW_SIZE - 1, diff);

            this.audioWindow.set(this.audioBuffer.subarray(sampleStartPos, sampleStartPos + FFT_WINDOW_SIZE), 0);

            // Do the smoothing for the input samples
            this.applyHanningWindow(this.audioWindow);

            // Perform the FFT
            this.fft.realTransform(this.out, this.audioWindow);

            // Process the output to get the magnitude spectrum
            for (let i = 0; i < FFT_WINDOW_SIZE / 2; i++) {
                const real = this.out[2 * i];
                const imag = this.out[2 * i + 1];
                this.spectrum[i] = Math.sqrt(real * real + imag * imag);
            }

            // Reset the graphics and clear
            this.graphics.clear();

            // Define canvas dimensions
            const canvasWidth = Engine.getInstance().getDisplay().getWidth() * 0.8;
            const canvasHeight = Engine.getInstance().getDisplay().getHeight();

            // Bar graph settings
            const barWidth = Engine.getInstance().getClipById(this.clipId).getCustomData("barWidth") as number || 40;
            const gap = 2; // Gap between bars
            const numBars = Math.floor(canvasWidth / (barWidth + gap));
            const maxBarHeight = canvasHeight;
            const color = Engine.getInstance().getClipById(this.clipId).getCustomData("barColor") as number || "blue";
            const scalingFactor = Engine.getInstance().getClipById(this.clipId).getCustomData("scalingFactor") as number || 1;

            // Normalize the spectrum values
            const maxMagnitude = Math.max(...this.spectrum);
            const normalizedSpectrum = this.spectrum.map((value) => (value / maxMagnitude) * scalingFactor);

            // Draw bars with translation
            this.graphics.lineStyle(1, color, 1); // White bars
            for (let i = 0; i < numBars; i++) {
                const spectrumIndex = Math.floor((i / numBars) * (FFT_WINDOW_SIZE / 2));
                const barHeight = normalizedSpectrum[spectrumIndex] * maxBarHeight;

                // Calculate the bar position with translation
                const x =
                    -canvasWidth / 2 + (i * (barWidth + gap));
                const y = 0; // Translate up by full canvas height

                // Draw the bar
                this.graphics.beginFill(color); // White color
                this.graphics.drawRect(x, y - barHeight, barWidth, barHeight); // Draw the bar as a rectangle
                this.graphics.endFill();
            }
        }
    }


    // Here we destroy any resources that are not garbage collected
    override destroy() {
        super.destroy();
        // this.graphics.destroy(false);
    }

    override clone() {
        return WaveformClip.deserialize(this.serialize());
    }

    override serialize() : WaveformClipSerialized {
        const serializedParent = super.serialize(); // Serialize the base Clip object
        return {
            ...serializedParent, // Spread the parent serialized fields
            clipId: this.clipId, // Add our custom fields
        };
    }

    static override deserialize(payload: object) {
        const data = WaveformClipSchema.parse(payload);
        // Make JS Gods happy
        const clip = new WaveformClip(data as unknown as WaveformClipOptions);

        return clip;
    }
}

export { WaveformClip };
