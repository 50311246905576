import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {collection, doc, getDoc, onSnapshot, query, updateDoc, where} from "firebase/firestore";
import {db} from "../../Firebase";
import {
    kLibrettoUserCollectionName,
    kProjectsCollectionName, LibrettoUserId,
    ProjectDeleted,
    ProjectUserId
} from "../../utils/CollectionConstants";
import {
    formatUnixTimeBrief, LibrettoPlan,
    LibrettoPlanToSubscriptionDesc,
    LibrettoStudioTheme,
    PlanToRenderablePlan, RefreshTokenAndRetry
} from "../../utils/utils";
import {AuthContext} from "../../context/AuthContext";
import {FetchContext} from "../../context/FetchContext";
import {useConfirmDialog} from "../NewHome/DeleteConfirmation";
import {StudioBackIconButton} from "../NewStudio/SettingsIcon";
import {useLocation, useNavigate} from "react-router-dom";
import {useStripe} from '@stripe/react-stripe-js';
import {Elements} from '@stripe/react-stripe-js';

import {loadStripe} from '@stripe/stripe-js';
import {useTheme} from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {Button, DialogContent, Typography} from "@mui/material";
import SettingsMenu from "../NewStudio/SettingsMenu";
import Dialog from "@mui/material/Dialog";
import UpgradePricingBox, {LifetimeUpgradePricingBox} from "./UpgradePricingBox";
import PaymentErrorCard from "./PaymentErrorCard";
import useMediaQuery from "@mui/material/useMediaQuery";
import SignInUserIntercomComponent from "../../components/IntercomComponent";

const StripePromise = loadStripe('pk_live_51M1XiRBj7f2nVTUZCtLaBjIfMqKiA8OhnoQxOSddZwAJMktMfnlYz1pIrYVdxu9Yoato4ZIRjKZyv7oRp41OpDDJ00dgvuYP97');

const textStyle = {
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0.14px",
}

const whiteTextStyle = {
    ...textStyle,
    color: "white",
}

const blueTextStyle = {
    ...textStyle,
    color: "#2B6BFD",
}

const changePlanButtonStyle = {
    display: "flex",
    padding: "16px 24px",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "440px",
    gap: "10px",
    alignSelf: "stretch",
    borderRadius: "8px",
    cursor: "pointer",
    background: "rgba(43, 107, 253, 0.10)",
    // Set hovered state
    "&:hover": {
        background: "rgba(43, 107, 253, 0.20)",
    },
    // Set focused state
    "&:focus": {
        background: "linear-gradient(0deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.60) 100%), rgba(43, 107, 253, 0.20)"
    },
    // Set disabled state
    "&:disabled": {
        background: "rgba(43, 107, 253, 0.10)",
        opacity: 0.5,
        cursor: "not-allowed",
    }
}

const buttonStyle = {
    display: "flex",
    padding: "16px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    alignSelf: "stretch",
    borderRadius: "8px",
    cursor: "pointer",
    background: "rgba(43, 107, 253, 0.10)",
}

const enabledButtonStyle = {
    ...buttonStyle,
    cursor: "pointer",
    "&:hover": {
        background: "rgba(43, 107, 253, 0.20)",
    },
    "&:focus": {
        background: "linear-gradient(0deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.60) 100%), rgba(43, 107, 253, 0.20)"
    },
}

const disabledButtonStyle = {
    ...buttonStyle,
    opacity: 0.5,
}

const mainBoxAreaStyle = {
    display: "flex",
    padding: "20px",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    gap: "12px",
    alignSelf: "stretch",
    borderRadius: "8px",
    border: "1px solid #E8E8E8",
    background: "#FFF",
}

const DeletePaymentButton = ({handleClick, disabled}) => {
    const theme = useTheme();

    return (
        <Tooltip title="Delete Payment Method">
            <IconButton onClick={handleClick} sx={{
                color: "#ED0C32", // Set color from theme
                fontSize: '2rem', // Increase the font size
                // You can also adjust the size of the icon itself
                '& .MuiSvgIcon-root': {fontSize: '1.5rem'}
            }} disabled={disabled}>
                <DeleteIcon/>
            </IconButton>
        </Tooltip>
    );
};

const SubscriptionPlanAndStatus = ({subscriptionAndPaymentInfo, fetchContext, authContext}) => {
    const stripe = useStripe();

    const handlePaymentMethodUpdate = async () => {
        if (!stripe) {
            return;
        }

        try {
            const response = await fetchContext.authAxios.get('/checkout', {
                headers: {
                    Authorization: `Bearer ${authContext.getToken()}`,
                },
            });

            console.log("Response from checkout endpoint: ", response.data)

            const {sessionId} = response.data;

            // Redirect to Stripe Checkout
            const result = await stripe.redirectToCheckout({
                sessionId: sessionId
            });

            if (result.error) {
                // Handle any errors from Stripe
                console.error(result.error);
            }
        } catch (error) {
            console.error("Error creating checkout session:", error);
        }
    }

    const subStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
    }

    const rowStyle = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        position: "relative",
    }

    const {name, desc, price} = LibrettoPlanToSubscriptionDesc(subscriptionAndPaymentInfo.plan);

    const readableSubscriptionStatus = (subscriptionAndPaymentInfo) => {
        if (subscriptionAndPaymentInfo.plan === LibrettoPlan.AppSumoTier1 || subscriptionAndPaymentInfo.plan === LibrettoPlan.AppSumoTier2) {
            return "Active";
        }

        if (subscriptionAndPaymentInfo.subscriptionStatus === "trialing") {
            return `Free trial, ends ${formatUnixTimeBrief(subscriptionAndPaymentInfo.trialEndDate)}`;
        }

        if (subscriptionAndPaymentInfo.cancelAtPeriodEnd) {
            return "Cancelled, ends " + formatUnixTimeBrief(subscriptionAndPaymentInfo.cancelAt);
        }

        return subscriptionAndPaymentInfo.subscriptionStatus;
    }

    const {openDialog, ConfirmDialogComponent} = useConfirmDialog({confirmText: 'Delete', cancelText: 'Cancel'});

    const deletePaymentMethod = async () => {
        try {
            const response = await fetchContext.authAxios.delete('/payment', {
                headers: {
                    Authorization: `Bearer ${authContext.getToken()}`,
                },
            });
        } catch (error) {
            console.log("Error deleting payment method: ", error)
        }
    }

    const handleDeletePaymentClick = () => {
        openDialog({
            title: 'Delete Payment Method?',
            message: subscriptionAndPaymentInfo.plan != LibrettoPlan.Starter ? "Your subscription will become inactive after the next billing date, " + formatUnixTimeBrief(subscriptionAndPaymentInfo.nextBillingDate) + "." : "",
            onConfirm: async () => {
                await deletePaymentMethod();
            },
        });
    };

    if (!subscriptionAndPaymentInfo.loaded) {
        return (<div>Loading...</div>)
    }

    return (
        <>
            <Box sx={mainBoxAreaStyle}>
                <Box sx={subStyle}>
                    <div style={{
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "24px",
                        color: "#1A1A1A",
                        letterSpacing: "0.18px"
                    }}>
                        {name}
                    </div>
                    <div style={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "16px",
                        color: "#1a1a1a",
                        letterSpacing: "0.18px",
                        opacity: 0.5
                    }}>
                        {desc}
                    </div>
                </Box>
                <Box sx={subStyle}>
                    <div style={{
                        fontFamily: "Inter",
                        fontSize: "15px",
                        fontStyle: "normal",
                        fontWeight: 450,
                        lineHeight: "24px",
                        color: "#1A1A1A",
                        letterSpacing: "0.18px",
                        marginLeft: "auto",
                    }}>
                        {price}
                    </div>
                    <div style={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "16px",
                        color: "#1a1a1a",
                        letterSpacing: "0.18px",
                        opacity: 0.5,
                        marginLeft: "auto",
                    }}>
                        {readableSubscriptionStatus(subscriptionAndPaymentInfo)}
                    </div>
                </Box>
            </Box>
            {subscriptionAndPaymentInfo.stripeHasPaymentMethod && (<Box style={mainBoxAreaStyle}>
                    <Box sx={rowStyle}>
                        <div style={{
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "20px",
                            color: "#1A1A1A",
                            letterSpacing: "0.18px"
                        }}>
                            Payment Method
                        </div>
                        <div style={{
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "16px",
                            color: "#1a1a1a",
                            letterSpacing: "0.18px",
                            opacity: 0.5,
                            marginLeft: "auto",
                        }}>
                            {subscriptionAndPaymentInfo.paymentMethodBrand} ending
                            in {subscriptionAndPaymentInfo.stripePaymentLastFour}
                        </div>
                        <div style={{marginLeft: "5px"}}>
                            <DeletePaymentButton handleClick={handleDeletePaymentClick}/>
                        </div>
                        <ConfirmDialogComponent/>
                    </Box>
                </Box>
            )}
            <Box sx={changePlanButtonStyle} onClick={handlePaymentMethodUpdate}>
                <div style={blueTextStyle}>
                    {subscriptionAndPaymentInfo.stripeHasPaymentMethod ? "Update Payment Method" : "Add Payment Method"}
                </div>
            </Box>
        </>)
}

const cancelPlanButtonStyle = {
    display: "flex",
    padding: "16px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    alignSelf: "stretch",
    borderRadius: "8px",
    cursor: "pointer",
    background: "#ED0C32",
    // Set hovered state
    "&:hover": {
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.10)0%, rgba(0, 0, 0, 0.10)100%), #D60C2B',
    },
}

const resumePlanButtonStyle = {
    display: "flex",
    padding: "16px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    alignSelf: "stretch",
    borderRadius: "8px",
    cursor: "pointer",
    // Light green
    background: "#84d422",
    // Set hovered state
    "&:hover": {
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.10)0%, rgba(0, 0, 0, 0.10)100%), #84d422',
    },
}

const ResumeOrCancelButton = ({planCancelled, cancelAtPeriodEnd, handleResurrectClick, handleResumeClick, handleCancelClick}) => {
    if (cancelAtPeriodEnd) {
        return (
            <Box sx={resumePlanButtonStyle} onClick={handleResumeClick}>
                <div style={whiteTextStyle}>Resume Plan</div>
            </Box>
        )
    }

    if (!planCancelled) {
        return (
            <Box sx={cancelPlanButtonStyle} onClick={handleCancelClick}>
                <div style={whiteTextStyle}>Cancel Plan</div>
            </Box>
        )
    }

    return null;
}

const NewSettings = ({upgradeIntent}) => {
    const location = useLocation();
    const [reload, setReload] = useState(false);

    const [showUpgradePlanBox, setShowUpgradePlanBox] = useState(false);
    const [showUpgradeErrorBox, setShowUpgradeErrorBox] = useState(false);
    const [upgradeErrorCode, setUpgradeErrorCode] = useState(0);

    const [showPaywall, setShowPaywall] = useState(false);

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const authContext = useContext(AuthContext);

    const fetchContext = useContext(FetchContext);

    const checkoutSuccess = async (sessionId) => {
        try {
            const response = await fetchContext.authAxios.get(`/checkout/success/${sessionId}`, {
                headers: {
                    Authorization: `Bearer ${authContext.getToken()}`,
                }
            });
        } catch (error) {
            console.error("Error confirming checkout success:", error);
        }
    }

    useEffect(() => {
        authContext.getSubscriptionStatusFromStorage({userId: authContext.getUserId()}).then((statusAndUserPlan) => {
            if (statusAndUserPlan.status != "active" && statusAndUserPlan.status != "trialing" && statusAndUserPlan.userPlan != LibrettoPlan.Free) {
                setShowPaywall(true);
            }
        })
    });

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        let currentSuccess = false;
        if (params.has('success')) {
            currentSuccess = params.get('success') === 'true';
        }
        let currentSessionId = null;
        if (params.has('session_id')) {
            currentSessionId = params.get('session_id');
        }

        if (currentSuccess && currentSessionId) {
            checkoutSuccess(currentSessionId).then(() => setReload(!reload));
        }

        if (params.has('upgrade') || upgradeIntent) {
            setShowUpgradePlanBox(true);
        }

    }, [location]);


    const userId = authContext.getUserId();

    const [userState, setUserState] = useState({
        name: '',
        email: '',
        studio: '',
        planText: null,
        plan: null,
        createdAt: 0,
        trialEndDate: null,
        subscriptionStatus: null,
        stripeHasPaymentMethod: null,
        stripePaymentLastFour: null,
        paymentMethodBrand: null,
        nextBillingDate: null,
        loaded: false,
        cancelAtPeriodEnd: false,
        cancelAt: null,
    });

    const cancelAtPeriodEnd = userState.cancelAtPeriodEnd;

    const planCancelled = userState.subscriptionStatus === "cancelled";

    useEffect(() => {
        if (!userId) {
            return;
        }

        const q = query(collection(db, kLibrettoUserCollectionName), where(LibrettoUserId, "==", userId));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const newUserState = [];
                querySnapshot.forEach((doc) => {
                    newUserState.push(doc.data());
                });
                if (newUserState.length > 0) {
                    const docData = newUserState[0];
                    setUserState({
                        loaded: true,
                        name: docData.displayName,
                        email: docData.email,
                        studio: docData.userFriendlyRoomName,
                        planText: PlanToRenderablePlan(docData.plan),
                        plan: docData.plan,
                        createdAt: docData.createdAt,
                        trialEndDate: docData.trialEndDate,
                        subscriptionStatus: docData.subscriptionStatus,
                        stripeHasPaymentMethod: docData.stripeHasPaymentMethod,
                        stripePaymentLastFour: docData.stripePaymentLastFour,
                        paymentMethodBrand: docData.paymentMethodBrand,
                        nextBillingDate: docData.nextBillingDate,
                        cancelAtPeriodEnd: docData.cancelAtPeriodEnd,
                        cancelAt: docData.cancelAt,
                    });

                }
            },
            (error) => {
                if (error.code === 'permission-denied') {
                    authContext.logout();
                }
            });

        return () => unsubscribe();
    }, [authContext, userId]);

    const updateUserState = async () => {

        const entityRef = doc(db, kLibrettoUserCollectionName, userId);
        const kLibrettoDisplayName = 'librettoDisplayName';
        try {
            await updateDoc(entityRef, {displayName: userState.name, userFriendlyRoomName: userState.studio});
            sessionStorage.setItem(kLibrettoDisplayName, userState.name);
        } catch (error) {
            console.error('Error updating title: ', error);
        }
    };

    const pageStyle = {
        minHeight: "100vh",
        width: "100%",
        backgroundColor: "#f3f4f5",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflow: "auto",
    }

    const mainAreaStyle = {
        margin: "37px",
        display: "flex",
        width: "676px",
        maxWidth: "100%",
        padding: "48px 120px",
        paddingBottom: "64px",
        flexDirection: "column",
        alignItems: "flex-end",
        gap: "24px",
        borderRadius: "12px",
        backgroundColor: "#fff",
    }

    const headerStyle = {
        fontFamily: "Inter",
        fontSize: "42px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "52px",
        alignSelf: "flex-start",
        color: "#1a1a1a"
    }

    const subHeaderStyle = {
        fontFamily: "Inter",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "24px",
        alignSelf: "flex-start",
        color: "#1a1a1a"
    }

    const normalTextStyle = {
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.14px",
        alignSelf: "flex-start",
        color: "#808080"
    }

    const saveButtonStyle = {
        display: "flex",
        flexDirection: "row",
        height: "52px",
        width: "154px",
        padding: "10px 20px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "6px",
        backgroundColor: "#2B6BFD",
        cursor: "pointer",
        // Set hovered state
        "&:hover": {
            background: "linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), #2B6BFD",
        },
        // Set focused state
        "&:focus": {
            background: "linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), #2B6BFD"
        },
        // Set disabled state
        "&:disabled": {
            background: "#2B6BFD",
            opacity: 0.3,
            cursor: "not-allowed",
        }
    }

    const {openDialog, ConfirmDialogComponent} = useConfirmDialog({confirmText: 'Cancel', cancelText: 'Keep'});

    const cancelSubscription = async () => {
        try {
            const response = await fetchContext.authAxios.post(`/subscription/cancel`, {}, {
                headers: {
                    Authorization: `Bearer ${authContext.getToken()}`,
                }
            });
        } catch (error) {
            console.error("Error confirming checkout success:", error);
        }
    }

    const resumeSubscription = async () => {
        try {
            const response = await fetchContext.authAxios.post(`/subscription/resume`, {}, {
                headers: {
                    Authorization: `Bearer ${authContext.getToken()}`,
                }
            });
        } catch (error) {
            console.error("Error confirming checkout success:", error);
        }
    }

    const handleResumeClick = () => {
        resumeSubscription().then(() => setReload(!reload));
    }

    const handleResurrectClick = () => {

    }

    const handleCancelClick = () => {
        openDialog({
            title: 'Cancel Subscription?',
            message: "You subscription will be cancelled at the end of the current billing period, on " + formatUnixTimeBrief(userState.nextBillingDate) + ". You'll not be charged.",
            onConfirm: async () => {
                await cancelSubscription();
            },
        });
    };

    const navigate = useNavigate();

    const handlePlanTypeChange = async (planType) => {
        try {
            const response = await fetchContext.authAxios.post(`/subscription/update`, {
                plan: planType,
            }, {
                headers: {
                    Authorization: `Bearer ${authContext.getToken()}`,
                }
            });
            setShowUpgradePlanBox(false);
        } catch (error) {
            console.error("Error confirming checkout success:", error);
            setShowUpgradePlanBox(false);
            setShowUpgradeErrorBox(true);
            setUpgradeErrorCode(error.response.status);
        }
    }

    const changePlanButtonDisabled = userState.plan === LibrettoPlan.AppSumoTier2;

    const handleUpgrade = async (appSumoUpgradeCode) => {
        try {
            const {data} = await fetchContext.authAxios.post('/upgrade', {
                code: appSumoUpgradeCode,
                plan: LibrettoPlan.AppSumoTier2,
            }, {
                headers: {
                    Authorization: `Bearer ${authContext.getToken()}`,
                }
            });
            setShowUpgradePlanBox(false);
        } catch (error) {
            if (error.response.status === 401) {
                await RefreshTokenAndRetry(error, authContext, fetchContext);
            }
            console.log("Error upgrading plan: ", error);
            setShowUpgradePlanBox(false);
        }
    }

    const handleChangePlanButtonClick = () => {
        if (!changePlanButtonDisabled) {
            setShowUpgradePlanBox(!showUpgradePlanBox);
        }
    }

    if (isMobile) {
       return (<>
            <Dialog
                open={isMobile}
                onClose={() => {}}
                aria-labelledby="form-dialog-title"
            >
                <DialogContent sx={{
                    borderRadius: '30px',
                    display: 'flex',        // Enable flexbox
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center',     // Center vertically
                    minWidth: {xs: '300px', md: '400px'},
                }}>
                    <Typography><b>Please log in on a computer or laptop.</b> You need a larger screen to view this.</Typography>
                </DialogContent>
            </Dialog>
        </>)
    }

    return (
        <Box style={pageStyle}>
            <SignInUserIntercomComponent user={authContext.getUser()} />
            <Box style={{position: "absolute", left: "32px", top: "20px"}}>
                <StudioBackIconButton theme={LibrettoStudioTheme.Light} handleClick={() => navigate("/dashboard")}/>
            </Box>
            <Box style={mainAreaStyle}>
                <Box style={headerStyle}>
                    Settings
                </Box>
                <Box style={subHeaderStyle}>
                    General
                </Box>
                <TextField
                    variant="outlined"
                    label="Name"
                    value={userState.name}
                    onChange={(e) => setUserState({...userState, name: e.target.value})}
                    InputProps={{
                        style: {
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "20px",
                            letterSpacing: "0.14px",
                            color: "#000000",
                            height: "64px",
                            backgroundColor: "white",
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: '#9E9E9E',
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 400,
                        },
                        shrink: true,
                    }}
                    sx={{
                        width: '100%',
                        height: '64px',
                        marginTop: "10px",
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: "1px solid #e8e8e8",
                            },
                            '&:hover fieldset': {
                                border: "1px solid #e8e8e8",
                            },
                            '&.Mui-focused fieldset': {
                                border: "1px solid #e8e8e8",
                            },
                        },
                        '& .MuiInputBase-root': {
                            backgroundColor: 'white',
                        },
                    }}
                />
                <TextField
                    variant="outlined"
                    disabled={true}
                    label="Email"
                    value={userState.email}
                    onChange={(e) => setUserState({...userState, email: e.target.value})}
                    InputProps={{
                        readOnly: true,
                        style: {
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "20px",
                            letterSpacing: "0.14px",
                            color: "#000000",
                            height: "64px",
                            backgroundColor: "white",
                            readOnly: true,
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: '#9E9E9E',
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 400,
                        },
                        shrink: true,
                    }}
                    sx={{
                        width: '100%',
                        height: '64px',
                        marginTop: "10px",
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: "1px solid #e8e8e8",
                            },
                            '&:hover fieldset': {
                                border: "1px solid #e8e8e8",
                            },
                            '&.Mui-focused fieldset': {
                                border: "1px solid #e8e8e8",
                            },
                        },
                        '& .MuiInputBase-root': {
                            backgroundColor: 'white',
                        },
                    }}
                />
                <Dialog
                    open={showPaywall}
                    onClose={() => {}}
                    aria-labelledby="form-dialog-title"
                >
                    <Box sx={{
                        borderRadius: '30px',
                        padding: "30px",
                        display: 'flex',        // Enable flexbox
                        maxWidth: "440px",
                        justifyContent: 'center', // Center horizontally
                        alignItems: 'center',     // Center vertically
                        flexDirection: "column",
                        gap: "18px",
                        minWidth: {xs: '300px', md: '400px'},
                    }}>
                        <Box><b>Your subscription is inactive.</b> Add a valid payment method or change your plan to continue using Libretto.</Box>
                        <Elements stripe={StripePromise}>
                            <SubscriptionPlanAndStatus subscriptionAndPaymentInfo={{
                                plan: userState.plan, trialEndDate: userState.trialEndDate,
                                subscriptionStatus: userState.subscriptionStatus,
                                stripeHasPaymentMethod: userState.stripeHasPaymentMethod,
                                loaded: userState.loaded,
                                stripePaymentLastFour: userState.stripePaymentLastFour,
                                paymentMethodBrand: userState.paymentMethodBrand,
                                nextBillingDate: userState.nextBillingDate,
                                cancelAtPeriodEnd: userState.cancelAtPeriodEnd,
                                cancelAt: userState.cancelAt,
                            }} fetchContext={fetchContext} authContext={authContext}/>
                        </Elements>
                        <Box sx={{...(changePlanButtonDisabled ? disabledButtonStyle : enabledButtonStyle),}}
                             onClick={handleChangePlanButtonClick}>
                            <div style={blueTextStyle}>Change Plan</div>
                        </Box>
                        <Box style={normalTextStyle} marginTop={"-8px"}>
                            Questions? Email us at
                            {' '}<span style={{...normalTextStyle, color: "#2B6BFD"}}>contact@libretto.fm</span>
                        </Box>
                    </Box>
                </Dialog>
                <TextField
                    variant="outlined"
                    label="Studio name"
                    value={userState.studio}
                    onChange={(e) => setUserState({...userState, studio: e.target.value})}
                    InputProps={{
                        style: {
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "20px",
                            letterSpacing: "0.14px",
                            color: "#000000",
                            height: "64px",
                            backgroundColor: "white",
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: '#9E9E9E',
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 400,
                        },
                        shrink: true,
                    }}
                    sx={{
                        width: '100%',
                        height: '64px',
                        marginTop: "10px",
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: "1px solid #e8e8e8",
                            },
                            '&:hover fieldset': {
                                border: "1px solid #e8e8e8",
                            },
                            '&.Mui-focused fieldset': {
                                border: "1px solid #e8e8e8",
                            },
                        },
                        '& .MuiInputBase-root': {
                            backgroundColor: 'white',
                        },
                    }}
                />
                <Box style={normalTextStyle} marginTop={"-8px"}>
                    For any questions, please email us at
                    {' '}<span style={{...normalTextStyle, color: "#2B6BFD"}}>contact@libretto.fm</span>
                </Box>
                <Box sx={saveButtonStyle} onClick={updateUserState}>
                    <div style={whiteTextStyle}>Save</div>
                </Box>
                <div style={{
                    height: "1px",
                    alignSelf: "stretch",
                    background: "#e8e8e8",
                    marginTop: "12px",
                    marginBottom: "16px"
                }}/>
                <Box style={subHeaderStyle}>
                    Subscription
                </Box>
                <Elements stripe={StripePromise}>
                    <SubscriptionPlanAndStatus subscriptionAndPaymentInfo={{
                        plan: userState.plan, trialEndDate: userState.trialEndDate,
                        subscriptionStatus: userState.subscriptionStatus,
                        stripeHasPaymentMethod: userState.stripeHasPaymentMethod,
                        loaded: userState.loaded,
                        stripePaymentLastFour: userState.stripePaymentLastFour,
                        paymentMethodBrand: userState.paymentMethodBrand,
                        nextBillingDate: userState.nextBillingDate,
                        cancelAtPeriodEnd: userState.cancelAtPeriodEnd,
                        cancelAt: userState.cancelAt,
                    }} fetchContext={fetchContext} authContext={authContext}/>
                </Elements>
                <Box sx={{...(changePlanButtonDisabled ? disabledButtonStyle : enabledButtonStyle),}}
                     onClick={handleChangePlanButtonClick}>
                    <div style={blueTextStyle}>Change Plan</div>
                </Box>
                <Dialog
                    open={showUpgradePlanBox}
                    onClose={() => setShowUpgradePlanBox(false)}
                    aria-labelledby="form-dialog-title"
                    maxWidth={"1000px"}
                >
                    <DialogContent sx={{
                        borderRadius: '30px',
                        display: 'flex',        // Enable flexbox
                        justifyContent: 'center', // Center horizontally
                        alignItems: 'center',     // Center vertically
                        padding: "0px 0px 0px 0px",
                    }}>
                        <ChangePlanSection currentPlan={userState.plan} onChangePlan={handlePlanTypeChange}
                                           onLifetimeUpgrade={handleUpgrade}/>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={showUpgradeErrorBox}
                    onClose={() => setShowUpgradeErrorBox(false)}
                    aria-labelledby="form-dialog-title"
                    maxWidth={"500px"}
                >
                    <PaymentErrorCard errorCode={upgradeErrorCode}/>
                </Dialog>
                <ResumeOrCancelButton planCancelled={planCancelled} cancelAtPeriodEnd={cancelAtPeriodEnd} handleCancelClick={handleCancelClick}
                                      handleResurrectClick={handleResurrectClick} handleResumeClick={handleResumeClick}/>
                <ConfirmDialogComponent/>
            </Box>
        </Box>
    )
}

const ChangePlanSection = ({currentPlan, onChangePlan, onLifetimeUpgrade}) => {
    if (currentPlan === LibrettoPlan.AppSumoTier1) {
        return <LifetimeUpgradePricingBox onUpgrade={onLifetimeUpgrade}/>
    } else {
        return (
            <UpgradePricingBox currentPlan={currentPlan} onChangePlan={onChangePlan}/>
        )
    }
}

export default NewSettings;
