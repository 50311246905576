import {createBrowserRouter, RouterProvider, Navigate, useNavigate} from "react-router-dom";
import React, {useContext} from 'react';
import {AuthContext, AuthProvider} from "./context/AuthContext";
import {FetchContext, FetchProvider} from "./context/FetchContext";
import Home from "./pages/Home/Home";
import Asset from "./pages/Asset/Asset";
import Page from "./components/Page";
import StudioAndLobby from "./pages/Studio/StudioAndLobby";
import GuestStudioAndLobby from "./pages/GuestStudio/GuestStudioAndLobby";
import LandingPage from "./pages/Landing/Landing";
import Settings from "./pages/Settings/Settings";
import PrivacyPolicy from "./pages/Landing/PrivacyPolicy";
import TermsOfService from "./pages/Landing/Terms";
import {NewPricingpage, Pricing} from "./pages/Pricing/Pricing";
import SharedProject from "./pages/SharedProject/SharedProject";
import SharedAsset from "./pages/SharedAsset/SharedAsset";
import {LibrettoLiveblocksProvider} from "./pages/Editor/LibrettoLiveblocksContext";
import EditorWrapper from "./pages/Editor/Editor";
import GuestEditorWrapper from "./pages/GuestEditor/GuestEditor";
import NewHome, {SharedHomePage} from "./pages/NewHome/NewHome";
import NewStudioAndLobby from "./pages/NewStudio/NewStudioAndLobby";
import NewSettings from "./pages/NewSettings/NewSettings";
import NewGuestStudioAndLobby from "./pages/NewGuestStudio/NewGuestStudioAndLobby";
import NewAsset, {NewSharedAsset} from "./pages/NewAsset/NewAsset";
import NewLandingPage, {ModernLandingPage} from "./pages/NewLanding/LandingPage";
import RecordScreen from "./pages/Screen/Screen";
import LiveblocksWrappedEditor from "./pages/ModernEditor/ModernEditor";
import {LiveblocksProvider} from "@liveblocks/react/suspense";
import axios from "axios";

const AuthenticatedRoute = ({children, path}) => {
  const auth = useContext(AuthContext);

  if (auth.isUserAuthenticated()) {

    if (path === "settings") {
      return <NewSettings/>
    }

    // When subscription is inactive, we force navigate to settings page.
    // The path var avoids infinite loop.
    if (!auth.isUserSubscriptionActive()) {
      return <Navigate to="/settings" replace={true}/>
    }

    return children;
  }
  return (
      <Navigate to="/" replace={true}/>
  );
};

if (typeof process === 'undefined') {
  global.process = {
    env: { NODE_ENV: 'development' }
    // You can add more properties to this object if needed
  };
}
const UnAuthenticatedRoute = ({children, isCatchAll}) => {
  const auth = useContext(AuthContext);

  if (isCatchAll) {
    return (
        <Navigate to="/dashboard" replace={true}/>
    );
  }


  if (!auth.isUserAuthenticated()) {
    return children;
  }
  return (
      <Navigate to="/dashboard"/>
  );
}

const router = createBrowserRouter([
  {path: "/editor/:assetId/:editId", element: <AuthenticatedRoute><NewHome/></AuthenticatedRoute>}, // S
  {path: "/editor/:assetId/:editId/:token", element: <UnAuthenticatedRoute><ModernLandingPage/></UnAuthenticatedRoute>}, // S
  {path: "/privacy", element: <PrivacyPolicy/>},
  {path: "/logout", element: <UnAuthenticatedRoute><Navigate to="/"/></UnAuthenticatedRoute>},
  {path: "/terms", element: <TermsOfService/>},
  {path: "/screen/:projectId", element: <AuthenticatedRoute><RecordScreen/></AuthenticatedRoute>},
  {path: "/", element: <UnAuthenticatedRoute><ModernLandingPage/></UnAuthenticatedRoute>},
  {path: "/edit/:editId", element: <AuthenticatedRoute><LiveblocksWrappedEditor/></AuthenticatedRoute>},
  {path: "/guest/:roomName/:userFriendlyRoomName", element: <NewGuestStudioAndLobby/>},
  {path: "/dashboard", element: <AuthenticatedRoute><NewHome/></AuthenticatedRoute>},
  {path: "/studio/:projectId/:title?/:assetId?", element: <AuthenticatedRoute><NewStudioAndLobby/></AuthenticatedRoute>},
  {path: "/settings", element: <AuthenticatedRoute path={"settings"}><NewSettings/></AuthenticatedRoute>},
  {path: "/shared/projects/:projectId/:token", element: <SharedHomePage/>},
  {path: "/assets/:assetId", element: <AuthenticatedRoute><NewAsset/></AuthenticatedRoute>},
  {path: "/shared/assets/:assetId/:token", element: <NewSharedAsset/>},
  {
    path: "*", // Catch-all route
    element: <UnAuthenticatedRoute isCatchAll={true}><ModernLandingPage/></UnAuthenticatedRoute>, // Redirect based on login status
  },
]);

const kIsAuthenticated = 'isAuthenticatedOnLibretto';
const kLibrettoToken = 'librettoUserToken';
const kUserEmailVerified = 'librettoUserEmailVerified';

const App = () => {

  return (
      <Page>
        <FetchProvider>
          <AuthProvider>
            <LiveblocksProvider authEndpoint={async (room) => {

              const isAuthenticated = sessionStorage.getItem(kIsAuthenticated) === 'true' && sessionStorage.getItem(kUserEmailVerified) === 'true'
              const token = sessionStorage.getItem(kLibrettoToken);
              const isProduction = sessionStorage.getItem("isProduction") === 'true';

              const urlEndpoint = isAuthenticated ? "/editor/join" : "/editor/guest";
              const fullUrlEndpoint = isProduction ? `https://cdn.libretto.fm${urlEndpoint}` : `https://localhost:8088${urlEndpoint}`;
              const authToken = isAuthenticated ? token : "";

              let response = null;
              try {
                response = await axios.post(fullUrlEndpoint, {}, {
                  headers: {
                    Authorization: `Bearer ${authToken}`,
                  }
                })
                return response.data;
              } catch (error) {
                if (error.response.status === 401) {
                  window.location.href = "/";
                }
              }

              if (response === null) {
                return null;
              }
              return response.data;
            }}>
              <RouterProvider router={router}/>
            </LiveblocksProvider>
          </AuthProvider>
        </FetchProvider>
      </Page>
  );
}

export default App;
