import React, { useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { ClipTypeEnum } from "@rendley/sdk";
import { Clip, RendleyStore } from "../../../../store/RendleyStore";
import { useCreateDropClipPlaceholder } from "../../hooks/tracks/useCreateDropClipPlaceholder";
import { TRACK_CLASSNAME } from "../../config/constants";
import { ApplicationStore } from "../../../../store/ApplicationStore";
import { RendleyService } from "../../../../services/RendleyService";
import { TimelineService } from "../../services/TimelineService";
import { convertUnitsToTime } from "../../../../utils/dom/convertUnitsToTime";
import './Track.styles.scss';

// Import your clip components here
import VideoClip from "../VideoClip/VideoClip";
import Transition from "../Transition/Transition";
import AudioClip from "../AudioClip/AudioClip";
import ImageClip from "../ImageClip/ImageClip";
import TextClip from "../TextClip/TextClip";
import ShapeClip from "../ShapeClip/ShapeClip";
import SubtitlesClip from "../SubtitlesClip/SubtitlesClip";
import LottieClip from "../LottieClip/LottieClip";
import WaveformClip from "../WaveformClip/WaveformClip";

interface TrackProps {
    layerId: string;
    setContextMenuVisible: (visible: boolean) => void;
    setContextMenuPosition: (position: { x: number, y: number }) => void;
    timelineTracksRef: React.RefObject<HTMLDivElement>;
}

export const Track: React.FC<TrackProps> = observer(({ layerId, setContextMenuVisible, setContextMenuPosition, timelineTracksRef }) => {
    const placeholderRef = useRef<HTMLDivElement>(null);
    const createDropClipPlaceholder = useCreateDropClipPlaceholder(() => placeholderRef.current);

    const layer = RendleyStore.layers[layerId];
    const temporaryTransitions = layer ? TimelineService.getTemporaryTransitions(layerId) : [];
    const clipsIds = layer?.clipsIds ?? [];
    const transitionIds = layer?.transitionIds ?? [];
    const clips = clipsIds.reduce<Record<string, Clip>>((acc, cv) => {
        acc[cv] = RendleyStore.clips[cv];
        return acc;
    }, {});

    const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        // @ts-ignore
        createDropClipPlaceholder.onDragLeave(event);

        try {
            const draggingObject = ApplicationStore.draggingObject;
            let clipId: string | undefined;

            if (!draggingObject) {
                return null;
            }

            if (draggingObject.type === "media") {
                const mediaId = draggingObject.payload.id;
                const startTime = convertUnitsToTime(event.nativeEvent.offsetX);
                const clip = await RendleyService.addMediaToLayer(layerId, mediaId, startTime);
                clipId = clip?.id;
            }

            if (draggingObject.type === "text-preset") {
                const title = draggingObject.payload;
                const startTime = convertUnitsToTime(event.nativeEvent.offsetX);
                const clip = await RendleyService.createLottieClip({
                    dataUrl: title.dataUrl,
                    propertiesUrl: title.propertiesUrl,
                    startTime,
                    layerId: layerId,
                });
                clipId = clip?.id;
            }

            if (clipId != null) {
                ApplicationStore.setSelectedClipId(clipId);
            }
        } catch {
            return null;
        }
    };

    const ReadableClipType = (clipType: string) => {
        return String(clipType).toLowerCase();
    }

    const renderClip = (clipId: string, index: number) => {
        const clip = clips[clipId];

        switch (ReadableClipType(clip.type)) {
            case ClipTypeEnum.GIF:
            case ClipTypeEnum.VIDEO:
                return <VideoClip key={`${clipId}-${index}`} clipId={clip.id} layerId={layerId} />;
            case ClipTypeEnum.AUDIO:
                return <AudioClip key={`${clipId}-${index}`} clipId={clip.id} layerId={layerId} />;
            case ClipTypeEnum.IMAGE:
                return <ImageClip key={`${clipId}-${index}`} clipId={clip.id} layerId={layerId} />;
            case ClipTypeEnum.TEXT:
                return <TextClip key={`${clipId}-${index}`} clipId={clip.id} layerId={layerId} />;
            case ClipTypeEnum.SHAPE:
                return <ShapeClip key={`${clipId}-${index}`} clipId={clip.id} layerId={layerId} />;
            case ClipTypeEnum.SUBTITLES:
                return <SubtitlesClip key={`${clipId}-${index}`} clipId={clip.id} layerId={layerId} />;
            case ClipTypeEnum.LOTTIE:
                return <LottieClip key={`${clipId}-${index}`} clipId={clip.id} layerId={layerId} />;
            case "waveform_clip":
                return <WaveformClip key={`${clipId}-${index}`} clipId={clip.id} layerId={layerId}/>
            default:
                return null;
        }
    };

    const renderTransition = (transitionId: string) => {
        return <Transition key={transitionId} type="permanent" permanentTransition={{ transitionId }} />;
    };

    const renderTransitionPlaceholder = ({ startTime, layerId, clipId }: any) => {
        return <Transition key={startTime} type="temporary" temporaryTransition={{ startTime, layerId, clipId }} />;
    };

    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        // @ts-ignore
        return createDropClipPlaceholder.onDragEnter(event);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        // @ts-ignore
        return createDropClipPlaceholder.onDragOver(event);
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        // @ts-ignore
        return createDropClipPlaceholder.onDragLeave(event);
    };

    const handleTrackContextMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const boundingRect = timelineTracksRef.current?.getBoundingClientRect();
        setContextMenuVisible(true);
        setContextMenuPosition({
            x: event.clientX - (boundingRect?.left || 0),
            y: event.clientY - (boundingRect?.top || 0),
        });
    }

    return (
        <div id={layerId} onContextMenu={handleTrackContextMenuClick} className={`${TRACK_CLASSNAME} ${TRACK_CLASSNAME}-${layerId}`}>
            <div
                className="track__content"
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onDragLeave={handleDragLeave}
            >
                <div ref={placeholderRef} className="track__drop-clip-placeholder"></div>
                {clipsIds.map(renderClip)}
                {transitionIds.map(renderTransition)}
                {temporaryTransitions.map(renderTransitionPlaceholder)}
            </div>


        </div>
    );
});