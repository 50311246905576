import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {
    ClipTypeEnum,
    Engine,
    HighlightAnimationEnum,
    MainTextStyle, SubtitlesClip,
    TextModeType,
} from '@rendley/sdk';
import {SubtitlesStylesDescription} from '../../../../config/config';
import {RendleyStore} from '../../../../store/RendleyStore';
import {ApplicationStore} from '../../../../store/ApplicationStore';
// Assuming these components are available as React components
import PanelGridListSection from "../../../../components/PanelGridListSection/PanelGridListSection";
import EffectShowcaseCard from "../../../../components/EffectsShowcaseCard/EffectShowcaseCard";
import {reaction} from "mobx";
import {ClipsToTimelineItems, GetSRTSubtitleUrl} from "../../../../../pages/ModernEditor/MultitrackTranscript";
import {RendleyService} from "../../../../services/RendleyService";
import {useStorage} from "@liveblocks/react/suspense";
import {ToggleButton} from "../../../../../pages/NewStudio/ToggleButton";
import Box from "@mui/material/Box";
import {WaveformClipOptions} from "../../../../custom/WaveFormClip";
import Typography from "@mui/material/Typography";

interface StyleConfiguration {
    name?: string;
    value: string;
    thumbnailUrl: string;
    videoPreviewUrl?: string;
}

// @ts-ignore
const AudiogramStylesContainer = observer(() => {
    const [colors, setColors] = useState<StyleConfiguration[]>([]);
    const [widths, setWidths] = useState<StyleConfiguration[]>([]);
    const [scalingFactors, setScalingFactors] = useState<StyleConfiguration[]>([]);
    const [enabledDisabled, setEnabledDisabled] = useState<StyleConfiguration[]>([]);

    const [selectedEnabled, setSelectedEnabled] = useState(RendleyStore.audiogramStyles.selectedEnabled);
    const [selectedBarWidth, setSelectedBarWidth] = useState(RendleyStore.audiogramStyles.selectedBarWidth);
    const [selectedColor, setSelectedColor] = useState(RendleyStore.audiogramStyles.selectedColor);

    useEffect(() => {
        const disposer = reaction(
            () => ({
                selectedEnabled: RendleyStore.audiogramStyles.selectedEnabled,
                selectedBarWidth: RendleyStore.audiogramStyles.selectedBarWidth,
                selectedColor: RendleyStore.audiogramStyles.selectedColor,
            }),
            ({selectedEnabled, selectedColor, selectedBarWidth}) => {
                setSelectedEnabled(selectedEnabled);
                setSelectedColor(selectedColor);
                setSelectedBarWidth(selectedBarWidth);
            });
        return () => {
            disposer(); // Dispose of the autorun when the component unmounts
        };
    }, []);

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        setWidths([{
         name: '20',
         value: '20',
            thumbnailUrl: 'https://dummyimage.com/600x400/000000/fff.png&text=20',
            videoPreviewUrl: 'https://dummyimage.com/600x400/000000/fff.png&text=20',
        },
            {
                name: '40',
                value: '40',
                thumbnailUrl: 'https://dummyimage.com/600x400/000000/fff.png&text=40',
                videoPreviewUrl: 'https://dummyimage.com/600x400/000000/fff.png&text=40',
            },
            {
                name: '60',
                value: '60',
                thumbnailUrl: 'https://dummyimage.com/600x400/000000/fff.png&text=60',
                videoPreviewUrl: 'https://dummyimage.com/600x400/000000/fff.png&text=60',
            },
            {
                name: '80',
                value: '80',
                thumbnailUrl: 'https://dummyimage.com/600x400/000000/fff.png&text=80',
                videoPreviewUrl: 'https://dummyimage.com/600x400/000000/fff.png&text=80',
            },
            {
                name: '100`',
                value: '100',
                thumbnailUrl: 'https://dummyimage.com/600x400/000000/fff.png&text=100',
                videoPreviewUrl: 'https://dummyimage.com/600x400/000000/fff.png&text=100',
            },
            {
                name: '120',
                value: '120',
                thumbnailUrl: 'https://dummyimage.com/600x400/000000/fff.png&text=120',
                videoPreviewUrl: 'https://dummyimage.com/600x400/000000/fff.png&text=120',
            },
        ]);

        setScalingFactors([
            {
                name: '1x',
                value: '1',
                thumbnailUrl: 'https://dummyimage.com/600x400/000000/fff.png&text=1x',
                videoPreviewUrl: 'https://dummyimage.com/600x400/000000/fff.png&text=1x',
            },
            {
                name: '2x',
                value: '2',
                thumbnailUrl: 'https://dummyimage.com/600x400/000000/fff.png&text=2x',
                videoPreviewUrl: 'https://dummyimage.com/600x400/000000/fff.png&text=2x',
            },
            {
                name: '3x',
                value: '3',
                thumbnailUrl: 'https://dummyimage.com/600x400/000000/fff.png&text=3x',
                videoPreviewUrl: 'https://dummyimage.com/600x400/000000/fff.png&text=3x',
            },
            {
                name: '4x',
                value: '4',
                thumbnailUrl: 'https://dummyimage.com/600x400/000000/fff.png&text=4x',
                videoPreviewUrl: 'https://dummyimage.com/600x400/000000/fff.png&text=4x',
            }
        ]);

        setColors([{
            name: 'Red',
            value: 'red',
            thumbnailUrl: 'https://dummyimage.com/600x400/ff0000/fff.png&text=+',
            videoPreviewUrl: 'https://dummyimage.com/600x400/ff0000/fff.png&text=Red',
        }, {
            name: 'Green',
            value: 'green',
            thumbnailUrl: 'https://dummyimage.com/600x400/00ff00/fff.png&text=+',
            videoPreviewUrl: 'https://dummyimage.com/600x400/00ff00/fff.png&text=+',
        }, {
            name: 'Blue',
            value: 'blue',
            thumbnailUrl: 'https://dummyimage.com/600x400/0000ff/fff.png&text=+',
            videoPreviewUrl: 'https://dummyimage.com/600x400/0000ff/fff.png&text=+',
        }, {
            name: 'Orange',
            value: 'orange',
            thumbnailUrl: 'https://dummyimage.com/600x400/ffa500/fff.png&text=+',
            videoPreviewUrl: 'https://dummyimage.com/600x400/ffa500/fff.png&text=+',
        },
            {
                name: 'Yellow',
                value: 'yellow',
                thumbnailUrl: 'https://dummyimage.com/600x400/ffff00/fff.png&text=+',
                videoPreviewUrl: 'https://dummyimage.com/600x400/ffff00/fff.png&text=+',
            },
            {
                name: 'Purple',
                value: 'ff00ff',
                thumbnailUrl: 'https://dummyimage.com/600x400/ff00ff/fff.png&text=+',
                videoPreviewUrl: 'https://dummyimage.com/600x400/ff00ff/fff.png&text=+',
            }]);
    };

    const handleSetColor = (value: StyleConfiguration) => {
        RendleyStore.setAudiogramColor(value.value);
        Engine.getInstance().getClipById(RendleyStore.audiogramStyles.audiogramAudioClipId)?.setCustomData("barColor", value.value);
    }

    const handleSetBarWidth = (value: StyleConfiguration) => {
        RendleyStore.setAudiogramBarWidth(Number(value.value));
        Engine.getInstance().getClipById(RendleyStore.audiogramStyles.audiogramAudioClipId)?.setCustomData("barWidth", Number(value.value));
    }

    const handleSetScalingFactor = (value: StyleConfiguration) => {
        RendleyStore.setAudiogramScalingFactor(Number(value.value));
        Engine.getInstance().getClipById(RendleyStore.audiogramStyles.audiogramAudioClipId)?.setCustomData("scalingFactor", Number(value.value));
    }

    const handleSetEnabled = async (value: boolean) => {
        RendleyStore.setAudiogramEnabled(value);
        if (value) {
            const durations = Engine.getInstance().getTimeline().getClips()?.map((clip) => {
                if (clip.getType() === ClipTypeEnum.AUDIO) {
                    return {duration: clip.getDuration(), clipId: clip.id};
                }
                return {duration: clip.getDuration(), clipId: null};
            });

            // Find the maxDuration clip and clipId
            let maxDuration = 0;
            let clipId = null;
            durations.forEach((duration) => {
                if (duration.duration > maxDuration) {
                    maxDuration = duration.duration;
                    clipId = duration.clipId;
                }
            });

            const layer = RendleyService.getEngine().getTimeline().createLayer();
            const clipOptions: WaveformClipOptions = {
                type: "waveform_clip",
                duration: maxDuration,
                clipId: clipId,
            };
            const audioGramClip = await layer.addClip(clipOptions);

            RendleyStore.setAudiogramClipId(audioGramClip.id);
            RendleyStore.setAudiogramAudioClipId(clipId);
            ApplicationStore.setSelectedClipId(audioGramClip.id);
            return;
        }
        RendleyService.deleteClipById(RendleyStore.audiogramStyles.audiogramClipId);
        RendleyStore.setAudiogramClipId(null);
    }

    return (
        <div style={{width: '100%', maxHeight: '600px', height: "600px", minWidth: "250px", paddingBottom: "40px"}}>

            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <Typography variant={'subtitle1'}
                            gutterBottom
                            sx={{ fontWeight: 650 }}
                            align={'center'}>
                    Enable Audiogram
                </Typography>
                <Box>
                    <ToggleButton
                        checked={RendleyStore.audiogramStyles.selectedEnabled}
                        onChange={(e) => handleSetEnabled(!RendleyStore.audiogramStyles.selectedEnabled)}
                    />
                </Box>
            </Box>
            <div className="spacer-2"></div>
            <PanelGridListSection
                title={'Color'}
                data={colors}
                columns={2}
                renderCard={(color: StyleConfiguration) => (
                    <EffectShowcaseCard
                        key={color.value}
                        isSelected={RendleyStore.audiogramStyles.selectedColor === color.value}
                        tooltip={color.name}
                        imageUrl={color.thumbnailUrl}
                        onMouseDown={() => handleSetColor(color)}
                    />
                )}
            />
            <div className="spacer-2"></div>
            <PanelGridListSection
                title={'Width'}
                data={widths}
                columns={2}
                renderCard={(width: StyleConfiguration) => (
                    <EffectShowcaseCard
                        key={width.value}
                        isSelected={RendleyStore.audiogramStyles.selectedBarWidth === Number(width.value)}
                        tooltip={width.name}
                        imageUrl={width.thumbnailUrl}
                        onMouseDown={() => handleSetBarWidth(width)}
                    />
                )}
            />
            <div className="spacer-2"></div>
            <PanelGridListSection
                title={'Scaling Factor'}
                data={scalingFactors}
                columns={2}
                renderCard={(factor: StyleConfiguration) => (
                    <EffectShowcaseCard
                        key={factor.value}
                        isSelected={
                            RendleyStore.audiogramStyles.selectedScalingFactor === Number(factor.value)
                        }
                        tooltip={factor.name}
                        imageUrl={factor.thumbnailUrl}
                        videoUrl={factor.videoPreviewUrl}
                        onMouseDown={() => handleSetScalingFactor(factor)}
                    />
                )}
            />
            <div className="spacer-2"></div>
        </div>
    );
});

export default AudiogramStylesContainer;
