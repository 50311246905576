import Box from "@mui/material/Box";
import * as React from "react";
import {TrackToggle, useTrackToggle} from "@livekit/components-react";
import {Track} from "livekit-client";
import {NewMediaDeviceMenu} from "./NewMediaDeviceMenu";
import {useEffect, useState} from "react";
import {collection, doc, onSnapshot, query, updateDoc, where} from "firebase/firestore";
import {db} from "../../Firebase";
import {kRoomStateCollectionName, RoomName} from "../../utils/CollectionConstants";
import {DropdownArrowIcon} from "./SettingsIcon";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {BackgroundMenu} from "./BackgroundMenu";
import {Typography} from "@mui/material";
import {useCustomTrackToggle} from "./useCustomTrackToggle";
import {MusicVideo} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";

const RecordVideoShowVideo = 0;
const ShowVideoRecordAudio = 1;
const NoVideoRecordAudio = 2;

const textStyle = {
    fontFamily: "Inter",
    fontSize: {md: "9px", lg: "11px", xl: "14px"},
    fontStyle: "normal",
    fontWeight: {md: "350", lg: "400", xl: "500"},
    lineHeight: {md: "13px", lg: "15px", xl: "20px"},
    letterSpacing: {md: "0.1px", lg: "0.1px", xl: "0.14px"},
    color: "#1A1A1A",
    // disable highlight on double click
    WebkitTouchCallout: "none",
    WebkitUserSelect: "none",
    KhtmlUserSelect: "none",
    MozUserSelect: "none",
    msUserSelect: "none",
}

const dropdownArrowStyle = {
    display: "flex",
    padding: {md: "7px 8px", lg: "11px 9px", xl: "14px 12px"},
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    backgroundColor: "#e8e8e8",
    width: {md: "32px", lg: "36px", xl: "48px"},
    height: {md: "34px", lg: "39px", xl: "52px"},
    alignSelf: "stretch",
    cursor: "pointer",
    // 4px border radius for the top right and bottom right corners
    borderRadius: "0 5px 5px 0",
}

const MicrophoneIcon = ({color = "#1A1A1A"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 21 20" fill="none">
            <path
                d="M10.5 0.833313C9.83696 0.833313 9.20107 1.09671 8.73223 1.56555C8.26339 2.03439 8 2.67027 8 3.33331V9.99998C8 10.663 8.26339 11.2989 8.73223 11.7677C9.20107 12.2366 9.83696 12.5 10.5 12.5C11.163 12.5 11.7989 12.2366 12.2678 11.7677C12.7366 11.2989 13 10.663 13 9.99998V3.33331C13 2.67027 12.7366 2.03439 12.2678 1.56555C11.7989 1.09671 11.163 0.833313 10.5 0.833313V0.833313Z"
                stroke={color} strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M16.3332 8.33331V9.99998C16.3332 11.5471 15.7186 13.0308 14.6246 14.1248C13.5307 15.2187 12.0469 15.8333 10.4998 15.8333C8.95274 15.8333 7.46901 15.2187 6.37505 14.1248C5.28109 13.0308 4.6665 11.5471 4.6665 9.99998V8.33331"
                stroke={color} strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.5 15.8333V19.1666" stroke={color} strokeWidth="1.5" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M7.1665 19.1667H13.8332" stroke={color} strokeWidth="1.5" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </svg>
    )
}

const MicDisabledIcon = ({color = "#1A1A1A"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 21 20" fill="none">
            <path
                d="M10.5 0.833313C9.83696 0.833313 9.20107 1.09671 8.73223 1.56555C8.26339 2.03439 8 2.67027 8 3.33331V9.99998C8 10.663 8.26339 11.2989 8.73223 11.7677C9.20107 12.2366 9.83696 12.5 10.5 12.5C11.163 12.5 11.7989 12.2366 12.2678 11.7677C12.7366 11.2989 13 10.663 13 9.99998V3.33331C13 2.67027 12.7366 2.03439 12.2678 1.56555C11.7989 1.09671 11.163 0.833313 10.5 0.833313V0.833313Z"
                stroke={color} strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M16.3332 8.33331V9.99998C16.3332 11.5471 15.7186 13.0308 14.6246 14.1248C13.5307 15.2187 12.0469 15.8333 10.4998 15.8333C8.95274 15.8333 7.46901 15.2187 6.37505 14.1248C5.28109 13.0308 4.6665 11.5471 4.6665 9.99998V8.33331"
                stroke={color} strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.5 15.8333V19.1666" stroke={color} strokeWidth="1.5" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M7.1665 19.1667H13.8332" stroke={color} strokeWidth="1.5" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M3 3L18 18" stroke={color} strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
}

const CameraIcon = ({color = "#1A1A1A"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 21 20" fill="none">
            <path
                d="M4.25 3.75C2.86929 3.75 1.75 4.86929 1.75 6.25V13.75C1.75 15.1307 2.86929 16.25 4.25 16.25H11.125C12.5057 16.25 13.625 15.1307 13.625 13.75V6.25C13.625 4.86929 12.5057 3.75 11.125 3.75H4.25Z"
                stroke={color} strokeWidth="1.5"/>
            <path
                d="M16.1161 15.625L13.875 13.3839V6.61609L16.1161 4.37497C16.9036 3.58751 18.25 4.14523 18.25 5.25885V14.7411C18.25 15.8547 16.9036 16.4124 16.1161 15.625Z"
                stroke={color} strokeWidth="1.5"/>
        </svg>
    )
}

const CamDisabledIcon = ({color = "#1A1A1A"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 21 20" fill="none">
            <path
                d="M4.25 3.75C2.86929 3.75 1.75 4.86929 1.75 6.25V13.75C1.75 15.1307 2.86929 16.25 4.25 16.25H11.125C12.5057 16.25 13.625 15.1307 13.625 13.75V6.25C13.625 4.86929 12.5057 3.75 11.125 3.75H4.25Z"
                stroke={color} strokeWidth="1.5"/>
            <path
                d="M16.1161 15.625L13.875 13.3839V6.61609L16.1161 4.37497C16.9036 3.58751 18.25 4.14523 18.25 5.25885V14.7411C18.25 15.8547 16.9036 16.4124 16.1161 15.625Z"
                stroke={color} strokeWidth="1.5"/>
            <path d="M3 3L18 18" stroke="black" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

const ShareScreenIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
            <path d="M6.5 9L6.5 3" stroke="#1A1A1A" strokeWidth="1.28571" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M3.5 6L6.5 3L9.5 6" stroke="#1A1A1A" strokeWidth="1.28571" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </svg>
    );
};

const ActiveShareScreenIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
            <path d="M6.5 9L6.5 3" stroke="#2B6BFD" strokeWidth="1.28571" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M3.5 6L6.5 3L9.5 6" stroke="#2B6BFD" strokeWidth="1.28571" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </svg>
    )
}

export const RecordingModeButton = ({roomName}) => {

    const buttonRef = React.useRef(null);

    const [recordingModeAudio, setRecordingModeAudio] = useState(false);

    const IsRecordingModeAudio = (recordingType) => {
        return recordingType === 1 || recordingType === 2;
    }

    useEffect(() => {
        if (roomName === '') {
            return;
        }

        const q = query(collection(db, kRoomStateCollectionName), where(RoomName, "==", roomName));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const newRoomState = [];
            querySnapshot.forEach((doc) => {
                newRoomState.push(doc.data());
            });
            if (newRoomState.length > 0) {
                setRecordingModeAudio(IsRecordingModeAudio(newRoomState[0].recordingType));
            }
        });

        return () => unsubscribe();
    }, [roomName, setRecordingModeAudio]);

    const updateRecordingMode = async (audioRecording) => {
        if (roomName === '') {
            return;
        }

        const recordingType = audioRecording ? NoVideoRecordAudio : RecordVideoShowVideo;

        const entityRef = doc(db, kRoomStateCollectionName, roomName);
        try {
            await updateDoc(entityRef, {recordingType: recordingType});
        } catch (error) {
            console.error('Error updating recording type for room: ', error);
        }
    }

    const buttonStyle = {
        display: "flex",
        padding: {md: "8px 11px", lg: "12px 18px", xl: "16px 24px"},
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        cursor: "pointer",
        backgroundColor: "#f3f4f5",
        width: {md: "115px", lg: "179px", xl: "179px"},
        height: {md: "34px", lg: "39px", xl: "52px"},
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: '#e5e5e5', // Darker primary color
        },
        borderRadius: "5px 0 0 5px",
    }

    return (
        <Box flexDirection="row" display="flex">
            <Box onClick={() => {
            }} sx={buttonStyle} alignItems="center" justifyContent="center" flexDirection="row">
                {recordingModeAudio ? <MicrophoneIcon/> : <CameraIcon/>}
                <div sx={textStyle}>
                    {recordingModeAudio ? "Audio-only" : "Video"}
                </div>
            </Box>
            <Box sx={dropdownArrowStyle} ref={buttonRef}>
                <DropdownArrowIcon/>
            </Box>
        </Box>
    )

}

export const CameraButton = ({onChange, saveVideoInputDevice, room}) => {
    const {buttonProps, enabled} = useCustomTrackToggle({
        source: Track.Source.Camera,
        onChange: onChange,
        maybeRoom: room
    });

    const handleClick = () => {
        buttonProps.onClick();
    }

    const buttonStyle = {
        display: "flex",
        padding: {md: "8px 11px", lg: "10px 12px", xl: "12px 18px"},
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        cursor: "pointer",
        backgroundColor: "#f3f4f5",
        width: {md: "106px", lg: "119px", xl: "159px"},
        height: {md: "34px", lg: "39px", xl: "45px"},
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: '#e5e5e5', // Darker primary color
        },
        borderRadius: "5px 0 0 5px",
    }

    const theme = useTheme();

    const isLg = useMediaQuery(theme.breakpoints.up("lg"));
    const isXl = useMediaQuery(theme.breakpoints.up("xl"));

    const width = isXl ? 159 : isLg ? 119 : 106;

    return (
        <Box flexDirection="row" display="flex">
            <Box onClick={handleClick} sx={buttonStyle} alignItems="center" justifyContent="center" flexDirection="row">
                {enabled ? <CameraIcon/> : <CamDisabledIcon/>}
                <div sx={textStyle}>Camera</div>
            </Box>
            <NewMediaDeviceMenu kind="videoinput" room={room}
                                onActiveDeviceChange={(_kind, deviceId) => saveVideoInputDevice(deviceId ?? '')}
                                width={width}/>
        </Box>
    )
}

export const NewCameraButton = ({onChange, saveVideoInputDevice, room}) => {
    const {buttonProps, enabled} = useCustomTrackToggle({
        source: Track.Source.Camera,
        onChange: onChange,
        maybeRoom: room
    });

    const handleClick = () => {
        buttonProps.onClick();
    }

    const buttonStyle = {
        display: "flex",
        padding: {md: "4px 4px"},
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        cursor: "pointer",
        borderRadius: "5px 0 0 5px",
        border: "1px solid #D5D5D5",
        backgroundColor: "#f3f4f5",
        width: "40px",
        height: "40px",
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: '#e5e5e5', // Darker primary color
        },
    }

    const theme = useTheme();

    const isLg = useMediaQuery(theme.breakpoints.up("lg"));
    const isXl = useMediaQuery(theme.breakpoints.up("xl"));

    const width = isXl ? 159 : isLg ? 119 : 106;

    return (
        <Box flexDirection="row" display="flex">
            <Tooltip title={enabled ? "Disable camera" : "Enable camera"} placement={"bottom"}>
                <Box onClick={handleClick} sx={buttonStyle} alignItems="center" justifyContent="center"
                     flexDirection="row">
                    {enabled ? <CameraIcon/> : <CamDisabledIcon/>}
                </Box>
            </Tooltip>
            <NewMediaDeviceMenu kind="videoinput" room={room}
                                onActiveDeviceChange={(_kind, deviceId) => saveVideoInputDevice(deviceId ?? '')}
                                width={width} isPrejoin={false}/>
        </Box>
    )
}

export const BackgroundButton = ({
                                     setBackgroundImage, backgroundImageIndex,
                                     setBackgroundImageIndex
                                 }) => {

    return (
        <Box flexDirection="row" display="flex">
            <BackgroundMenu setBackgroundImage={setBackgroundImage} initialSelection="none" onBackgroundChange={() => {
            }} backgroundImageIndex={backgroundImageIndex} setBackgroundImageIndex={setBackgroundImageIndex}/>
        </Box>
    )
}

export const ShareScreenButton = ({onChange, disabled, room}) => {
    const {buttonProps, enabled} = useCustomTrackToggle({
        source: Track.Source.ScreenShare,
        onChange: onChange,
        maybeRoom: room
    });

    const handleClick = () => {
        buttonProps.onClick();
    }

    const buttonStyle = {
        display: "flex",
        padding: {md: "11px 16px", lg: "12px 18px", xl: "12px 18px"},
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        cursor: "pointer",
        backgroundColor: "#f3f4f5",
        width: {md: "112px", lg: "179px", xl: "179px"},
        height: {md: "34px", lg: "39px", xl: "45px"},
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: '#e5e5e5', // Darker primary color
        },
        // 5px border radius on the top left and bottom left corners
        borderRadius: "5px 5px 5px 5px",
    }

    const enabledButtonStyle = {
        ...buttonStyle,
        backgroundColor: "#e5e5e5",
        border: "1.5px solid #2B6BFD",
    }

    const effectiveStyle = enabled ? enabledButtonStyle : buttonStyle;

    const shareScreenBoxStyle = {
        width: "20px",
        height: "15px",
        padding: "1px",
        flexShrink: 0,
        borderRadius: "3px",
        border: "1.5px solid #1A1A1A",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }

    const theme = useTheme();

    const isLg = useMediaQuery(theme.breakpoints.up("lg"));

    const buttonText = (enabled) => {
        if (enabled) {
            return isLg ? <div sx={textStyle}>Stop sharing</div> : <div sx={textStyle}>Stop</div>;
        }
        return isLg ? <div sx={textStyle}>Share screen</div> : <div sx={textStyle}>Screen</div>;
    }

    return (
        <Box flexDirection="row" display="flex">
            <Box onClick={handleClick} sx={effectiveStyle} alignItems="center" justifyContent="center"
                 flexDirection="row">
                <div style={shareScreenBoxStyle}>
                    {<ShareScreenIcon/>}
                </div>
                {buttonText(enabled)}
            </Box>
        </Box>
    )
}

export const NewShareScreenButton = ({onChange, disabled, room}) => {
    const {buttonProps, enabled} = useCustomTrackToggle({
        source: Track.Source.ScreenShare,
        onChange: onChange,
        maybeRoom: room
    });

    const handleClick = () => {
        buttonProps.onClick();
    }

    const buttonStyle = {
        display: "flex",
        padding: {md: "11px 16px", lg: "12px 18px", xl: "12px 18px"},
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "5px",
        border: "1px solid #D5D5D5",
        cursor: "pointer",
        backgroundColor: "#f3f4f5",
        width: "40px",
        height: "40px",
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: '#e5e5e5', // Darker primary color
        },
        // 5px border radius on the top left and bottom left corners
    }

    const enabledButtonStyle = {
        ...buttonStyle,
        backgroundColor: "#e5e5e5",
        border: "1.5px solid #2B6BFD",
    }

    const effectiveStyle = enabled ? enabledButtonStyle : buttonStyle;

    const shareScreenBoxStyle = {
        width: "20px",
        height: "15px",
        padding: "1px",
        flexShrink: 0,
        borderRadius: "3px",
        border: "1.5px solid #1A1A1A",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }


    return (
        <Tooltip title={"Share screen"} placement={"bottom"}>
            <Box flexDirection="row" display="flex">
                <Box onClick={handleClick} sx={effectiveStyle} alignItems="center" justifyContent="center"
                     flexDirection="row">
                    <div style={shareScreenBoxStyle}>
                        {<ShareScreenIcon/>}
                    </div>
                </Box>
            </Box>
        </Tooltip>
    )
}

export const MediaButton = ({onClick, mediaBoardOpen}) => {
    const buttonStyle = {
        display: "flex",
        padding: "12px 18px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        cursor: "pointer",
        width: "145px",
        height: "40px",
        transition: 'background-color 0.3s',
        backgroundColor: "#2B6BFD",
        '&:hover': {
            background: "linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), #2B6BFD", // Darker primary color
        },
        // 5px border radius on the top left and bottom left corners
        borderRadius: "8px",
    }

    const enabledButtonStyle = {
        ...buttonStyle,
        backgroundColor: "#2B6BFD",
        border: "1.5px solid #2B6BFD",
    }

    const mediaBoxStyle = {
        width: "20px",
        height: "15px",
        padding: "1px",
        flexShrink: 0,
        borderRadius: "3px",
        border: "1.5px solid #1A1A1A",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }

    const effectiveStyle = mediaBoardOpen ? enabledButtonStyle : buttonStyle;

    const theme = useTheme();

    const isLg = useMediaQuery(theme.breakpoints.up("lg"));

    const mediaButtonTextStyle = {
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.14px",
        color: "#ffffff",
    }

    return (
        <Box flexDirection="row" display="flex">
            <Box onClick={onClick} sx={effectiveStyle} alignItems="center" justifyContent="center" flexDirection="row">
                <div style={mediaBoxStyle}>
                    {<MusicVideo sx={{color: "#ffffff"}}/>}
                </div>
                <div style={mediaButtonTextStyle}>Mediaboard</div>
            </Box>
        </Box>
    )

}

export const PrejoinMicrophoneButton = ({
                                            onChange,
                                            audioDeviceId,
                                            audioEnabled,
                                            audioTrack,
                                            onActiveDeviceChange,
                                            toggleRef
                                        }) => {
    const {buttonProps, enabled} = useTrackToggle({source: Track.Source.Microphone, onChange: onChange});

    const handleClick = () => {
        toggleRef.current.click();
        buttonProps.onClick();
    }

    const buttonStyle = {
        display: "flex",
        padding: "16px 24px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        cursor: "pointer",
        background: "#E3E5E8",
        '&:hover': {
            background: "linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #E3E5E8",
        },
        backdropFilter: "blur(12.5px)",
        width: "215px",
        height: "52px",
        transition: 'background-color 0.3s',
    }

    return (
        <Box flexDirection="row" display="flex">
            <Box onClick={handleClick} sx={buttonStyle} alignItems="center" justifyContent="center" flexDirection="row">
                {audioEnabled ? <MicrophoneIcon color={"#1A1A1A"}/> : <MicDisabledIcon color={"#1A1A1A"}/>}
                <Typography color={"#1A1A1A"}>Microphone</Typography>
            </Box>
            <NewMediaDeviceMenu kind="audioinput" room={null} initialSelection={audioDeviceId}
                                tracks={{audioinput: audioTrack}} disabled={!audioTrack} isPrejoin={true}
                                onActiveDeviceChange={onActiveDeviceChange}/>
        </Box>
    )
}

export const PrejoinCameraButton = ({
                                        onChange,
                                        videoDeviceId,
                                        videoEnabled,
                                        videoTrack,
                                        onActiveDeviceChange,
                                        toggleRef
                                    }) => {
    const {buttonProps, enabled} = useTrackToggle({
        source: Track.Source.Camera,
        initialState: videoEnabled,
        onChange: onChange
    });

    const handleClick = () => {
        // Click html button ref toggleRef
        toggleRef.current.click();
        buttonProps.onClick();
    }

    const buttonStyle = {
        display: "flex",
        padding: "16px 24px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        cursor: "pointer",
        background: "#E3E5E8",
        '&:hover': {
            background: "linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #E3E5E8",
        },
        // backdropFilter: "blur(12.5px)",
        width: "215px",
        height: "52px",
        transition: 'background-color 0.3s',
    }

    return (
        <Box flexDirection="row" display="flex">
            <Box onClick={handleClick} sx={buttonStyle} alignItems="center" justifyContent="center" flexDirection="row">
                {enabled ? <CameraIcon color={"#1A1A1A"}/> : <CamDisabledIcon color={"#1A1A1A"}/>}
                <Typography color={"#1a1a1a"}>Camera</Typography>
            </Box>
            <NewMediaDeviceMenu kind="videoinput" room={null} initialSelection={videoDeviceId}
                                tracks={{videoinput: videoTrack}} disabled={!videoTrack} isPrejoin={true}
                                onActiveDeviceChange={onActiveDeviceChange}/>
        </Box>
    )
}


export const MicrophoneButton = ({onChange, saveAudioInputDevice, room}) => {

    const {buttonProps, enabled} = useCustomTrackToggle({
        source: Track.Source.Microphone,
        onChange: onChange,
        maybeRoom: room
    });

    const handleClick = () => {
        buttonProps.onClick();
    }

    const buttonStyle = {
        display: "flex",
        padding: {md: "10px 14px", lg: "12px 18px", xl: "12px 18px"},
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        cursor: "pointer",
        backgroundColor: "#f3f4f5",
        width: {md: "145px", lg: "157px", xl: "179px"},
        height: {md: "34px", lg: "39px", xl: "45px"},
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: '#e5e5e5', // Darker primary color
        },
        // 5px border radius on the top left and bottom left corners
        borderRadius: "5px 0 0 5px",
    }

    const theme = useTheme();

    const isLg = useMediaQuery(theme.breakpoints.up("lg"));
    const isXl = useMediaQuery(theme.breakpoints.up("xl"));

    const width = isXl ? 179 : isLg ? 157 : 145;

    return (
        <Box flexDirection="row" display="flex">
            <Box onClick={handleClick} sx={buttonStyle} alignItems="center" justifyContent="center" flexDirection="row">
                {enabled ? <MicrophoneIcon/> : <MicDisabledIcon/>}
                <div sx={textStyle}>Microphone</div>
            </Box>
            <NewMediaDeviceMenu kind="audioinput" room={room}
                                onActiveDeviceChange={(_kind, deviceId) => saveAudioInputDevice(deviceId ?? '')}
                                width={width}/>
        </Box>
    )
}

export const NewMicrophoneButton = ({onChange, saveAudioInputDevice, room}) => {

    const {buttonProps, enabled} = useCustomTrackToggle({
        source: Track.Source.Microphone,
        onChange: onChange,
        maybeRoom: room
    });

    const handleClick = () => {
        buttonProps.onClick();
    }

    const buttonStyle = {
        display: "flex",
        padding: {md: "4px 4px"},
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #D5D5D5",
        gap: "10px",
        cursor: "pointer",
        backgroundColor: "#f3f4f5",
        width: "40px", // "145px",
        height: "40px",
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: '#e5e5e5', // Darker primary color
        },
        // 5px border radius on the top left and bottom left corners
        borderRadius: "5px 0 0 5px",
    }

    const theme = useTheme();

    const isLg = useMediaQuery(theme.breakpoints.up("lg"));
    const isXl = useMediaQuery(theme.breakpoints.up("xl"));

    const width = isXl ? 179 : isLg ? 157 : 145;

    return (
        <Box flexDirection="row" display="flex">
            <Tooltip title={enabled ? "Mute" : "Unmute"} placement={"bottom"}>
                <Box onClick={handleClick} sx={buttonStyle} alignItems="center" justifyContent="center"
                     flexDirection="row">
                    {enabled ? <MicrophoneIcon/> : <MicDisabledIcon/>}
                </Box>
            </Tooltip>
            <NewMediaDeviceMenu kind="audioinput" room={room}
                                onActiveDeviceChange={(_kind, deviceId) => saveAudioInputDevice(deviceId ?? '')}
                                width={width} isPrejoin={false}/>
        </Box>
    )
}
