import Box from "@mui/material/Box";
import ProjectThumbnailCard, {ListViewThumbnailCard} from "./ProjectThumbnail";
import {
    formatDuration, formatSampleRate, FormatTimeRange,
    formatToReadableLargeTitle,
    formatUnixTime, FormatUnixTimeForSchedule, handleDownloadButtonClick,
    IsVideoAsset,
    IsVideoTrackType, RefreshTokenAndRetry
} from "../../utils/utils";
import StatusChip from "../../components/StatusChip";
import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, {useContext} from "react";
import Grid from "@mui/material/Grid";
import DownloadIcon from "@mui/icons-material/Download";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {Typography} from "@mui/material";
import dayjs from "dayjs";
import GoogleCalendarIcon from "../../assets/GCalIcon";
import {JoinSessionButton} from "./HomeButtons";
import {MiniTrashButton, SessionTrashButton} from "../../components/CoolButtons";
import {useConfirmDialog} from "./DeleteConfirmation";
import {AuthContext} from "../../context/AuthContext";
import {FetchContext} from "../../context/FetchContext";

const boxStyle = (isMobile) => {
    return {
        display: 'flex',
        height: '125px',
        padding: isMobile ? '8px 10px' : '12px 22px',
        alignItems: 'center',
        alignSelf: 'stretch',
        borderBottom: '1px solid #E8E8E8',
        background: '#FFF',
        // Hover style
        '&:hover': {
            background: 'rgba(43, 107, 253, 0.05);',
            cursor: 'pointer',
        }
    }
}

const scheduledRecordingboxStyle = (isMobile) => {
    return {
        display: 'flex',
        height: '125px',
        padding: isMobile ? '8px 10px' : '12px 22px',
        alignItems: 'center',
        alignSelf: 'stretch',
        borderBottom: '1px solid #E8E8E8',
        background: '#FFF',
        // Hover style
        '&:hover': {
            background: 'rgba(43, 107, 253, 0.05);',
        }
    }
}

const thumbnailAreaStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '32px',
    flex: '1 1 50%',
    maxWidth: '50%'
}

const thumbnailContainerStyle = {
    width: '124px',
    height: '70px',
    borderRadius: '12px',
    flexShrink: 0,
}

const titleDateBoxStyle = (isMobile) => {
    return {
        display: isMobile ? "none" : "flex",
        flexDirection: "column",
        gap: "6px",
        overflow: "hidden",
    }
}

const titleStyle = {
    color: "#1a1a1a",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "0.16px",
    alignSelf: "strech",
}

const dateStyle = {
    color: "#808080",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "0.14px",
}

const statusAndArrowAreaStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: '50%',
    flex: '1 1 50%',
}

const textStyle = {
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0.14px",
}

const buttonTextStyle = {
    ...textStyle,
    color: "#1A1A1A",
}

const iconContainerStyle = {
    display: "flex",
    width: "20px",
    height: "20px",
    padding: "2px",
    justifyContent: "center",
    alignItems: "center",
}

// Format time range for a schedule, considering timezone

export const ListViewRecording = ({recording, onClick, isMobile, isSharedView}) => {

    const [hovered, setHovered] = React.useState(false);

    const {openDialog, ConfirmDialogComponent} = useConfirmDialog({confirmText: 'Delete', cancelText: 'Cancel'});

    const navigate = useNavigate();

    const auth = useContext(AuthContext);
    const fetchContext = useContext(FetchContext);

    const deleteAsset = async (assetId) => {
        fetchContext.authAxios.delete(`/assets/${assetId}`, {
            headers: {
                Authorization: `Bearer ${auth.getToken()}`,
            }
        }).then(() => {

        }).catch((err) => {
            if (err.response.status === 401) {
                RefreshTokenAndRetry(err, auth, fetchContext).then(() => {

                }).catch(() => {
                    auth.logout();
                })
            }
        })
    };

    const handleDeleteScheduledAsset = (assetId) => {
        openDialog({
            title: 'Delete Scheduled session',
            message: 'This scheduled session permanently deleted.',
            onConfirm: async () => {
                await deleteAsset(assetId);
            },
        });
    };

    if (recording.assetType === "SCHEDULED_RECORDING") {

        if (isSharedView) {
            return null;
        }

        const handleAddToGoogleCalendar = (e) => {
            e.preventDefault();
            e.stopPropagation();
            const calendarLink = recording.scheduledCalendarLink;

            if (calendarLink) {
                window.open(calendarLink, "_blank");
            }
        }

        const navigateToStudio = () => {
            navigate(`/studio/${recording.projectId}/${recording.title}/${recording.assetId}`)
        }

        return (
            <>
                <ConfirmDialogComponent/>
                <Box sx={scheduledRecordingboxStyle(isMobile)} onMouseEnter={() => setHovered(true)}
                     onMouseLeave={() => setHovered(false)} onClick={() => {
                }} key={recording.assetId}>
                    <Box sx={thumbnailAreaStyle}>
                        <Box sx={thumbnailContainerStyle}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    minWidth: "80px",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        color: "#808080",
                                        fontWeight: 400,
                                    }}
                                >
                                    {FormatUnixTimeForSchedule(recording.scheduledStartTime, recording.scheduledTimezone)}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "28px",
                                        fontWeight: 700,
                                        color: "#000000",
                                        lineHeight: "32px",
                                    }}
                                >
                                    {dayjs.unix(recording.scheduledStartTime).format("DD")}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        color: "#808080",
                                        fontWeight: 400,
                                    }}
                                >
                                    {dayjs.unix(recording.scheduledStartTime).format("MMM").toUpperCase()}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={titleDateBoxStyle(isMobile)}>
                            <Box sx={titleStyle}>
                                {formatToReadableLargeTitle(recording.title)}
                            </Box>
                            <Box sx={dateStyle}>
                                {FormatTimeRange(recording.scheduledStartTime, recording.scheduledEndTime, recording.scheduledTimezone)} ({recording.scheduledTimezone})
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={statusAndArrowAreaStyle}>
                        <Box sx={{display: hovered && !isSharedView ? "none" : "flex"}}>
                            <StatusChip status={"Scheduled"} progress={recording.uploadProgress}/>
                        </Box>
                        <Box sx={{
                            height: "40px",
                            width: "200px",
                            flexShrink: 0,
                            alignItems: "center",
                            display: isMobile || !hovered || isSharedView ? "none" : "flex",
                            justifyContent: "space-between",
                        }}>
                            <JoinSessionButton onClick={navigateToStudio}/>
                            <Tooltip title={"Add to Google Calendar"}>
                                <Box sx={{cursor: "pointer"}} onClick={handleAddToGoogleCalendar}>
                                    <GoogleCalendarIcon/>
                                </Box>
                            </Tooltip>
                        </Box>
                        <Box sx={{height: "40px", alignItems: "center", display: isMobile || !hovered || isSharedView ? "none" : "flex",}}>
                            <SessionTrashButton disabled={isSharedView} handleClick={() => {
                                handleDeleteScheduledAsset(recording.assetId)
                            }}/>
                        </Box>
                    </Box>
                </Box>
            </>
        )
    }

    return (
        <Box sx={boxStyle(isMobile)} onClick={() => onClick(recording.assetId)} key={recording.assetId}>
            <Box sx={thumbnailAreaStyle}>
                <Box sx={thumbnailContainerStyle}>
                    <ListViewThumbnailCard thumbnailUrl={recording.thumbnailUrl} title={recording.title}
                                           status={recording.status} isVideo={IsVideoAsset(recording)}
                                           onClick={() => onClick(recording.assetId)}/>
                </Box>
                <Box sx={titleDateBoxStyle(isMobile)}>
                    <Box sx={titleStyle}>
                        {formatToReadableLargeTitle(recording.title)}
                    </Box>
                    <Box sx={dateStyle}>
                        {formatUnixTime(recording.createTime)}
                    </Box>
                </Box>
            </Box>
            <Box sx={statusAndArrowAreaStyle}>
                <Box sx={{}}>
                    <StatusChip status={recording.status} progress={recording.uploadProgress}/>
                </Box>
                <Box sx={{width: "20px", height: "20px", flexShrink: 0, display: isMobile ? "none" : "flex"}}
                     onClick={() => onClick(recording.assetId)}>
                    <ForwardArrowIcon/>
                </Box>
            </Box>
        </Box>
    )
}

const styles = {
    boxStyle: {
        display: 'flex',
        height: '110px',
        padding: '12px 22px',
        alignItems: 'center',
        alignSelf: 'stretch',
        justifyContent: "space-between",
        borderBottom: '1px solid #E8E8E8',
        background: '#FFF',
        '&:hover': {
            background: 'rgba(43, 107, 253, 0.05)',
            cursor: 'pointer',
        }
    },
    scheduledRecordingBoxStyle: {
        display: 'flex',
        height: '110px',
        padding: '12px 22px',
        alignItems: 'center',
        alignSelf: 'stretch',
        justifyContent: "space-between",
        borderBottom: '1px solid #E8E8E8',
        background: '#FFF',
        '&:hover': {
            background: 'rgba(43, 107, 253, 0.05)',
        }
    },
    selectedBoxStyle: {
        display: 'flex',
        height: '110px',
        padding: '12px 22px',
        alignItems: 'center',
        alignSelf: 'stretch',
        justifyContent: "space-between",
        borderBottom: '1px solid #E8E8E8',
        border: '1px solid #2B6BFD',
        background: '#FFF',
        '&:hover': {
            background: 'rgba(43, 107, 253, 0.05)',
            cursor: 'pointer',
        }
    },
    thumbnailContainerStyle: {
        width: '130px',
        height: '73px',
        borderRadius: '12px',
        flexShrink: 0,
        marginRight: '32px',
    },
    titleStyle: {
        color: "#1a1a1a",
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: "20px",
        letterSpacing: "0.16px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        flex: 1,
        marginRight: '16px',
    },
    infoStyle: {
        color: "#1a1a1a",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        fontStyle: "normal",
        lineHeight: "20px",
        letterSpacing: "0.14px",
        marginRight: '16px',
    },
    placeholderStyle: {
        width: '24px',
        height: '24px',
        marginLeft: '16px',
    }
};

const downloadButtonStyle = (enabled) => {

    const boxStyle = {
        display: "flex",
        height: "40px",
        minWidth: "140px",
        padding: "10px 20px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "6px",
        // Focus state
    }

    const enabledStyle = {
        ...boxStyle,
        "&:hover": {
            background: "linear-gradient(0deg, rgba(0, 0, 0, 0.10)0%, rgba(0, 0, 0, 0.10)100%), #E3E5E8",
        },
        "&:focus": {
            background: "linear-gradient(0deg, rgba(255, 255, 255, 0.10)0%, rgba(255, 255, 255, 0.10)100%), #E3E5E8",
        },
        background: "#E3E5E8",
        cursor: "pointer",
    }

    if (enabled) {
        return enabledStyle;
    }

    const disabledStyle = {
        ...boxStyle,
        background: "linear-gradient(0deg, rgba(255, 255, 255, 0.10)0%, rgba(255, 255, 255, 0.10)100%), #E3E5E8",
        opacity: 0.3,
        cursor: "none",
    }

    return disabledStyle;
}

const MyDeleteIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
            <path
                d="M4.77762 7.80085L4.58339 7.81939C4.58259 7.78855 4.58813 7.75787 4.59969 7.72917C4.61126 7.70047 4.6286 7.67434 4.65069 7.65234C4.67277 7.63033 4.69916 7.61289 4.72827 7.60107C4.75739 7.58924 4.78863 7.58327 4.82015 7.5835L4.77762 7.80085ZM4.77762 7.80085L4.79682 7.79901L4.80823 7.79792L4.81857 7.79694L4.82015 7.79679L5.32999 7.7481L5.38589 8.3335M4.77762 7.80085L4.8146 8.3335H4.82015H5.38589M5.38589 8.3335H14.6152L13.8184 16.6668L13.8183 16.6668L13.8179 16.6715C13.7934 16.9455 13.6618 17.2035 13.4449 17.3903C13.2276 17.5774 12.9419 17.6776 12.6469 17.6659C12.637 17.6655 12.6271 17.6653 12.6172 17.6653H7.38283C7.37541 17.6653 7.36799 17.6654 7.36058 17.6656C7.06441 17.6744 6.7784 17.5725 6.56015 17.3846L6.07944 17.9429L6.56015 17.3846C6.34275 17.1974 6.20928 16.9397 6.18149 16.6651C6.18144 16.6646 6.18139 16.6642 6.18134 16.6637L5.38589 8.3335Z"
                stroke="#ED0C32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.75 11.3335V14.6668" stroke="#ED0C32" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M11.25 11.3335V14.6668" stroke="#ED0C32" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M3.75 5.5H16.25" stroke="#ED0C32" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path
                d="M7 4.1665C7 3.47615 7.55964 2.9165 8.25 2.9165H11.75C12.4404 2.9165 13 3.47615 13 4.1665V5.37484H7V4.1665Z"
                stroke="#ED0C32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

const DeleteTrackButton = ({handleClick, disabled}) => {

    return (
        <Tooltip title="Delete Track">
            <IconButton onClick={handleClick} sx={{
                color: "#ED0C32", // Set color from theme
                fontSize: '2rem', // Increase the font size
                // You can also adjust the size of the icon itself
                '& .MuiSvgIcon-root': {fontSize: '1.5rem'}
            }} disabled={disabled}>
                <MyDeleteIcon/>
            </IconButton>
        </Tooltip>
    );
};

const RenameIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
            <path
                d="M13.7611 4.82646C14.0161 4.72086 14.2893 4.6665 14.5653 4.6665C14.8412 4.6665 15.1145 4.72086 15.3694 4.82646C15.6244 4.93207 15.856 5.08685 16.0512 5.28198C16.2463 5.47711 16.4011 5.70877 16.5067 5.96372C16.6123 6.21867 16.6667 6.49192 16.6667 6.76788C16.6667 7.04383 16.6123 7.31709 16.5067 7.57204C16.4011 7.82699 16.2463 8.05865 16.0512 8.25378L8.02972 16.2752L4.16666 17.1665L5.05793 13.3034L13.0794 5.28198C13.2745 5.08685 13.5062 4.93207 13.7611 4.82646Z"
                stroke="#1A1A1A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.6667 7.1665L14.1667 9.6665" stroke="#1A1A1A" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
    )
}

const CaptionsIcon = ({disabled}) => {

    const color = disabled ? "#d0d0d0" : "#1A1A1A";

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect x="4.75" y="4.75" width="14.5" height="14.5" rx="2.25" stroke={color} stroke-width="1.5"/>
            <path d="M7 13.25H17" stroke={color} stroke-width="1.5"/>
            <line x1="8" y1="16.25" x2="16" y2="16.25" stroke={color} stroke-width="1.5"/>
        </svg>
    )
}

const DownloadTranscriptButton = ({handleClick, disabled}) => {

    const onClick = () => {
        if (disabled) {
            return;
        }

        handleClick();
    }


    return (
        <Tooltip title="Download Transcript">
            <IconButton onClick={onClick} sx={{
                color: disabled ? "#d0d0d0" : "#1a1a1a", // Set color from theme
                fontSize: '2rem', // Increase the font size
                // You can also adjust the size of the icon itself
                '& .MuiSvgIcon-root': {fontSize: '1.5rem'}
            }} disabled={disabled}>
                <CaptionsIcon disabled={disabled}/>
            </IconButton>
        </Tooltip>
    );
};

const RenameTrackButton = ({handleClick, disabled}) => {

    return (
        <Tooltip title="Rename Track">
            <IconButton onClick={handleClick} sx={{
                color: "#1a1a1a", // Set color from theme
                fontSize: '2rem', // Increase the font size
                // You can also adjust the size of the icon itself
                '& .MuiSvgIcon-root': {fontSize: '1.5rem'}
            }} disabled={disabled}>
                <RenameIcon/>
            </IconButton>
        </Tooltip>
    );
};

const sectionStyle = {
    boxSizing: 'border-box',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
};

export const ListViewRecordedTrackSet = ({
                                             trackSet,
                                             onClick,
                                             onRenameClick,
                                             onDownloadTranscript,
                                             onDeleteClick,
                                             isSelected,
                                             isSharedView,
                                             isRestrictedPlan
                                         }) => {
    const highQualityDownloadDisabled = trackSet.highQualityFileUrl === null || trackSet.highQualityFileUrl === "" || trackSet.highQualityStatus !== "Ready" || isRestrictedPlan;

    const cloudRecordingDownloadDisabled = trackSet.cloudRecordingFileUrl === null || trackSet.cloudRecordingFileUrl === "" || trackSet.cloudRecordingStatus !== "Ready" || isRestrictedPlan;

    return (
        <Box container direction="row" sx={isSelected ? styles.selectedBoxStyle : styles.boxStyle}
             onClick={() => onClick(trackSet)}>
            <Box sx={{
                ...sectionStyle,
                display: "flex",
                flexDirection: "row",
                gap: "12px",
                alignItems: "center",
                width: "30%",
                padding: '0 8px',
            }}>
                <Box sx={styles.thumbnailContainerStyle}>
                    <ListViewThumbnailCard
                        thumbnailUrl={trackSet.thumbnailUrl}
                        title={trackSet.title}
                        status={trackSet.status}
                        isVideo={trackSet.mediaType === "Video"}
                    />
                </Box>
                <Box sx={styles.titleStyle}>
                    {formatToReadableLargeTitle(trackSet.title)}
                </Box>
            </Box>
            <Box sx={{
                ...sectionStyle,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
                flex: 1,
                width: "20%"
            }}>
                <Box>
                    <StatusChip status={trackSet.status} progress={null}/>
                </Box>
                <Box>
                    {trackSet.duration}
                </Box>
                <Box>
                    {trackSet.mediaType === "Video" ? trackSet.resolution ? `${trackSet.resolution}` : '----x---' : trackSet.sampleRate ? `${formatSampleRate(trackSet.sampleRate)}` : 'xx kHz'}
                </Box>
            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
                gap: '12px',
                width: "40%"
            }}>
                <Tooltip title={isRestrictedPlan ? "Unlock with Standard Plan" : ""}>
                    <Box sx={downloadButtonStyle(!highQualityDownloadDisabled)}
                         onClick={() => handleDownloadButtonClick({
                             assetTitle: trackSet.assetTitle,
                             trackTitle: trackSet.title,
                             trackUrl: trackSet.highQualityFileUrl,
                             trackCreatedAt: trackSet.highQualityCreateTime,
                             isRecording: true,
                             customTitle: null
                         })}>
                        <Box sx={iconContainerStyle}>
                            <DownloadIcon/>
                        </Box>
                        <Box sx={buttonTextStyle}>
                            High Quality
                        </Box>
                    </Box>
                </Tooltip>

                <Tooltip title={isRestrictedPlan ? "Unlock with Standard Plan" : ""}>
                    <Box sx={downloadButtonStyle(!cloudRecordingDownloadDisabled)}
                         onClick={() => handleDownloadButtonClick({
                             assetTitle: trackSet.assetTitle,
                             trackTitle: trackSet.title,
                             trackUrl: trackSet.cloudRecordingFileUrl,
                             trackCreatedAt: trackSet.cloudRecordingCreateTime,
                             isRecording: true,
                             customTitle: null
                         })}>
                        <Box sx={iconContainerStyle}>
                            <DownloadIcon/>
                        </Box>
                        <Box sx={buttonTextStyle}>
                            Cloud Recording
                        </Box>
                    </Box>
                </Tooltip>
            </Box>
            {!isSharedView &&
                <Box sx={{display: "flex", flexDirection: "row", justifyContent: 'flex-end', width: "10%"}}>
                    <div style={{}}>
                        <DownloadTranscriptButton
                            handleClick={() => onDownloadTranscript({track: trackSet})}
                            disabled={!trackSet.transcriptUrl}/>
                    </div>
                    <div style={{}}>
                        <RenameTrackButton
                            handleClick={() => onRenameClick({trackIds: trackSet.trackIds, title: trackSet.title})}/>
                    </div>
                    <div style={{marginLeft: "5px"}}>
                        <DeleteTrackButton handleClick={() => onDeleteClick(trackSet.trackIds)}/>
                    </div>
                </Box>}
        </Box>
    );
}

export const ListViewUploadedTrack = ({
                                          track,
                                          onClick,
                                          onRenameClick,
                                          onDownloadTranscript,
                                          onDeleteClick,
                                          isSelected,
                                          isSharedView,
                                          isRestrictedPlan
                                      }) => {
    const readyForDownload = track.status === "Ready" && !isRestrictedPlan;

    const customTitle = track.customTitle === undefined || track.customTitle === null ? "" : track.customTitle;

    return (
        <Box container direction="row" sx={isSelected ? styles.selectedBoxStyle : styles.boxStyle}
             onClick={() => onClick(track)}>
            <Box sx={{
                ...sectionStyle,
                display: "flex",
                flexDirection: "row",
                gap: "12px",
                alignItems: "center",
                width: "30%"
            }}>
                <Box sx={styles.thumbnailContainerStyle}>
                    <ListViewThumbnailCard
                        thumbnailUrl={track.thumbnailUrl}
                        title={track.title}
                        status={track.status}
                        isVideo={IsVideoTrackType(track.trackType)}
                    />
                </Box>
                <Box sx={styles.titleStyle}>
                    {formatToReadableLargeTitle(track.title)}
                </Box>
            </Box>
            <Box sx={{
                ...sectionStyle,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
                width: "30%",
                // flex: 1,
                // maxWidth: "25%"
            }}>
                <Box>
                    <StatusChip status={track.status} progress={track.uploadProgress}/>
                </Box>
                <Box>
                    {formatDuration(track.duration)}
                </Box>
                <Box>
                    {IsVideoTrackType(track.trackType) ? track.resolution ? `${track.resolution}` : '----x---' : track.sampleRate ? `${formatSampleRate(track.sampleRate)}` : 'xx kHz'}
                </Box>
            </Box>
            <Box sx={{
                ...sectionStyle, display: 'flex',
                width: "25%",
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Tooltip title={isRestrictedPlan ? "Unlock with Standard Plan" : ""}>
                    <Box sx={downloadButtonStyle(readyForDownload)} onClick={() => handleDownloadButtonClick({
                        assetTitle: "",
                        trackTitle: track.title,
                        trackUrl: track.objectUrl,
                        trackCreatedAt: track.createTime,
                        isRecording: false,
                        customTitle: customTitle
                    })}>
                        <Box sx={iconContainerStyle}>
                            <DownloadIcon/>
                        </Box>
                        <Box sx={buttonTextStyle}>
                            Download
                        </Box>
                    </Box>
                </Tooltip>
            </Box>
            {!isSharedView && <Box
                sx={{...sectionStyle, display: "flex", flexDirection: "row", justifyContent: 'flex-end', width: "15%"}}>
                <div style={{}}>
                    <DownloadTranscriptButton
                        handleClick={() => onDownloadTranscript({track: track})}
                        disabled={!track.transcriptUrl}/>
                </div>
                <div style={{}}>
                    <RenameTrackButton
                        handleClick={() => onRenameClick({trackIds: [track.trackId], title: track.title})}/>
                </div>
                <div style={{marginLeft: "5px"}}>
                    <DeleteTrackButton handleClick={() => onDeleteClick([track.trackId])}/>
                </div>
            </Box>}
        </Box>
    );
};

const ForwardArrowIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
            <path d="M8.33325 14.667L12.4999 10.5003L8.33325 6.33366" stroke="#1A1A1A" stroke-width="1.5"
                  stroke-linecap="square" stroke-linejoin="round"/>
        </svg>
    )
}

export const RecordingsListView = ({recordings, setSwitchToComputerFormOpen, isSharedView, token, isMobile}) => {

    const navigate = useNavigate();

    const theme = useTheme();

    const handleCardClick = (assetId) => {
        if (isMobile) {
            setSwitchToComputerFormOpen(true);
            return;
        }

        if (isSharedView) {
            navigate('/shared/assets/' + assetId + '/' + token);
            return;
        }

        navigate('/assets/' + assetId);
    }

    return (
        <>
            {recordings.map((recording, i) => (
                <ListViewRecording recording={recording} onClick={handleCardClick} isMobile={isMobile}
                                   isSharedView={isSharedView}/>
            ))}
        </>
    )
}

export const NewGridRecordings = ({recordings, setSwitchToComputerFormOpen, isSharedView, token}) => {

    const navigate = useNavigate();

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleCardClick = (assetId) => {
        if (isMobile) {
            setSwitchToComputerFormOpen(true);
            return;
        }

        if (isSharedView) {
            navigate('/shared/assets/' + assetId + '/' + token)
            return;
        }

        navigate('/assets/' + assetId);
    }

    const handleScheduledSessionClick = (recording) => {
        navigate("/studio/" + recording.projectId + "/" + recording.title + "/" + recording.assetId);
    }

    return (
        <>
            {recordings.map((recording, i) => (
                <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={i}>
                    <ProjectThumbnailCard thumbnailUrl={recording.thumbnailUrl}
                                          scheduledStartTime={recording.scheduledStartTime}
                                          scheduledTimezone={recording.scheduledTimezone}
                                          scheduledEndTime={recording.scheduledEndTime}
                                          onScheduledSessionClick={() => handleScheduledSessionClick(recording)}
                                          onClick={() => handleCardClick(recording.assetId)} title={recording.title}
                                          createTime={recording.createTime} status={recording.status}
                                          progress={recording.uploadProgress} isVideo={IsVideoAsset(recording)}/>
                </Grid>
            ))}
        </>
    )
}
