import {computeMenuPosition, wasClickOutside} from '@livekit/components-core';
import * as React from 'react';
import {MediaDeviceSelect} from "@livekit/components-react";
import {useRef, useState} from "react";
import Box from "@mui/material/Box";
import "./deviceMenu.css";
import {NewMediaDeviceSelect} from "./NewMediaDeviceSelect";
import {DropdownArrowIcon} from "./SettingsIcon";

/** @public */
// export interface MediaDeviceMenuProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
//     kind?: MediaDeviceKind;
//     initialSelection?: string;
//     onActiveDeviceChange?: (kind: MediaDeviceKind, deviceId: string) => void;
//     tracks?: Partial<Record<MediaDeviceKind, LocalAudioTrack | LocalVideoTrack | undefined>>;
// /**
//  * this will call getUserMedia if the permissions are not yet given to enumerate the devices with device labels.
//  * in some browsers multiple calls to getUserMedia result in multiple permission prompts.
//  * It's generally advised only flip this to true, once a (preview) track has been acquired successfully with the
//  * appropriate permissions.
//  *
//  * @see {@link PreJoin}
//  * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/enumerateDevices | MDN enumerateDevices}
//  */
// requestPermissions?: boolean;
// }

/**
 * The `MediaDeviceMenu` component is a button that opens a menu that lists
 * all media devices and allows the user to select them.
 *
 * @remarks
 * This component is implemented with the `MediaDeviceSelect` LiveKit components.
 *
 * @example
 * ```tsx
 * <LiveKitRoom>
 *   <MediaDeviceMenu />
 * </LiveKitRoom>
 * ```
 * @public
 */
export function NewMediaDeviceMenu({
                                       kind,
                                       initialSelection,
                                       onActiveDeviceChange,
                                       tracks,
                                       room,
                                       isPrejoin,
                                       width,
                                       requestPermissions = false,
                                       ...props
                                   }) {
    const [isOpen, setIsOpen] = React.useState(false);
    const [devices, setDevices] = useState([]);
    const [updateRequired, setUpdateRequired] = useState(true);
    const [needPermissions, setNeedPermissions] = useState(requestPermissions);
    const [positionLeft, setPositionLeft] = useState(0);
    const [positionBottom, setPositionBottom] = useState(0);

    const buttonRef = useRef(null);
    const lastCallTime = useRef(0);

    const setIsOpenWithThrottle = (newState) => {
        const now = Date.now();
        if (now - lastCallTime.current > 200) {
            lastCallTime.current = now;
            setIsOpen(newState);
        }
    };

    const handleActiveDeviceChange = (kind, deviceId, isActiveChange) => {
        setIsOpenWithThrottle(false);
        onActiveDeviceChange?.(kind, deviceId);
    };

    const button = React.useRef < HTMLButtonElement > (null);
    const tooltip = React.useRef(null);

    React.useLayoutEffect(() => {
        if (isOpen) {
            setNeedPermissions(true);
        }
    }, [isOpen]);

    React.useLayoutEffect(() => {
        if (button.current && tooltip.current && (devices || updateRequired)) {
            computeMenuPosition(button.current, tooltip.current).then(({x, y}) => {
                if (tooltip.current) {
                    Object.assign(tooltip.current.style, {left: `${x}px`, top: `${y}px`});
                }
            });
        }
        setUpdateRequired(false);
    }, [button, tooltip, devices, updateRequired]);

    const handleClickOutside = React.useCallback(
        (event) => {
            if (!tooltip.current) {
                return;
            }
            if (event.target === button.current) {
                return;
            }
            if (isOpen && wasClickOutside(tooltip.current, event)) {
                setIsOpenWithThrottle(false);
            }
        },
        [isOpen, tooltip, button],
    );

    React.useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        window.addEventListener('resize', () => setUpdateRequired(true));
        return () => {
            document.removeEventListener('click', handleClickOutside);
            window.removeEventListener('resize', () => setUpdateRequired(true));
        };
    }, [handleClickOutside, setUpdateRequired]);

    const dropdownArrowStyle = {
        display: "flex",
        padding: {md: "4px 4px"},
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        backdropFilter: isPrejoin ? "blur(12.5px)" : "none",
        backgroundColor: isPrejoin ? "rgba(0, 0, 0, 0.20)" : '#e8e8e8',
        width: isPrejoin ? "48px" : "40px", // "130px
        height: isPrejoin ? "52px" : "40px",
        alignSelf: "stretch",
        cursor: "pointer",
        borderRadius: "0 5px 5px 0",
    }

    const handleClick = () => {
        setPositionLeft(buttonRef.current.getBoundingClientRect().left);
        setPositionBottom(buttonRef.current.getBoundingClientRect().bottom);
        setIsOpenWithThrottle(!isOpen);
    }

    return (
        <>
            <Box sx={dropdownArrowStyle} ref={buttonRef} aria-pressed={isOpen} onClick={handleClick}>
                <DropdownArrowIcon isPrejoin={isPrejoin}/>
            </Box>
            {/** only render when enabled in order to make sure that the permissions are requested only if the menu is enabled */}
            {!props.disabled && isOpen && (
                <NewMediaDeviceSelect
                    initialSelection={initialSelection}
                    onActiveDeviceChange={(deviceId, isActiveChange) => handleActiveDeviceChange(kind, deviceId, isActiveChange)}
                    onDeviceListChange={setDevices}
                    kind={kind}
                    prejoin={isPrejoin}
                    exactMatch={false}
                    maybeRoom={room}
                    track={tracks?.[kind]}
                    requestPermissions={needPermissions}
                    left={positionLeft - width}
                    bottom={positionBottom}
                    setIsOpen={setIsOpenWithThrottle}
                />
            )}
        </>
    );
}
