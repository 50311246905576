import React, {useContext, useState} from "react";
import {TextField, MenuItem, Button, Select, FormControl, InputLabel, Box} from "@mui/material";
import {DatePicker, TimePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import * as yup from "yup";
import {useFormik} from "formik";
import Typography from "@mui/material/Typography";
import EmailBadge from "../NewStudio/EmailBadge";
import {RefreshTokenAndRetry} from "../../utils/utils";
import {FetchContext} from "../../context/FetchContext";
import {AuthContext} from "../../context/AuthContext";
import {SessionTrashButton} from "../../components/CoolButtons";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import CloseIcon from "@mui/icons-material/Close";

dayjs.extend(utc);
dayjs.extend(timezone);

const parseTime = (time) => {
    const [hourMinute, period] = time.split(" ");
    let [hour, minute] = hourMinute.split(":").map(Number);

    // Convert to 24-hour format
    if (period === "PM" && hour !== 12) hour += 12;
    if (period === "AM" && hour === 12) hour = 0;

    return hour * 60 + minute; // Return total minutes since midnight
};

const getDuration = (a, b) => {
    const minutesA = parseTime(a);
    const minutesB = parseTime(b);
    const difference = Math.abs(minutesB - minutesA); // Duration in minutes

    if (difference % 60 === 0) {
        return `${difference / 60} hr`;
    } else if (difference < 60) {
        return `${difference} min`;
    } else {
        const hours = Math.floor(difference / 60);
        const minutes = difference % 60;
        return `${hours} hr ${minutes} min`;
    }
};

const superHeaderText = {
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "650",
    lineHeight: "24px",
    letterSpacing: "0.18px",
}

const topTitleStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
}

const SessionForm = ({onClose, projectId}) => {

    const defaultTimezone = "America/New_York";

    const now = dayjs.tz(dayjs(), defaultTimezone); // Current time in the default timezone
    const roundedMinutes = Math.ceil(now.minute() / 15) * 15; // Round up to the next quarter-hour
    const roundedTime = now.startOf("hour").minute(roundedMinutes); // Set to the rounded time

    const nearestTime = roundedTime.format("h:mm A"); // Format as h:mm A
    const oneHourLater = roundedTime.add(1, "hour").format("h:mm A"); // Add 1 hour and format


    const [sessionName, setSessionName] = useState("");
    const [sessionNameTouched, setSessionNameTouched] = useState(false);
    const [date, setDate] = useState(dayjs());
    const [startTime, setStartTime] = useState(nearestTime);
    const [endTime, setEndTime] = useState(oneHourLater);
    const [selectedTimezone, setSelectedTimezone] = useState(defaultTimezone);

    const fetchContext = useContext(FetchContext);
    const authContext = useContext(AuthContext);

    const timezones = [
        { label: "(GMT-11:00) Pacific/Midway", value: "Pacific/Midway" },
        { label: "(GMT-10:00) Pacific/Honolulu", value: "Pacific/Honolulu" },
        { label: "(GMT-08:00) America/Los_Angeles", value: "America/Los_Angeles" },
        { label: "(GMT-07:00) America/Denver", value: "America/Denver" },
        { label: "(GMT-06:00) America/Chicago", value: "America/Chicago" },
        { label: "(GMT-05:00) America/New_York", value: "America/New_York" }, // Default timezone
        { label: "(GMT+00:00) Europe/London", value: "Europe/London" },
        { label: "(GMT+01:00) Europe/Paris", value: "Europe/Paris" },
        { label: "(GMT+05:30) Asia/Kolkata", value: "Asia/Kolkata" },
        { label: "(GMT+08:00) Asia/Shanghai", value: "Asia/Shanghai" },
        { label: "(GMT+09:00) Asia/Tokyo", value: "Asia/Tokyo" },
        { label: "(GMT+10:00) Australia/Sydney", value: "Australia/Sydney" },
    ];

    const generateTimes = () => {
        const times = [];
        const periods = ["AM", "PM"];

        for (let period of periods) {
            for (let hour = 0; hour < 12; hour++) {
                for (let minute = 0; minute < 60; minute += 15) {
                    const formattedHour = hour === 0 ? 12 : hour; // 0 -> 12 for AM/PM format
                    const formattedMinute = minute.toString().padStart(2, "0");
                    times.push(`${formattedHour}:${formattedMinute} ${period}`);
                }
            }
        }

        return times;
    };

    const times = generateTimes();

    const validationSchema = yup.object({
        email: yup
            .string()
            .trim()
            .email('Please enter a valid email address')
            .required('Email is required.'),
    });

    const initialValues = {
        email: '',
    };

    const [addedEmails, setAddedEmails] = useState([]);

    const [emailError, setEmailError] = useState(false);
    const [emailAlreadyInvited, setEmailAlreadyInvited] = useState('');

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
    });

    const handleCreateSession = async () => {
        try {
            // Get selected timezone value (e.g., "America/New_York")
            const selectedTimezoneValue = selectedTimezone;

            // Combine date and time into a full timestamp in the selected timezone
            const dateString = date.format("YYYY-MM-DD"); // From dayjs
            const startTime24Hr = dayjs(startTime, ["h:mm A"]).format("HH:mm");
            const combinedStartTime = dayjs.tz(`${dateString} ${startTime24Hr}`, "YYYY-MM-DD HH:mm", selectedTimezoneValue);

            const endTime24Hr = dayjs(endTime, ["h:mm A"]).format("HH:mm");
            const combinedEndTime = dayjs.tz(`${dateString} ${endTime24Hr}`, "YYYY-MM-DD HH:mm", selectedTimezoneValue);

            // Convert to UTC timestamp
            const utcStartTimestamp = combinedStartTime.utc().unix(); // Convert to UTC
            const utcEndTimestamp = combinedEndTime.utc().unix(); // Convert to UTC

            // Send data to server
            await fetchContext.authAxios.post('/schedule', {
                startTimestamp: utcStartTimestamp,
                endTimestamp: utcEndTimestamp,
                sessionName: sessionName,
                addedEmails: addedEmails,
                projectId: projectId,
                timezone: selectedTimezoneValue, // Send the IANA timezone identifier to the server
            }, {
                headers: {
                    Authorization: `Bearer ${authContext.getToken()}`,
                }
            });

            onClose();
        } catch (err) {
            if (err.response?.status === 401) {
                await RefreshTokenAndRetry(err, authContext, fetchContext);
            }
            console.error("Error creating session:", err);
        }
    };

    const handleAddEmail = () => {
        if (Boolean(formik.errors.email)) {
            setEmailError(true);
            setEmailAlreadyInvited('');
            return;
        }
        setEmailError(false);

        if (addedEmails.includes(formik.values.email)) {
            setEmailAlreadyInvited(formik.values.email);
            return;
        }
        setEmailAlreadyInvited('');

        setAddedEmails([...addedEmails, formik.values.email]);
    }

    const removeAddedEmail = (email) => {
        setAddedEmails(addedEmails.filter((addedEmail) => addedEmail !== email));
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
                sx={{
                    backgroundColor: "#fff",
                    padding: "20px",
                    borderRadius: "8px",
                    width: "650px",
                    margin: "0 auto",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                }}
            >
                <Box sx={{height: "10px"}}/>
                <Box style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    alignSelf: "stretch",
                    flexDirection: "column",
                    gap: "8px",
                }}>
                    <Box style={topTitleStyle}>
                        <div style={superHeaderText}>Schedule a recording session</div>
                        <CloseIcon onClick={onClose} sx={{cursor: 'pointer'}}/>
                    </Box>
                </Box>
                <Box sx={{height: "10px"}}/>
                {/* Session Name */}
                <TextField
                    fullWidth
                    label="Session name*"
                    variant="outlined"
                    value={sessionName}
                    onChange={(e) => {
                        setSessionName(e.target.value);
                        setSessionNameTouched(true);
                    }}
                    error={!sessionName && sessionNameTouched}
                    helperText={
                        <Box height="1em">
                            {!sessionName && sessionNameTouched ? "Enter a session name" : ""}
                        </Box>
                    }
                    margin="normal"
                    InputProps={{style: {color: "#000"}}}
                    InputLabelProps={{style: {color: "#555"}}}
                />

                {/* Date Picker */}
                <Box sx={{display: "flex", justifyContent: "space-between", gap: "10px", marginTop: "16px"}}>
                    <DatePicker
                        label="Date"
                        value={date}
                        minDate={dayjs.tz(dayjs(), selectedTimezone)}
                        onChange={(newDate) => setDate(newDate)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                margin="normal"
                                InputProps={{style: {color: "#000"}}}
                                InputLabelProps={{style: {color: "#555"}}}
                            />
                        )}
                    />

                    <FormControl sx={{width: "150px"}}>
                        <InputLabel sx={{color: "#555"}}>Start time</InputLabel>
                        <Select
                            value={startTime}
                            label={"Start time"}
                            onChange={(e) => {
                                setStartTime(e.target.value);
                                setEndTime(times[times.indexOf(e.target.value) + 4 % times.length]);
                            }}
                            displayEmpty
                            sx={{color: "#000"}}
                        >
                            {times
                                .filter((time) => {
                                    // Adjust current time to the selected timezone
                                    const now = dayjs.tz(dayjs(), selectedTimezone);
                                    const selectedDate = dayjs.tz(date.format("YYYY-MM-DD"), "YYYY-MM-DD", selectedTimezone);

                                    // If the selected date is today, only allow times after the current time
                                    if (selectedDate.isSame(now, "day")) {
                                        return dayjs.tz(`${date.format("YYYY-MM-DD")} ${time}`, "YYYY-MM-DD h:mm A", selectedTimezone).isAfter(now);
                                    }
                                    return true; // Otherwise, all times are valid
                                })
                                .map((time, index) => (
                                    <MenuItem key={index} value={time}>
                                        {time}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{width: "150px"}}>
                        <InputLabel sx={{color: "#555"}}>End time</InputLabel>
                        <Select
                            value={endTime}
                            label={"End time"}
                            onChange={(e) => setEndTime(e.target.value)}
                            displayEmpty
                            sx={{color: "#000"}}
                        >
                            {times
                                .filter((time) => {
                                    // Adjust current time and start time to the selected timezone
                                    const now = dayjs.tz(dayjs(), selectedTimezone);
                                    const selectedDate = dayjs.tz(date.format("YYYY-MM-DD"), "YYYY-MM-DD", selectedTimezone);
                                    const selectedStartTime = dayjs.tz(`${date.format("YYYY-MM-DD")} ${startTime}`, "YYYY-MM-DD h:mm A", selectedTimezone);

                                    // If the selected date is today, ensure times are after both the current time and the start time
                                    if (selectedDate.isSame(now, "day")) {
                                        return (
                                            dayjs.tz(`${date.format("YYYY-MM-DD")} ${time}`, "YYYY-MM-DD h:mm A", selectedTimezone).isAfter(selectedStartTime) &&
                                            dayjs.tz(`${date.format("YYYY-MM-DD")} ${time}`, "YYYY-MM-DD h:mm A", selectedTimezone).isAfter(now)
                                        );
                                    }

                                    // For future dates, ensure times are after the selected start time
                                    return dayjs.tz(`${date.format("YYYY-MM-DD")} ${time}`, "YYYY-MM-DD h:mm A", selectedTimezone).isAfter(selectedStartTime);
                                })
                                .map((time, index) => (
                                    <MenuItem key={index} value={time}>
                                        {time} {" "} {getDuration(startTime, time)}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Box>

                {/* Timezone Selector */}
                <FormControl fullWidth sx={{marginTop: "16px"}}>
                    <InputLabel sx={{color: "#555"}}>Timezone</InputLabel>
                    <Select
                        value={selectedTimezone}
                        label={"Timezone"}
                        onChange={(e) => setSelectedTimezone(e.target.value)}
                        displayEmpty
                        sx={{color: "#000"}}
                    >
                        {timezones.map((tz, index) => (
                            <MenuItem key={index} value={tz.value}>
                                {tz.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Invite Field */}
                <Box sx={{display: "flex", flexDirection: "row", gap: "12px", alignItems: "center"}}>
                    <TextField
                        fullWidth
                        label="Invite people via email"
                        variant="outlined"
                        name={'email'}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        margin="normal"
                        InputProps={{style: {color: "#000", height: "52px"}}}
                        InputLabelProps={{style: {color: "#555"}}}
                    />
                    <Box mt={1}>
                        <Button variant="contained" color="primary"
                                sx={{backgroundColor: "#000", color: "#fff", width: "100px", height: "52px"}}
                                onClick={handleAddEmail} disabled={addedEmails.length >= 8}>
                            Add
                        </Button>
                    </Box>
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    alignSelf: "flex-start",
                    width: "100%",
                    marginBottom: "8px"
                }}>
                    {emailError &&
                        <Box sx={{alignSelf: "flex-start"}}>
                            <Typography variant="body1" color="error">Please enter a valid email address.</Typography>
                        </Box>}
                    {emailAlreadyInvited &&
                        <Box>
                            <Typography variant="body1" color="error">
                                You have already added {emailAlreadyInvited}.
                            </Typography>
                        </Box>}
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    alignSelf: "flex-start",
                    width: "100%",
                    maxHeight: "210px", // Set a maximum height for the section
                    overflowY: "auto", // Enable vertical scrolling
                    scrollbarWidth: "thin", // For Firefox
                    "&::-webkit-scrollbar": {
                        width: "6px", // For Chrome, Edge, and Safari
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#cccccc", // Customize the scrollbar thumb color
                        borderRadius: "3px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "#aaaaaa", // Darker color on hover
                    }
                }}>
                    {addedEmails && addedEmails.length > 0 && <Box sx={{
                        fontSize: {sm: "17px", md: "17px"}, // Smaller font for mobile
                        fontWeight: "500",
                        color: "#6f6e77",
                        textAlign: "center", // Center align the text
                        paddingX: {xs: "20px", sm: "0"} // Padding on mobile for better spacing
                    }}>
                        An email with instructions on how to join the session will be sent to all invitees.
                    </Box>}
                    {addedEmails && addedEmails.length > 0 && addedEmails.map((email) => {
                        return (
                            <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                <Box>
                                    <EmailBadge email={email}/>
                                </Box>
                                <Box>
                                    <SessionTrashButton handleClick={() => removeAddedEmail(email)}/>
                                </Box>
                            </Box>)
                    })}
                </Box>

                {/* Buttons */}
                <Box sx={{display: "flex", justifyContent: "space-between", marginTop: "20px"}}>
                    <Button variant="outlined" color="primary" sx={{color: "#000", borderColor: "#000"}}
                            onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={sessionName === ""}
                        sx={{backgroundColor: "#000", color: "#fff"}}
                        onClick={handleCreateSession}
                    >
                        Create session
                    </Button>
                </Box>
            </Box>
        </LocalizationProvider>
    );
};

export default SessionForm;
