import {isEqualTrackRef, isTrackReference, isWeb, log, setupParticipantName} from '@livekit/components-core';
import {RoomEvent, Track} from 'livekit-client';
import * as React from 'react';
import {
    CarouselLayout,
    ConnectionStateToast,
    FocusLayout,
    FocusLayoutContainer,
    GridLayout,
    LayoutContextProvider,
    ParticipantTile,
    RoomAudioRenderer,
    useCreateLayoutContext,
    usePinnedTracks,
    useTracks,
    Chat, useEnsureParticipant,
} from "@livekit/components-react";
import './videoConference.css';
import {CustomParticipantTile} from "./CustomParticipantTile";

export function NewCustomVideoConference({
                                             recordingInProgress,
                                             startRecording,
                                             stopRecording,
                                             localRecordingInProgress,
                                             nameStyle,
                                             videoMirrored,
                                             hostIdentity,
                                             themeBackgroundColor,
                                             brandLogoUrl,
                                             chatMessageFormatter,
                                             chatMessageDecoder,
                                             chatMessageEncoder,
                                             ...props
                                         }) {
    const [widgetState, setWidgetState] = React.useState({
        showChat: false,
        unreadMessages: 0,
    });
    const lastAutoFocusedScreenShareTrack = React.useRef(null);

    const tracks = useTracks(
        [
            {source: Track.Source.Camera, withPlaceholder: true},
            {source: Track.Source.ScreenShare, withPlaceholder: false},
        ],
        {updateOnlyOn: [RoomEvent.ActiveSpeakersChanged], onlySubscribed: false},
    );

    const internalAgentParticipants = ["TimerAgent", "RecordingAgent", "Soundboard"];
    const visibleTracks = tracks.filter((track) => !internalAgentParticipants.includes(track.participant.identity));

    const widgetUpdate = (state) => {
        log.debug('updating widget state', state);
        setWidgetState(state);
    };

    const layoutContext = useCreateLayoutContext();

    const screenShareTracks = tracks
        .filter(isTrackReference)
        .filter((track) => track.publication.source === Track.Source.ScreenShare);

    const focusTrack = usePinnedTracks(layoutContext)?.[0];
    const carouselTracks = visibleTracks.filter((track) => !isEqualTrackRef(track, focusTrack));

    React.useEffect(() => {
        // If screen share tracks are published, and no pin is set explicitly, auto set the screen share.
        if (
            screenShareTracks.some((track) => track.publication.isSubscribed) &&
            lastAutoFocusedScreenShareTrack.current === null
        ) {
            log.debug('Auto set screen share focus:', {newScreenShareTrack: screenShareTracks[0]});
            layoutContext.pin.dispatch?.({msg: 'set_pin', trackReference: screenShareTracks[0]});
            lastAutoFocusedScreenShareTrack.current = screenShareTracks[0];
        } else if (
            lastAutoFocusedScreenShareTrack.current &&
            !screenShareTracks.some(
                (track) =>
                    track.publication.trackSid ===
                    lastAutoFocusedScreenShareTrack.current?.publication?.trackSid,
            )
        ) {
            log.debug('Auto clearing screen share focus.');
            layoutContext.pin.dispatch?.({msg: 'clear_pin'});
            lastAutoFocusedScreenShareTrack.current = null;
        }
    }, [
        screenShareTracks
            .map((ref) => `${ref.publication.trackSid}_${ref.publication.isSubscribed}`)
            .join(),
        focusTrack?.publication?.trackSid,
    ]);

    return (
        <div className="libretto-video-conference" style={{backgroundColor: themeBackgroundColor}} {...props}>
            {isWeb() && (
                <LayoutContextProvider
                    value={layoutContext}
                    // onPinChange={handleFocusStateChange}
                    onWidgetChange={widgetUpdate}
                >
                    <div className="libretto-video-conference-inner">
                        {!focusTrack ? (
                            <div className="libretto-grid-layout-wrapper">
                                <GridLayout tracks={visibleTracks}>
                                    <CustomParticipantTile nameStyle={nameStyle} hostIdentity={hostIdentity} brandLogoUrl={brandLogoUrl} videoMirrored={videoMirrored}/>
                                </GridLayout>
                            </div>
                        ) : (
                            <div className="libretto-focus-layout-wrapper">
                                <FocusLayoutContainer>
                                    <CarouselLayout tracks={carouselTracks}>
                                        <CustomParticipantTile nameStyle={nameStyle} hostIdentity={hostIdentity} brandLogoUrl={brandLogoUrl} videoMirrored={videoMirrored}/>
                                    </CarouselLayout>
                                    {focusTrack && <FocusLayout trackRef={focusTrack}/>}
                                </FocusLayoutContainer>
                            </div>
                        )}
                    </div>
                </LayoutContextProvider>
            )}
            <RoomAudioRenderer/>
            <ConnectionStateToast/>
        </div>
    );
}
